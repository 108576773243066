import {gql} from '@apollo/client'

export default gql`
  query GenresQuery {
    genres {
      id
      title
      slug
      type
    }
  }
`
