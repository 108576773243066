// @flow
import React from 'react'
import {useQuery} from '@apollo/client'

import {trendingPlaylists, uiLocations} from '../../common/constants'
import type {GenericCollectionType} from '../../common/types'
import {makeQueueFromItems} from '../../common/makeQueue'

import Loading from '../Loading'
import Card from '../Card'
import Page from '../Page'
import ShopSlider from '../ShopSlider'
import VideoScheduleNav from '../VideoScheduleNav'
import Share from '../Share'

import styles from './ChartsPage.less'

import GET_PLAYLIST_BY_SLUG from './Query'

export default () => {
  const {data: newPlaylist, loading: newPlaylistLoading} = useQuery(GET_PLAYLIST_BY_SLUG, {
    variables: {
      slug: trendingPlaylists.new,
    },
  })

  const {data: trendingPlaylist, loading: trendingPlaylistLoading} = useQuery(GET_PLAYLIST_BY_SLUG, {
    variables: {
      slug: trendingPlaylists.trending,
    },
  })

  const isLoading = newPlaylistLoading || trendingPlaylistLoading

  return (
    <Page
      title='Top 10 Charts'
      description='The most popular sets on Boiler Room'
      subNavigation={<VideoScheduleNav />}
    >
      <div className={styles.Wrapper}>
        <div className={styles.HeaderWrapper}>
          <div className={styles.HeaderAndIcon}>
            <h3>CHARTS</h3>
          </div>
          <Share className={styles.ChartShareLink} />
        </div>
        {isLoading ?
          <Loading />
          :
          <div className={styles.ChartPlaylistsWrapper}>
            <ChartPlaylist playlist={newPlaylist} />
            <ChartPlaylist playlist={trendingPlaylist} />
          </div>
        }
      </div>

      <div className={styles.ShopSliderWrapper}>
        <ShopSlider />
      </div>
    </Page>
  )
}

const ChartPlaylist = ({playlist}: {playlist: {collection_by_slug: GenericCollectionType}}) => {
  if (!playlist || !playlist.collection_by_slug) return null

  return (
    <div className={styles.PlaylistWrapper}>
      <div className={styles.ChartHeaderWrapper}>
        <div className={styles.ChartTitle}>{playlist.collection_by_slug.title}</div>
      </div>

      <div className={styles.PlaylistSummary}>
        <span>{playlist.collection_by_slug.summary}</span>
      </div>

      {playlist.collection_by_slug.items.map((item, index) =>
        <div className={styles.RankAndCardWrapper} key={item.id}>
          <span className={styles.RankNumber}>
            {index + 1}
          </span>
          <div className={styles.CardWrapper}>
            <Card
              item={item}
              queue={makeQueueFromItems(item, playlist.collection_by_slug.items)}
              horizontal
              disableChartPosition
              uiLocation={uiLocations.chartsPageCard}
            />
          </div>
        </div>
      )}
    </div>
  )
}
