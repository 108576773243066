// @flow

import {genericItemTypes} from './constants'
import type {RecordingType, GenericItemType} from './types'

// The queue for a card in a slider contains all of the items. Once
// a user reaches the end of the slider's items in the queue they loop
// back around to the items at the start.
export const makeQueue = (recordingToPlay: RecordingType, recordings: Array<RecordingType>) => {
  const recordingIndex = recordings.findIndex(queueRecording => queueRecording.id === recordingToPlay.id)

  // if the recording to play is not in the recordings, put it first
  if (recordingIndex === -1) return [recordingToPlay, ...recordings]

  // if the recording is first then recordings can be the queue
  if (recordingIndex === 0) return recordings

  // if the recording is not first, reassemble so the recording is first and the
  // preceding recordings are appended to the end
  return [...recordings.slice(recordingIndex), ...recordings.slice(0, recordingIndex)]
}

// creates a queue of recordings from an array of items
// useful for rendering of genres/playlists/search results
export const makeQueueFromItems = (itemToPlay: GenericItemType, items: Array<GenericItemType>) => {
  const recordings = items
    .filter(item => item.type === genericItemTypes.recording && item.recording)
    // $FlowFixMe
    .map(item => item.recording)
  if (recordings.length === 0 || !itemToPlay.recording) return []

  return makeQueue(itemToPlay.recording, recordings)
}
