// @flow
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import type {BrandType, RecordingType, TracklistType} from '../../common/types'
import type {State} from '../../stateType'
import {brandIds} from '../../common/brands'
import {hasFutureYoutubePublishDate} from '../../common/youtubePublicationDate'
import {recordingHasNoMedia} from '../../common/audioVideo'
import {actions as gateActions} from '../Gate/state'

import {genericItemTypes, signupSources, uiLocations} from '../../common/constants'
import {actions as notificationActions} from '../Notifications/state'

import Button from '../Button'
import Background from '../Background'
import Padlock from '../Svgs/Padlock'

import styles from './Tracklist.less'
import {analytics} from '../../common/analytics'

type Props = {
  tracklist: ?TracklistType,
  recording: RecordingType,
}

export default ({recording, tracklist}: Props) => {
  if (recordingHasNoMedia(recording) || hasFutureYoutubePublishDate(recording)) return null

  if (!tracklist || tracklist.tracks.length === 0) return <TracklistRequest recording={recording} />

  const {isLoggedIn} = useSelector((state: State) => state.auth)

  const {tracks} = tracklist

  if (!isLoggedIn && tracklist) return <TrackListGate tracks={tracks} recording={recording} />

  return (
    <div className={styles.Wrapper}>
      <Tracklist tracks={tracks} />
    </div>
  )
}

const Tracklist = ({tracks}) => {
  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Heading}>Tracklist</h3>

      {tracks.map((track, index) => (
        <div className={styles.Track} key={`Track-${index}`}>
          <div className={styles.Number}>{index + 1}</div>
          <div>
            <div className={styles.TrackArtist}>{track.artist}</div>

            <div className={styles.TrackTitle}>{track.title}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

const TracklistRequest = ({recording}) => {
  const dispatch = useDispatch()
  const {id}: BrandType = useSelector((state: State) => state.brand)
  if (id !== brandIds.boilerroom) return null

  const handleClick = () => {
    analytics.track('tracklist_request', {
      recording: recording.title,
    })
    dispatch(notificationActions.setSuccess('We have received your tracklist request'))
  }

  return (
    <div className={styles.EmptyTrackListWrapper}>
      <div className={styles.Text}>Tracklist not provided</div>

      <Button className={styles.Button} onClick={handleClick} text={'REQUEST TRACKLIST'} />
    </div>
  )
}

const TrackListGate = ({tracks, recording}) => {
  const firstTwoTracks = tracks.slice(0, 2)
  return (
    <div className={styles.GateWrapper}>
      <div className={styles.GateTracklist}>
        <Tracklist tracks={firstTwoTracks} />
      </div>

      <LoginBanner
        className={styles.BannerAVariant}
        header={'MEMBERS GET TRACKLISTS'}
        text={'Login or sign up for tracklist access, party invitations, presale ticket access & more.'}
        recording={recording}
      />
    </div>
  )
}

const LoginBanner = ({className = '', header, text, recording}: {className?: string, header: string, text: string, recording: RecordingType}) => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(gateActions.openGate(signupSources.tracklist, uiLocations.tracklist, recording.id, genericItemTypes.recording))

  return (
    <div className={`${className} ${styles.LoginBannerWrapper}`}>
      <Background
        image={'https://misc.boilerroom.tv/loginBackgroundGradient.png'}
        className={styles.GateBackgroundImage}
      />

      <h3>
        <Padlock className={styles.Padlock} color={'#fff'} />
        {header}
      </h3>

      <div className={styles.GateSubheading}>{text}</div>

      <Button className={styles.GateCTA} onClick={handleClick} text={'LOGIN / SIGN UP'} />
    </div>
  )
}
