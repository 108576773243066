import {gql} from '@apollo/client'

export default gql`
  query NavigationQuery($leftSlug: String!, $rightSlug: String!) {
    left_navigation: navigation_by_slug(slug: $leftSlug) {
      navigation_elements {
        name
        link
      }
    }
    right_navigation: navigation_by_slug(slug: $rightSlug) {
      navigation_elements {
        name
        link
      }
    }
  }
`
