// @flow
import React from 'react'

import {captureException} from '@sentry/browser'

import type {ConsentBoxType, ConsentBoxInputType, ShowType, BrandType} from '../../common/types'

import {signupSources, uiLocations} from '../../common/constants'
import captureMutationErrors from '../../common/captureMutationErrors'
import {analytics} from '../../common/analytics'
import getRequestHeaders from '../../common/getRequestHeaders'

import Loading from '../Loading'
import Query from '../Query'
import CompleteTick from '../Svgs/CompleteTick'
import CityUpdatesLink from '../CityUpdatesLink'

import RsvpForm from './RsvpForm'
import styles from './Rsvp.less'

import RSVP_MUTATION from './Mutation'
import RSVP_QUERY from './Query'

type Props = {
  consentBoxes: Array<ConsentBoxType>,
  minimumAge: number,
  mobileRequired: boolean,
  show: ShowType,
  title: string,
  closeRsvp: () => void,
  refetchShow: Function,
  setError: string => void,
  setSuccess: string => void,
  brands: Array<BrandType>,
  brand: BrandType,
}

type RsvpProps = {
  ...Props,
  loading: boolean,
  mobileRequired: boolean,
  profile: {
    email: string,
  },
  refetch: Function,
  rsvpMutation: Function,
  setError: string => void,
  setSuccess: string => void,
  user: {
    email: string,
    uuid: string,
  }
}

export default (props: Props) => (
  <Query
    Component={Rsvp}
    mutationName='rsvpMutation'
    mutationSchema={RSVP_MUTATION}
    props={props}
    query={RSVP_QUERY}
  />
)

export class Rsvp extends React.Component {
  state: {
    loading: boolean,
    submitSuccessful: boolean,
  }

  constructor(props: RsvpProps) {
    super(props)
    this.state = {
      loading: false,
      submitSuccessful: false,
    }
  }

  submit = (
    firstNames: string,
    lastNames: string,
    dob: string,
    gender: string,
    email: string,
    phonenumber: string,
    consentBoxData: Array<ConsentBoxInputType>,
    emailMarketingOptIn: boolean,
  ) => {
    this.setState({loading: true})
    this.submitRsvp(consentBoxData, firstNames, lastNames, dob, gender, email, phonenumber, emailMarketingOptIn)
  }

  submitRsvp = (
    consentBoxData: Array<ConsentBoxInputType>,
    firstNames: string,
    lastNames: string,
    dob: string,
    gender: string,
    email: string,
    phonenumber: string,
    emailMarketingOptIn: boolean,
  ) =>
    this.props
      .rsvpMutation({
        variables: {
          show_id: this.props.show && this.props.show.id,
          consent_box_data: consentBoxData,
          firstNames,
          lastNames,
          dob,
          gender,
          email,
          phonenumber,
          emailMarketingOptIn,
        },
        context: {
          headers: getRequestHeaders(uiLocations.rsvpForm),
        },
      })
      .then(({errors}) => {
        if (errors && errors.length > 0) {
          captureMutationErrors(errors)
          errors.map(error => this.props.setError(error.message))
          this.setState({loading: false})
          return
        }
        analytics.track('request_invite', {signup_source: signupSources.inviteRequest})
        this.setState({submitSuccessful: true, loading: false})
        this.props.setSuccess('Request Submitted')
        this.props.refetch()
        if (this.props.refetchShow) this.props.refetchShow()
      })
      .catch(error => {
        console.log(error)
        captureException(error)
        this.props.setError(error.message)
        this.setState({loading: false})
      })

  render() {
    const {
      profile,
      closeRsvp,
      consentBoxes,
      loading,
      minimumAge,
      mobileRequired,
      title,
      show,
      brands,
      brand,
    } = this.props

    if (!profile) return null

    if (loading) return <Loading />

    const updatedConsentBoxes =
      consentBoxes &&
      consentBoxes.map(box => {
        return {
          ...box,
          given: false,
        }
      })

    const userRsvp = show && show.user_rsvp_status

    return (
      <div className={styles.InviteFormContainer}>
        {this.state.submitSuccessful || userRsvp ?
          <SuccessMessage closeRsvp={closeRsvp} email={profile.email} show={show} /> :
          <RsvpForm
            consentBoxes={updatedConsentBoxes}
            loading={this.state.loading}
            minimumAge={minimumAge}
            mobileRequired={mobileRequired}
            onSubmit={this.submit}
            profile={profile}
            title={title}
            brands={brands}
            brand={brand}
          />
        }
      </div>
    )
  }
}

class SuccessMessage extends React.Component {
  messageTimeout: number

  componentDidUpdate() {
    if (!this.messageTimeout) {
      this.messageTimeout = window.setTimeout(this.props.closeRsvp, 3000)
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.messageTimeout)
  }

  render() {
    return (
      <div className={styles.SuccessMessage}>
        <CompleteTick />

        <h3>We’ve got your request.</h3>

        <p>
          This is not a confirmation of your place on the guestlist. We will notify you by email before the event if
          your application is successful.
        </p>

        <CityUpdatesLink city={this.props.show.city} arrowColor={'#fff'} />
      </div>
    )
  }
}
