// @flow
import React from 'react'

import ShopNav from '../ShopNav'
import Page from '../Page'

import styles from './ShopLoading.less'

export default ({activeSlug}: {activeSlug?: string}) => (
  <Page subNavigation={<ShopNav activeSlug={activeSlug} />}>
    <div className={styles.Wrapper} />
  </Page>
)
