// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import Component from './Component'

const mapStateToProps = ({auth, loadLocation}: State) => ({
  isLoggedIn: auth.isLoggedIn,
  hostname: loadLocation.hostname,
})

export default connect(mapStateToProps)(Component)
