// @flow
import React from 'react'

import styles from './Logo.less'

type Props = {
  src: string,
  className?: string,
  large?: boolean,
}

export default ({src, className, large}: Props) => (
  <img src={src} className={[large ? styles.LogoLarge : styles.Logo, className].join(' ')} alt='Logo' />
)
