// @flow
import React from 'react'
import {connect} from 'react-redux'
import {useQuery} from '@apollo/client'

import type {State} from '../../stateType'

import GET_PRODUCTS from './Query'
import GET_SHOP_NAVIGATION from '../ShopNav/Query'

import Query from '../Query'
import ShopComponent from './Component'

const mapStateToProps = ({shop}: State) => ({
  shopLocale: shop.shopLocale,
})

const Shop = props => {
  const slug = props.match.params.slug || 'all'
  const {data, fetchMore} = useQuery(GET_SHOP_NAVIGATION, {
    variables: {
      slug: 'boiler-room-main-shop',
    },
  })
  const web_settings = data && data.web_settings
  const shop_nav = data && data.navigation

  return (
    <Query
      Component={ShopComponent}
      query={GET_PRODUCTS}
      variables={{collection: slug, country: props.shopLocale}}
      props={{slug, web_settings, shop_nav, fetchMore}}
      key={slug}
      context={{clientName: 'shopify'}}
    />
  )
}

export default connect(mapStateToProps)(Shop)
