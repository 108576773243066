// @flow
import React from 'react'

export default ({color}: {color?: string}) => {
  const fill = color || 'none'
  const strokeColor = color || '#fff'

  return (
    <svg width='20' height='20' viewBox='0 0 10 14' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.18568 11.3333C5.16387 11.3333 5.98787 11.3333 5.98787 11.3333C5.98787 11.3333 6.81164 11.3333 7.78964 11.3333M4.18568 11.3333C3.11809 11.3333 1.86686 11.3333 1.30271 11.3333C0.221632 11.3333 2.38369 9.33333 2.38371 8.33333C2.38373 6.74969 2.43031 5.5952 3.46488 4.33333C4.00941 3.66917 4.90668 3.33333 4.90668 3.33333C4.90668 3.33333 4.70396 2 5.98787 2C7.27179 2 7.06906 3.33333 7.06906 3.33333C7.06906 3.33333 7.96591 3.66917 8.51044 4.33333C9.54501 5.5952 9.23123 6.66667 9.59181 8.33333C9.82775 9.42393 11.7542 11.3333 10.673 11.3333C10.1088 11.3333 8.8573 11.3333 7.78964 11.3333M4.18568 11.3333C4.18568 11.3333 4.1861 13 5.98787 13C7.78964 13 7.78964 11.3333 7.78964 11.3333'
        stroke={strokeColor}
        strokeWidth='1px'
        vectorEffect='non-scaling-stroke'
      />
    </svg>
  )
}
