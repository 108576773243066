// @flow
import type {GenericItemType} from './types'
import {hasFutureYoutubePublishDate, publishedInLastNDays, isPremieringOnYoutube} from './youtubePublicationDate'

export default (item: GenericItemType): boolean => {
  if (!item || !item.recording) return false

  const {recording} = item

  const isUpcoming = hasFutureYoutubePublishDate(recording)
  const isNewRecording = publishedInLastNDays(recording, 2)
  const isLive = isPremieringOnYoutube(recording)

  return !!(isUpcoming || isNewRecording || isLive)
}
