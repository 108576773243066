import {gql} from '@apollo/client'

// soundcloud_track_id is required here for the FavouriteToggle to render a heart
// i.e. the recording has media

export default gql`
  query getAudioRecordingBySlug($id: Int!) {
    recording(id: $id) {
      id
      slug
      title
      soundcloud_track_id
      soundcloud_stream_url
      thumbnail_image
    }
  }
`
