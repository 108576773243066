// @flow
import React from 'react'
import {captureException} from '@sentry/browser'

import styles from './Basket.less'

import type {ShopifyCheckoutType, ShopifyLineItemType} from '../../common/types'
import {shopifyCollectionIds, shopTheme} from '../../common/constants'
import {parsePrice, emptyBasket} from '../../common/shop'
import {analytics} from '../../common/analytics'

import {GET_BASKET} from '../BasketMenu'
import {REMOVE_LINE_ITEM, UPDATE_LINE_ITEM} from './Mutations'

import Link from '../Link'
import Loading from '../Loading'
import Mutation from '../Mutation'
import Page from '../Page'
import Query from '../Query'
import ShopNav from '../ShopNav'
import ShopSlider from '../ShopSlider'

import websiteIcon from '../Socials/white/website.svg'
import deleteIcon from '../../images/blackCancel.svg'
import Arrow from '../Svgs/Arrow'
import captureMutationErrors from '../../common/captureMutationErrors'

import getCheckoutURL from './getCheckoutURL'

const paymentIcons = [
  'visa',
  'maestro',
  'mastercard',
  'amex',
  'discover',
  'diner',
  'union',
  'shop',
  'gpay',
]

type Props = {
  checkoutId: string,
  shopLocale: string,
  setCheckout: string => void,
  removeCheckout: () => void,
}

type BasketProps = {
  basket: ShopifyCheckoutType,
  loading: boolean,
  checkoutId: string,
  shopLocale: string,
  refetch: Function,
  removeLineItem: any,
  setCheckout: string => void,
  removeCheckout: () => void,
}

export default (props: Props) => (
  <Query
    props={props}
    Component={Basket}
    mutationName='removeLineItem'
    mutationSchema={REMOVE_LINE_ITEM}
    query={GET_BASKET}
    skip={!props.checkoutId}
    variables={{id: props.checkoutId, country: props.shopLocale}}
    context={{clientName: 'shopify'}}
  />
)

export class Basket extends React.Component {
  state: {
    removedItems: Array<string>,
    update: boolean,
  }

  constructor(props: BasketProps) {
    super(props)
    this.state = {
      removedItems: [''],
      update: true,
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.loading && !this.props.loading && this.props.basket) {
      const {basket} = this.props
      analytics.trackConversion('AW-855500528/8kFpCJn9rfECEPDN95cD')

      analytics.track('begin_checkout', {
        items: basket.lineItems.edges.map(product => ({
          id: product.node.variant.product.id,
          name: product.node.title,
        })),
      })

      analytics.track('view_cart', {
        currency: 'GBP',
        value: basket.subtotalPrice.amount,
        items:
          basket &&
          basket.lineItems &&
          basket.lineItems.edges.map(item => ({
            item_id: item.node.id,
            item_name: item.node.title,
            quantity: item.node.quantity,
            price: item.node.variant && item.node.variant.price && item.node.variant.price.amount,
          })),
      })
    }
  }

  removeItem = (item: ShopifyLineItemType) => {
    this.setState({removedItems: [item.id, ...this.state.removedItems]})
    this.removeItemShopify(item.id)

    analytics.track('remove_from_cart', {
      items: [
        {
          id: item.variant.product.id,
          name: item.title,
        },
      ],
    })
  }

  removeItemShopify = (id: string) =>
    this.props
      .removeLineItem({
        variables: {
          checkoutId: this.props.checkoutId,
          lineItemIds: [id],
          country: this.props.shopLocale,
        },
      })
      .then(({errors}) => {
        captureMutationErrors(errors)
        this.itemRemoved()
      })
      .catch(error => console.log(error))

  itemRemoved = () => {
    this.props.refetch()
    if (emptyBasket(this.props.basket)) this.props.removeCheckout()
  }

  render() {
    const {basket, checkoutId, loading} = this.props

    if (loading && !basket) return <Loading />

    const items = basket && basket.lineItems && basket.lineItems.edges
    const itemsRemoved = items && items.filter(item => !this.state.removedItems.includes(item.node.id))
    const basketEmptied = itemsRemoved && itemsRemoved.length < 1

    const basketEmpty = !checkoutId || !basket || basketEmptied

    if (basketEmpty && items) {
      this.props.removeCheckout() // If in the case that basketEmpty passes and the basket has items in still, we drop the checkout; see https://app.shortcut.com/boilerroom/story/11528/investigate-not-being-able-to-add-items-to-basket & https://app.shortcut.com/boilerroom/story/11527/investigate-item-remaining-in-cart
    }

    return (
      <Page title={'Basket'} iconColour='#000' invertColours={true} theme={shopTheme} subNavigation={<ShopNav />}>
        {basketEmpty ?
          <EmptyBasket /> :
          <div className={styles.Wrapper}>
            <div className={styles.Container}>
              <div className={styles.ItemsAndAddOnsWrapper}>
                <Items checkoutId={checkoutId} items={items} removeItem={this.removeItem} shopLocale={this.props.shopLocale} />
                <ShopSlider collectionId={shopifyCollectionIds.addOns} />
              </div>

              <Checkout total={basket.subtotalPrice.amount} url={basket.webUrl} currency={basket.subtotalPrice.currencyCode} />
            </div>

            <ShopSlider collectionId={shopifyCollectionIds.popular} enableProductCard={true} />
          </div>
        }
      </Page>
    )
  }
}

const EmptyBasket = () => (
  <div className={styles.Wrapper_EmptyBasket}>
    <div className={styles.EmptyBasket}>
      <h3>You have no items in your basket</h3>

      <Link internalLink='/shop' className={styles.ShopLink}>
        GO TO SHOP
        <Arrow color='#000' direction='right' />
      </Link>
    </div>
  </div>
)

const Items = ({checkoutId, items, removeItem, shopLocale}) => (
  <div className={styles.Items}>
    <div className={styles.Nav}>
      <Link internalLink={'/shop'}><Arrow color='#000' /> SHOP</Link>
    </div>

    {items.map(item => (
      <Mutation
        key={item.node.id}
        Component={Item}
        props={{
          checkoutId,
          item: item.node,
          removeItem: removeItem,
          shopLocale: shopLocale,
        }}
        mutationName='updateLineItemQuantity'
        variables={{country: shopLocale}}
        mutationSchema={UPDATE_LINE_ITEM}
        context={{clientName: 'shopify'}}
      />
    ))}
  </div>
)

class Item extends React.Component {
  reduceQuantity = () => {
    if (this.props.item.quantity === 1) return
    const newQuantity = this.props.item.quantity - 1
    this.updateQuantity(newQuantity)
    analytics.track('decrease-quantity', {item_title: this.props.item.title})
  }

  increaseQuantity = () => {
    const newQuantity = this.props.item.quantity + 1
    this.updateQuantity(newQuantity)
    analytics.track('increase-quantity', {item_title: this.props.item.title})
  }

  updateQuantity = quantity => {
    this.props.updateLineItemQuantity({
      variables: {
        checkoutId: this.props.checkoutId,
        lineItems: [
          {
            id: this.props.item.id,
            variantId: this.props.item.variant.id,
            quantity,
          },
        ],
        country: this.props.shopLocale,
      },
      refetchQueries: ['FetchBasket'],
    }).then(({errors}) => {
      captureMutationErrors(errors)
    })
      .catch(error => {
        captureException(error)
        console.log(error)
      })
  }

  render() {
    const {item, removeItem} = this.props

    return (
      <div className={styles.Item}>
        <img className={styles.ItemImage} src={item.variant.image.url} alt={item.variant.image.altText} />

        <div className={styles.ItemInfo}>
          <div>
            <div className={styles.ItemTitle}>{item.title}</div>

            {item.variant.title !== 'Default Title' && <div className={styles.ItemVariant}>Size {item.variant.title}</div>}

            <div className={styles.ItemSubtotal}>{parsePrice(item.variant.price.amount * item.quantity, item.variant.price.currencyCode)}</div>
          </div>
        </div>

        <img className={styles.Delete} alt='delete-icon' src={deleteIcon} onClick={() => removeItem(item)} />

        <QuantitySelector
          quantity={item.quantity}
          increaseQuantity={this.increaseQuantity}
          reduceQuantity={this.reduceQuantity}
        />
      </div>
    )
  }
}

const QuantitySelector = ({quantity, increaseQuantity, reduceQuantity}) => (
  <div className={styles.ItemQuantity}>
    <div className={styles[quantity === 1 ? 'Selector_Disabled' : 'Selector']} onClick={reduceQuantity}>
      -
    </div>

    <div className={styles.Quantity}>Qty {quantity}</div>

    <div className={styles.Selector} onClick={increaseQuantity}>
      +
    </div>
  </div>
)

const Checkout = ({total, currency, url}) => {
  const checkoutUrl = getCheckoutURL(url)

  return (
    <div className={styles.Checkout}>
      <div className={styles.Total}>
        <span className={styles.SubTotal}>SUBTOTAL</span>

        <span className={styles.SubTotalPrice}>{parsePrice(total, currency)}</span>

        <span className={styles.ShippingMessage}>Shipping calculated at checkout</span>
      </div>

      <a className={styles.CheckoutButton} href={checkoutUrl}>
        Checkout <img src={websiteIcon} alt='checkout-button' />
      </a>

      <div className={styles.SecureMessage}>
        <span>Secure via Shopify.com</span>
        <img src={require('./svgs/padlock.svg')} />
      </div>

      <div className={styles.CheckoutPaymentWrapper}>
        {paymentIcons.map((icon, index) => (
          <img
            // $FlowFixMe
            src={require(`./svgs/${icon}.svg`)}
            key={index}
            className={styles.CheckoutPayment}
          />
        ))}
      </div>
    </div>
  )
}
