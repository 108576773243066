// @flow
import React from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Link from '../Link'
import {actions as playerActions} from '../PersistentPlayer/state'
import type {StateType as PlayerType} from './state'
import type {State} from '../../stateType'
import type {LocationType} from '../../common/types'
import {mediaFormats} from '../../common/constants'

import Button from '../Button'
import SoundcloudPlayer from '../SoundcloudPlayer'

import ExpandIcon from './expand.svg'
import NextIcon from './next.svg'
import CloseIcon from '../Svgs/CloseIcon'

import styles from './PersistentPlayer.less'

export default () => {
  const dispatch = useDispatch()
  const {playerEmbedUrl, format, itemPath, queue, itemId, isAudioPlaying}: PlayerType = useSelector((state: State) => state.player)

  const {pathname}: LocationType = useSelector((state: State) => state.loadLocation)
  const isPlayerActive = itemPath === pathname

  // $FlowFixMe
  React.useEffect(() => {
    if (playerEmbedUrl && window.YT && format === mediaFormats.video) {
      window.YT.ready(() => {
        // eslint-disable-next-line no-new
        new window.YT.Player('youtube-player-iframe', {
          events: {
            onStateChange: event => {
              const videoEnded = event.data === 0
              if (videoEnded) dispatch(playerActions.loadNext())
            },
          },
        })
      })
    }
  }, [playerEmbedUrl])

  // load youtube iframe
  if (playerEmbedUrl && format === mediaFormats.video) {
    return (
      <div className={isPlayerActive ? styles.ActiveVideoWrapper : styles.DockedVideoWrapper}>
        {!isPlayerActive && <PersistentPlayerControls itemPath={itemPath} queue={queue} />}
        <div className={styles.VideoPlayer}>
          <iframe
            className={styles.VideoPlayerIframe}
            frameBorder={0}
            allowFullScreen='allowfullscreen'
            allow='autoplay'
            src={playerEmbedUrl}
            id='youtube-player-iframe'
          />
        </div>
      </div>
    )
  }

  // load custom soundcloud player
  if (itemId && format === mediaFormats.audio) {
    return (
      <SoundcloudPlayer
        id={itemId}
        isAudioPlaying={isAudioPlaying}
        queue={queue}
      />
    )
  }

  return null
}

const PersistentPlayerControls = ({itemPath, queue}) => {
  const dispatch = useDispatch()
  return (
    <div className={styles.ControlsWrapper}>
      <Link className={styles.Control} internalLink={itemPath}>
        <img src={ExpandIcon} className={styles.ImgIcon} />
        OPEN
      </Link>
      {queue && queue.length > 0 &&
        <Button
          className={styles.Control}
          onClick={() => dispatch(playerActions.loadNext())}
          text={<React.Fragment><img src={NextIcon} className={styles.ImgIcon} />NEXT</React.Fragment>}
          disableDefaultStyles={true}
        />
      }
      <Button
        className={styles.Control}
        onClick={() => dispatch(playerActions.close())}
        text={<React.Fragment><CloseIcon className={styles.CloseIcon} />CLOSE</React.Fragment>}
        disableDefaultStyles={true}
      />
    </div>
  )
}
