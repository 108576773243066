import {gql} from '@apollo/client'

import {ProfileFragment} from '../../common/fragments'

export default gql`
  query MailingPreferencesQuery {
    profile {
      ...FullProfile
    }
  }

  ${ProfileFragment}
`
