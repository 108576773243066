import React from 'react'
import styles from './GenreButton.less'

export default class GenreButton extends React.Component {
  props: {genre: String}
  render() {
    const {genre, slug} = this.props
    return (
      <a href={'/genre/' + slug} className={styles.GenreButton}>
        {genre}
      </a>
    )
  }
}
