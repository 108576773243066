// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import GET_NAVIGATION from './Query'

import type {BrandType} from '../../common/types'
import type {State as StateType} from '../../stateType'

import Query from '../Query'
import Logo from '../Svgs/Logo'
import FourThreeLogo from '../Svgs/FourThreeLogo'
import {brandIds} from '../../common/brands'
import lowHeatLogo from './lowHeat.svg'
import GasworksLogo from '../Svgs/Gasworks'
import Link from '../Link'
import Arrow from './Arrow'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import NavigationLink from '../NavigationLink'

import styles from './Navigation.less'
import VideoScheduleNav from '../VideoScheduleNav'
import {hasWindow, uiLocations} from '../../common/constants'

type Props = {
  activeSlug?: ?string,
  left_navigation: {
    navigation_elements: Array<{
      name: string,
      link: string,
    }>,
  },
  right_navigation: {
    navigation_elements: Array<{
      name: string,
      link: string,
    }>,
  },
  geo_location: string,
  brand: BrandType,
  pageWidth: number,
  loading: boolean,
  navParentPathname?: ?string,
}

const getHeaderNavigationsForBrand = ({brand}: {brand: BrandType}) => {
  switch (brand.id) {
    case brandIds.lowheat:
      return {left: 'lowheat-left-nav', right: 'lowheat-right-nav'}
    case brandIds.gasworks:
      return {left: 'gasworks-left-nav', right: 'gasworks-right-nav'}
    case brandIds.fourthree:
      return {left: 'fourthree-left-nav', right: 'fourthree-right-nav'}
    default:
      return {left: 'boilerroom-left-nav', right: 'boilerroom-right-nav'}
  }
}

const BrandLogo = ({brand}: {brand: BrandType}) => {
  switch (brand.id) {
    case brandIds.lowheat:
      return <img src={lowHeatLogo} />
    case brandIds.gasworks:
      return <GasworksLogo />
    case brandIds.fourthree:
      return <FourThreeLogo />
    default:
      return <Logo />
  }
}

export default () => {
  const {pageWidth, hideMainNavigation} = useSelector((state: StateType) => state.page)
  const brand = useSelector((state: StateType) => state.brand)

  if (hideMainNavigation) return null

  const navigationQuerySlug = getHeaderNavigationsForBrand({brand})

  return (
    <Query
      Component={Nav}
      query={GET_NAVIGATION}
      variables={{
        leftSlug: navigationQuerySlug.left,
        rightSlug: navigationQuerySlug.right,
      }}
      props={{pageWidth, brand}}
    />
  )
}

class Nav extends React.Component {
  state: {
    showScrollRight: boolean,
    showScrollLeft: boolean,
  }

  containerRef: {current: HTMLDivElement}

  constructor(props: Props) {
    super(props)
    // $FlowFixMe
    this.containerRef = React.createRef()
    this.state = {
      showScrollLeft: false,
      showScrollRight: false,
    }
  }

  showScroll = () => {
    if (!this.containerRef) return null

    const navigation = this.containerRef.current
    // Check if the scrollable width is bigger than the div width
    const isScrollable = navigation && navigation.offsetWidth < navigation.scrollWidth
    // Work out the maximum scroll position by removing the div width from the scrollable width
    const maxScrollLeft = navigation && navigation.scrollWidth - navigation.offsetWidth

    // Show the right scroll arrow so long as the scroll position is less than the maximum
    const showScrollRight = !!(
      isScrollable &&
      maxScrollLeft !== null &&
      navigation &&
      navigation.scrollLeft < maxScrollLeft
    )
    // Show the left scroll arrow so long as the scroll position is more than 0
    const showScrollLeft = !!(isScrollable && navigation && navigation.scrollLeft > 0)

    this.setState({showScrollRight, showScrollLeft})
  }

  componentDidUpdate(prevProps: Props) {
    const justFinishedLoading = prevProps.loading && !this.props.loading
    const pageWasResized = prevProps.pageWidth !== this.props.pageWidth
    if (justFinishedLoading || pageWasResized) {
      this.showScroll()
    }
  }

  render() {
    const leftNavigation = this.props.left_navigation && this.props.left_navigation.navigation_elements
    const rightNavigation = this.props.right_navigation && this.props.right_navigation.navigation_elements
    const isFourThree = this.props.brand.id === brandIds.fourthree
    const arrowColour = isFourThree ? '#000' : '#FFF'
    const showVideoScheduleSubNavigation = hasWindow && (window.location.href.match(/^\/(schedule|charts)\/?/) || window.location.href.includes('/video'))

    return (
      <div>
        <div className={styles.Buffer} />

        <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
          <div onScroll={this.showScroll} className={styles.Container} ref={this.containerRef}>
            <Link internalLink='/' className={styles.Logo} uiLocation={uiLocations.navigationLogo}>
              <BrandLogo brand={this.props.brand} />
            </Link>

            <div className={styles.Links}>
              {leftNavigation &&
                leftNavigation.map((item, index) => (
                  <NavigationLink
                    isParentPath={true}
                    item={item}
                    className={styles.Link}
                    activeClassName={styles.ActiveLink}
                    key={item.link}
                    uiLocation={uiLocations.navigationLink}
                  />
                ))}
            </div>

            <div className={styles.Links}>
              {rightNavigation &&
                rightNavigation.map((item, index) => (
                  <NavigationLink
                    isParentPath={true}
                    item={item}
                    className={styles.Link}
                    activeClassName={styles.ActiveLink}
                    key={item.link}
                    uiLocation={uiLocations.navigationLink}
                  />
                ))}
            </div>

            {this.state.showScrollLeft &&
              <div className={styles.ArrowLeft}>
                <Arrow colour={arrowColour} />
              </div>
            }

            {this.state.showScrollRight &&
              <div className={styles.ArrowRight}>
                <Arrow colour={arrowColour} />
              </div>
            }

          </div>
        </BrandedStyleWrapper>

        {showVideoScheduleSubNavigation && <VideoScheduleNav />}
      </div>
    )
  }
}
