// @flow
import {captureException} from '@sentry/browser'

export default (errors: Array<{message: string}>) => {
  if (!errors || errors.length === 0) return null
  errors.map(error => {
    if (error.message) {
      console.error(error.message)
      captureException(error.message)
    }
  })
}
