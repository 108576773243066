// @flow
import React from 'react'

import Background from '../Background'

import {upcomingRecordingsImage} from '../../common/brands'
import embedUrl from '../../common/embedUrl'
import Padlock from '../Svgs/Padlock'
import styles from './Player.less'
import {analytics} from '../../common/analytics'

type Props = {|
  isLoggedIn: boolean,
  hostname: string,

  overrideUrl: ?string,
  geoblocked: boolean,
  hlsUrl?: ?string,
  overrideLoggedIn?: boolean,
  soundcloudId?: ?string,
  soundcloudSecretToken?: ?string,
  title: string,
  vimeoId?: ?string,
  youtubeId?: ?string,
  displayChat?: boolean,
  paywalled?: boolean,
  backgroundImage?: ?string,
|}

export default class Player extends React.Component {
  props: Props

  constructor(props: Props) {
    super(props)
    if (this.props.geoblocked) {
      analytics.track('content_geoblocked', {title: props.title})
    }
  }

  render() {
    const {
      geoblocked,
      hlsUrl,
      isLoggedIn,
      overrideLoggedIn,
      soundcloudId,
      soundcloudSecretToken,
      vimeoId,
      youtubeId,
      overrideUrl,
      hostname,
      displayChat,
      paywalled,
      backgroundImage,
    } = this.props

    const autoplay = isLoggedIn || overrideLoggedIn ? 1 : 0

    const findVimeoId = overrideUrl && overrideUrl.match(/(?:player\.vimeo\.com\/video\/)(.*)(?=\?)/)
    const vimeoIdfromOverride = findVimeoId && findVimeoId[1]

    const streamData = {
      autoplay,
      hlsUrl,
      soundcloudId,
      soundcloudSecretToken,
      vimeoId,
      youtubeId,
    }

    return (
      <div className={styles.Wrapper}>
        <div className={styles.Player}>
          {!geoblocked && !paywalled && <Background image={backgroundImage || upcomingRecordingsImage} />}

          {geoblocked ?
            <div className={styles.NotAvailable}>Unfortunately this video is not available in your region.</div> :
            <iframe
              className={styles.PlayerIframe}
              frameBorder={0}
              allowFullScreen='allowfullscreen'
              src={overrideUrl || embedUrl(streamData)}
            />
          }

          {paywalled &&
            <div className={styles.Paywalled}>
              <Padlock color={'#fff'} />
            </div>
          }
        </div>

        {displayChat && youtubeId &&
          <iframe
            src={`https://www.youtube.com/live_chat?v=${youtubeId}&embed_domain=${hostname}`}
            frameBorder={0}
            className={styles.Chat}
          />
        }

        {displayChat && vimeoIdfromOverride &&
          <iframe src={`https://vimeo.com/live-chat/${vimeoIdfromOverride}`} frameBorder={0} className={styles.Chat} />
        }
      </div>
    )
  }
}
