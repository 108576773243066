// @flow
import React from 'react'
import Cookies from 'js-cookie'

import type {BrandType, ProfileType} from '../../common/types'
import {hasWindow, uiLocations} from '../../common/constants'

import {brandIds} from '../../common/brands'

import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Auth from '../Auth'
import Background from '../Background'
import OptInGate from '../OptInGate'

import CloseIcon from '../Svgs/CloseIcon'

import styles from './Gate.less'

type Props = {
  brand: BrandType,
  data: {
    profile: ProfileType,
  },
  refetch: Function,
  closeGate: () => void,
  isLoggedIn: boolean,
  loading: boolean,
  userOpenedGate: boolean,
  requiresLogin: boolean,
  optInGateTags?: ?Array<string>,
  disableOptInGate?: ?boolean,
  itemType: ?string,
  itemId: ?string,
}

export default class Gate extends React.Component {
  props: Props

  componentDidMount = () => {
    document.addEventListener('keydown', this.escapeClose, false)
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.props.refetch()
    }
  }

  onCloseGate = () => {
    this.props.closeGate()
    Cookies.remove('openRsvpAfterLogin')
  }

  escapeClose = (event: any) => event.key === 'Escape' && this.props.closeGate()

  render() {
    // Never render the gate server side, or if auth is loading
    if (!hasWindow || this.props.loading) return null

    const profile = this.props.data && this.props.data.profile
    const userConsents = profile && profile.consents

    // If a user has never made a consent object for current brands
    // mailing list, show them the mailing list opt in prompt
    const hasNeverConsented = !(
      userConsents && userConsents.find(consent => consent.title.includes(this.props.brand.title))
    )
    if (this.props.isLoggedIn && hasNeverConsented && !this.props.disableOptInGate) {
      return <OptInGate tags={this.props.optInGateTags || []} />
    }

    // If a user has not logged in but the content requires login
    // or if the user manually opens it, show the login/signup gate
    const contentRequiresLoginAndUserIsNotLoggedIn = this.props.requiresLogin && !this.props.isLoggedIn
    const loggedOutUserOpenedGate = !this.props.isLoggedIn && this.props.userOpenedGate
    if (contentRequiresLoginAndUserIsNotLoggedIn || loggedOutUserOpenedGate) {
      const isFourThree = this.props.brand && this.props.brand.id === brandIds.fourthree
      return (
        <AuthGate
          requiresLogin={this.props.requiresLogin}
          closeGate={this.onCloseGate}
          isFourThree={isFourThree}
          itemType={this.props.itemType}
          itemId={this.props.itemId}
        />
      )
    }

    return null
  }
}

const AuthGate = ({requiresLogin, closeGate, isFourThree, itemType, itemId}) => (
  <BrandedStyleWrapper classNameKey='Gate' styles={styles}>
    {!requiresLogin && <div className={styles.CloseOverlay} onClick={closeGate} />}
    <div className={styles.Content}>
      <Background
        image={isFourThree ? '' : 'https://misc.boilerroom.tv/loginBackgroundGradient.png'}
        className={styles.BackgroundImage}
      />
      <Auth
        uiLocation={uiLocations.loginGate}
        itemType={itemType}
        itemId={itemId}
      />
      {!requiresLogin && <CloseIcon className={styles.CloseIcon} onClick={closeGate} fill={isFourThree && '#000'} />}
    </div>
  </BrandedStyleWrapper>
)
