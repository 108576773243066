import React from 'react'
import {gql} from '@apollo/client'
import {Query} from '@apollo/client/react/components'
import {BrandFragment, GenericItemFragment} from '../../common/fragments'

import getParameterByName from '../../common/getParameterByName'

import Component from './Component'

const GET_ARTICLE = gql`
  query ArticleQuery($slug: String!, $signature: String) {
    article: article_by_slug(slug: $slug, signature: $signature) {
      id
      title
      author
      slug
      summary
      released
      content

      big_header_enabled
      big_header_text_color

      background_image
      thumbnail_image
      trailer_video
      logo_image

      sponsor {
        id
        name
        logo_image
        url
      }

      brand {
        ...FullBrand
      }

      requires_login

      related_playlists {
        id
        title
        description
        summary
        slug
        items {
          ...FullGenericItem
        }
      }
    }
  }

  ${BrandFragment}
  ${GenericItemFragment}
`

export default props => (
  <Query
    query={GET_ARTICLE}
    variables={{
      slug: props.match.params.slug,
      signature:
        (props.staticContext && props.staticContext.signature) ||
        getParameterByName('signature', props.location.search),
    }}
  >
    {({loading, data}) => <Component {...props} data={data} loading={loading} />}
  </Query>
)
