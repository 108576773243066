import {gql} from '@apollo/client'
import {RecordingCardFragment} from '../../common/fragments'

export default gql`
  query ContinentQuery($slug: String!, $limit: Int, $offset: Int) {
    continent: continent_by_slug(slug: $slug) {
      name
      artists_count

      recordings_count
      recordings(limit: $limit, offset: $offset) {
        ...RecordingCard
      }

      shows_count
      shows(limit: $limit) {
        id
        title
        city {
          id
          name
        }
        flyer_image
        broadcast_status
        start
        end
        slug
        date_display_format
      }
    }
  }
  ${RecordingCardFragment}
`
