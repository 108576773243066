import {gql} from '@apollo/client'

import {ShowFragment} from '../../common/fragments'

export const GET_43_SHOWS = gql`
  query FourThreeEvents {
    upcoming: shows(status: ["live", "relooping", "upcoming"], brand_id: 1) {
      shows {
        ...FullShow
      }
    }

    archive: shows(status: ["archived", "offair"], brand_id: 1, orderBy: "start", orderDirection: "desc") {
      shows {
        ...FullShow
      }
    }
  }

  ${ShowFragment}
`

export const GET_BR_SHOWS_PAGINATED = gql`
  query BoilerRoomEvents($limit: Int, $offset: Int) {
    pastShows: shows(status: ["archived", "offair"], brand_id: 7, limit: $limit, offset: $offset, orderBy: "start", orderDirection: "desc") {
      shows {
        ...FullShow
      }
      shows_count
    }
  }

  ${ShowFragment}
`
