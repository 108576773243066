import {gql} from '@apollo/client'
import {CheckoutFragment} from '../../common/fragments'

export const REMOVE_LINE_ITEM = gql`
  mutation ($checkoutId: ID!, $lineItemIds: [ID!]!, $country: CountryCode) @inContext(country: $country) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ...Checkout
      }
    }
  }
  ${CheckoutFragment}
`

export const UPDATE_LINE_ITEM = gql`
  mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!, $country: CountryCode) @inContext(country: $country) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...Checkout
      }
    }
  }
  ${CheckoutFragment}
`
