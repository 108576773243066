// @flow
import React from 'react'

export default ({className, color, direction}: {className?: string, color?: string, direction?: string}) => {
  const stroke = color || 'currentColor'

  if (direction === 'left') {
    return (
      <svg className={className} width='12' height='19' viewBox='0 0 12 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11 1L1.89639 8.73806C1.42659 9.13739 1.42659 9.86261 1.89639 10.2619L11 18'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          vectorEffect='non-scaling-stroke'
        />
      </svg>
    )
  }

  return (
    <svg className={className} width='12' height='19' viewBox='0 0 12 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 18L10.1036 10.2619C10.5734 9.86261 10.5734 9.13739 10.1036 8.73806L1 1'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        vectorEffect='non-scaling-stroke'
      />
    </svg>
  )
}
