// @flow
import React from 'react'
import styles from './Icon.less'

type Props = {
  className?: ?string,
  src: any,
  onClick?: Function,
}

export default ({className, src, onClick}: Props) =>
  onClick ?
    <button className={[styles.Icon, className].join(' ')} onClick={onClick}>
      <img src={src} alt='Icon' />
    </button> :
    <div className={className}>
      <img src={src} alt='Icon' />
    </div>
