import {gql} from '@apollo/client'

import {
  RecordingFragment,
  RecordingCardFragment,
  GenericItemFragment,
  BrandFragment,
  ThemeFragment,
} from '../../common/fragments'

export default gql`
  query RecordingQuery($slug: String!, $signature: String) {
    recording: recording_by_slug(slug: $slug, signature: $signature) {
      ...FullRecording

      theme {
        ...FullTheme
      }

      tracklist {
        tracks {
          artist
          title
        }
      }

      show {
        id
        flyer_image
        slug
        date_display_format
        city {
          name
        }

        recordings {
          id
          title
          description
          director
          duration
          released
          slug
          background_image
          thumbnail_image
          youtube_video_id
          youtube_publish_date
          vimeo_video_id
          soundcloud_track_id
          embed_override_url

          city {
            name
          }

          formats {
            id
            title
          }

          genres {
            id
            title
          }

          series {
            id
            title
            type
            slug
          }
        }
      }

      series {
        id
        title
        description
        type
        slug

        brand {
          ...FullBrand
        }

        recordings {
          ...RecordingCard
        }
      }

      suggestions {
        ...FullRecording
        genres {
          id
          title
        }
        sponsor {
          id
          name
          url
          logo_image
        }
      }

      playlists {
        id
        title
        type
        slug
        items(limit: 8) {
          ...FullGenericItem
        }
      }
    }
  }

  ${RecordingFragment}
  ${RecordingCardFragment}
  ${GenericItemFragment}
  ${BrandFragment}
  ${ThemeFragment}
`
