import React from 'react'
import {Query} from '@apollo/client/react/components'
import {connect} from 'react-redux'

import type {State} from '../../stateType'

import GET_PROFILE from './Query'
import Component from './Component'

import {actions as authActions} from '../Auth/state'

const mapStateToProps = ({auth}: State) => ({
  shouldRedirect: !auth.isLoggedIn && !auth.loading,
})

const mapDispatchToProps = {
  logout: authActions.logout,
}

const Account = props => (
  <Query query={GET_PROFILE} fetchPolicy='no-cache'>
    {({data, loading}) => <Component {...props} data={data} loading={loading} />}
  </Query>
)

export default connect(mapStateToProps, mapDispatchToProps)(Account)
