// @flow
import React from 'react'
import {gql} from '@apollo/client'
import {Query} from '@apollo/client/react/components'

import connect from '../../common/safeConnect'

import type {State} from '../../stateType'

import Component from './Component'

const mapStateToProps = ({shop}: State) => ({
  checkoutId: shop.checkoutId,
  shopLocale: shop.shopLocale,
})

export const GET_BASKET = gql`
  query FetchBasket($id: ID!, $country: CountryCode) @inContext(country: $country) {
    basket: node(id: $id) {
      ... on Checkout {
        completedAt
        id
        subtotalPrice {
          amount
          currencyCode
        }
        webUrl
        customAttributes {
          key
          value
        }
        lineItems(first: 10) {
          edges {
            node {
              id
              quantity
              title
              variant {
                id
                image {
                  altText
                  url
                }
                price {
                  amount
                  currencyCode
                }
                title
                product {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

const BasketMenu = props => {
  return (
    <Query query={GET_BASKET} variables={{id: props.checkoutId, country: props.shopLocale}} skip={!props.checkoutId} context={{clientName: 'shopify'}}>
      {({data}) => {
        return <Component {...props} data={data} />
      }}
    </Query>
  )
}

export default connect(mapStateToProps)(BasketMenu)
