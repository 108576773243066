import {gql} from '@apollo/client'
import {ProfileFragment, RecordingFragment} from '../../common/fragments'

export default gql`
  query FavouriteQuery {
    profile {
      ...FullProfile
    }
  }

  ${ProfileFragment}
  ${RecordingFragment}
`
