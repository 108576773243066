import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import type {PlayerType, State, LocationType} from '../../common/types'
import {actions as playerActions} from '../PersistentPlayer/state'

import Button from '../Button'
import Link from '../Link'

import NextIcon from '../PersistentPlayer/next.svg'

import styles from './UpNextButton.less'
import paths from '../../common/paths'

export default ({location}: {location: LocationType}) => {
  const dispatch = useDispatch()
  const {queue}: PlayerType = useSelector((state: State) => state.player)

  if (!queue || queue.length === 0) return null

  const nextRecording = queue[0]
  const isAudioPath = location.pathname && location.pathname.includes('/audio/')

  return (
    <Link internalLink={paths.recording(nextRecording, isAudioPath)}>
      <Button
        className={isAudioPath ? styles.AudioUpNextButton : styles.UpNextButton}
        onClick={() => dispatch(playerActions.loadNext())}
        text={
          <React.Fragment>
            <img src={NextIcon} className={styles.ImgIcon} />
            <span className={styles.UpNextText}>UP NEXT</span>
            <span>{nextRecording.title}</span>
          </React.Fragment>
        }
        disableDefaultStyles
      />
    </Link>
  )
}
