// @flow
import React from 'react'

import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Card from '../Card'
import BroadcastData from '../BroadcastData'
import Background from '../Background'

import {itemizeCollection} from '../../common/itemize'
import {genericItemTypes, uiLocations} from '../../common/constants'

import Grid, {GridItem} from '../Grid'

import styles from './ParentShowPanel.less'

export default class ParentShowPanel extends React.Component {
  render() {
    const {parentShow} = this.props
    const childShows = itemizeCollection(parentShow.child_shows, genericItemTypes.show)
    return (
      <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
        <Background image={parentShow.background_image} dim={0.5} />
        <div className={styles.ChildShows}>
          <div className={styles.ParentShowInfo}>
            <a href={parentShow.web_url}>
              <h2>{parentShow.title}</h2>
            </a>
            <BroadcastData show={parentShow} disableCityLink={true} />
            <p className={styles.Description}>{parentShow.summary}</p>
          </div>
          <Grid gutter={false} className={styles.Grid}>
            {childShows.map(childShow => (
              <ChildShow key={childShow.id} childShow={childShow} />
            ))}
          </Grid>
        </div>
      </BrandedStyleWrapper>
    )
  }
}

class ChildShow extends React.Component {
  render() {
    const {childShow} = this.props
    return (
      <GridItem className={styles.Card} responsiveWidths={{small: 12, medium: 4, large: 4, extraLarge: 4}}>
        <Card item={childShow} uiLocation={uiLocations.parentShowPanelCard} />
      </GridItem>
    )
  }
}
