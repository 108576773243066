// @flow
import React from 'react'

export default class FourThreeLogo extends React.PureComponent {
  props: {|
    className?: string,
    colour?: string,
    onClick?: Function,
    style?: Object,
  |}

  render() {
    const {className, colour, onClick, style} = this.props
    const fill = colour || '#000'

    return (
      <div className={className} onClick={onClick} style={style}>
        <svg width='81px' height='62px' viewBox='0 0 81 62' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='fourThree' transform='translate(1.000000, 1.000000)'>
              <rect stroke={fill} x='0.1875' y='0.3375' width='79.125' height='59.3625' strokeWidth='1.5' />
              <g transform='translate(14.625000, 17.250000)' fillRule='nonzero' fill={fill}>
                <path d='M15.7125,19.4625 L15.7125,24.9375 L10.3125,24.9375 L10.3125,19.5 C6.9,19.5 3.525,19.5 0.1125,19.5 C0.1125,19.3875 0.1125,19.275 0.1125,19.1625 C0.1125,17.925 0.1125,16.725 0.1125,15.4875 C3.675,10.575 7.275,5.6625 10.8375,0.7125 C12.3375,0.7125 13.875,0.7125 15.375,0.7125 C15.45,0.7125 15.5625,0.7125 15.675,0.7125 C15.675,3.1125 15.675,5.5125 15.675,7.875 C15.675,10.2375 15.675,12.6375 15.675,15.0375 L19.35,15.0375 L19.35,19.4625 L15.7125,19.4625 L15.7125,19.4625 Z M10.3125,4.2 C7.65,7.8375 5.025,11.4375 2.4,15.0375 L10.3125,15.0375 L10.3125,4.2 Z' />
                <path d='M33.5625,6.975 C33.7125,6.45 33.7875,5.925 33.975,5.475 C34.9875,2.625 37.05,0.975 39.975,0.3375 C41.625,2.10526041e-14 43.3125,0.0375 44.925,0.525 C46.8,1.0875 48.3375,2.2125 48.975,4.65 C48.2625,7.275 46.5375,8.55 44.55,9.525 C44.25,9.675 43.9125,9.825 43.5,10.0125 C43.6875,10.0875 43.7625,10.125 43.8375,10.1625 C45.45,10.6875 46.875,11.5125 48.075,12.7125 C50.1,14.775 50.6625,17.85 49.3875,20.3625 C48.75,21.675 47.7375,22.6875 46.5,23.4375 C44.1375,24.8625 41.5875,25.3875 38.85,25.275 C37.125,25.2 35.475,24.8625 33.8625,24.375 C33.7125,24.3375 33.6375,24.2625 33.6375,24.075 C33.6375,22.2 33.6375,20.325 33.6375,18.45 C33.6375,18.375 33.6375,18.3375 33.6375,18.2625 C33.675,18.2625 33.7125,18.225 33.7125,18.225 C34.2,18.1875 34.1625,18.1875 34.35,18.6375 C34.8,19.9875 35.4375,21.225 36.45,22.2375 C38.3625,24.225 40.9875,24.525 44.7,22.275 C45.525,16.6125 43.05,13.9875 38.925,12.975 C38.475,12.9375 38.3625,12.9375 38.3625,12.7125 C38.3625,12.1875 38.3625,12.1875 38.8125,12 C39.9375,11.5125 40.9875,10.875 41.8125,9.975 C42.75,8.8875 43.35,7.6875 43.35,6.225 C43.35,4.9125 42.7125,3.975 41.475,3.4875 C39.75,2.8125 37.3125,3.0375 35.85,4.6125 C35.2875,5.2125 34.875,5.925 34.65,6.7125 C34.575,6.9 34.5,7.0125 34.275,6.975 C34.125,6.975 33.9,6.975 33.5625,6.975 Z' />
                <path d='M23.4375,9.9 C23.4375,8.1375 24.7875,6.75 26.5125,6.75 C28.3125,6.75 29.6625,8.1 29.6625,9.9 C29.6625,11.6625 28.3125,13.0125 26.5125,13.0125 C24.7875,13.0125 23.4375,11.6625 23.4375,9.9 Z' />
                <path d='M26.5125,19.125 C28.3125,19.125 29.6625,20.475 29.6625,22.2375 C29.6625,24.0375 28.3125,25.35 26.4375,25.35 C24.7125,25.35 23.4,23.9625 23.4,22.2 C23.4375,20.475 24.7875,19.125 26.5125,19.125 Z' />
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
