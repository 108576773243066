// @flow
import React from 'react'

import {GridItem} from '../Grid'
import {gridItemWidths} from '../../common/constants'
import Card from '../Card'

export default () =>
  Array.from(Array(24).keys()).map(id => (
    <GridItem responsiveWidths={gridItemWidths} key={`result_${id}`}>
      <Card />
    </GridItem>
  ))
