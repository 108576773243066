// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

import getVideoEmbedUrl from '../../common/getVideoEmbedUrl'
import paths from '../../common/paths'

import {mediaFormats, genericItemTypes} from '../../common/constants'
import type {RecordingType} from '../../common/types'
import {analytics} from '../../common/analytics'
import {recordingHasMedia} from '../../common/audioVideo'

type PlayerFormatType = 'audio' | 'video'

export const types = {
  load: 'PersistentPlayer/Load',
  clear: 'PersistentPlayer/Clear',
  setQueue: 'PersistentPlayer/SetQueue',
  setAudioPlaying: 'PersistentPlayer/ToggleAudioPlaying',
}

const plainActions = mapValues(types, type => createAction(type))

const trackPlayerCloseEvent = (itemSlug: string, format: PlayerFormatType) => {
  analytics.track('player_close', {
    player_item_type: genericItemTypes.recording,
    player_item_slug: itemSlug,
    player_format: format,
  })
}

const getFormat = (nextRecording, currentFormat) => {
  // if the next recording has media available in the current format
  // keep the format the same
  const canStayVideo =
    currentFormat === mediaFormats.video && nextRecording.youtube_video_id
  const canStayAudio =
    currentFormat === mediaFormats.audio && nextRecording.soundcloud_track_id
  if (canStayVideo || canStayAudio) {
    return currentFormat
  }

  // switch to video if recording only has video
  const recordingOnlyHasVideo = nextRecording.youtube_video_id && !nextRecording.soundcloud_track_id
  if (recordingOnlyHasVideo) return mediaFormats.video

  // switch to audio if recording only has audio
  const recordingOnlyHasAudio = nextRecording.soundcloud_track_id && !nextRecording.youtube_video_id
  if (recordingOnlyHasAudio) return mediaFormats.audio

  return currentFormat
}

const loadQueue = (recordings: [RecordingType], formatOverride?: String) => (dispatch, getState) => {
  // load first recording to play
  const firstRecording = recordings[0]
  // default format is video
  const {player: {format: currentFormat, itemPath: currentItemPath, isAudioPlaying}} = getState()
  const format = formatOverride || getFormat(firstRecording, currentFormat)
  const isAudio = format === mediaFormats.audio
  const itemPath = paths.recording(firstRecording, isAudio)
  const playerEmbedUrl = isAudio ?
    firstRecording.soundcloud_stream_url : getVideoEmbedUrl(firstRecording)

  const isFirstAudioRecordingPlaying = isAudioPlaying && itemPath === currentItemPath
  const toggleIsAudioPlaying = isFirstAudioRecordingPlaying ? !isAudioPlaying : true

  dispatch(
    plainActions.load({
      playerEmbedUrl,
      format,
      itemPath,
      itemSlug: firstRecording.slug,
      itemId: firstRecording.id,
      isAudioPlaying: isAudio && toggleIsAudioPlaying,
    }),
  )

  const eventData = {
    player_item_type: genericItemTypes.recording,
    player_item_slug: firstRecording.slug,
    player_format: format,
  }

  analytics.track('player_load_queue', eventData)

  // Track player_load_queue on Braze
  try {
    const braze = require('@braze/web-sdk')
    braze.logCustomEvent('player_load_queue', eventData)
  } catch (e) {
    // non-attributable plays fail gracefully
  }

  // put rest of recordings with YouTube or SoundCloud IDs in the queue
  // or set it back to an empty array
  const queue = recordings.slice(1).filter(recordingHasMedia)
  dispatch(plainActions.setQueue({queue}))
}

const loadNext = () => (dispatch, getState) => {
  // simply call loadQueue() with the current queue
  // this will replace the currently playing item
  // with the first from the queue, and shift the queue
  // forward by one
  const {player} = getState()
  analytics.track('player_load_next', {
    player_item_type: genericItemTypes.recording,
    player_item_slug: player.itemSlug,
    player_format: player.format,
  })
  dispatch(actions.loadQueue(player.queue))
}

const close = () => (dispatch, getState) => {
  const {player} = getState()
  trackPlayerCloseEvent(player.itemSlug, player.format)
  dispatch(plainActions.clear())
}

const toggleAudioPlaying = () => (dispatch, getState) => {
  dispatch(plainActions.setAudioPlaying())
}

export const actions = {
  ...plainActions,
  loadQueue,
  loadNext,
  close,
  toggleAudioPlaying,
}

export type StateType = {
  playerEmbedUrl: ?string,
  format: PlayerFormatType,
  itemPath: ?string,
  itemSlug: ?string,
  queue: Array<RecordingType>,
  isAudioPlaying: ?boolean,
  itemId: ?number,
}

const initialState: StateType = {
  playerEmbedUrl: null,
  format: mediaFormats.video,
  itemPath: null,
  itemSlug: null,
  queue: [],
  isAudioPlaying: false,
  itemId: null,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.load:
      return {
        queue: state.queue,
        playerEmbedUrl: action.payload.playerEmbedUrl,
        format: action.payload.format,
        itemPath: action.payload.itemPath,
        itemSlug: action.payload.itemSlug,
        isAudioPlaying: action.payload.isAudioPlaying,
        itemId: action.payload.itemId,
      }
    case types.clear:
      return {
        ...initialState,
        format: state.format,
      }
    case types.setQueue:
      return {
        ...state,
        queue: action.payload.queue,
      }
    case types.setAudioPlaying:
      return {
        ...state,
        isAudioPlaying: !state.isAudioPlaying,
      }
    default:
      return state
  }
}
