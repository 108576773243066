// @flow
import React from 'react'

export default class Arrow extends React.PureComponent {
  props: {|
    className?: string,
    colour?: string,
  |}

  render() {
    const {colour, className} = this.props
    const fill = colour || '#FFF'

    return (
      <svg className={className} viewBox='0 0 12 10' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='mobile' transform='translate(-322.000000, -22.000000)' fill={fill} fillRule='nonzero'>
            <polygon
              id='⃡'
              points='329.313131 31.1043771 334 26.5521886 329.313131 22 329.313131 25.5286195 322.47138 25.5286195 322.47138 27.6296296 329.313131 27.6296296'
            />
          </g>
        </g>
      </svg>
    )
  }
}
