// @flow
import React from 'react'

import {Redirect} from 'react-router-dom'

import Page from '../Page/'
import Auth from '../Auth/'
import Loading from '../Loading/'
import BrandedStyleWrapper from '../BrandedStyleWrapper/'
import {uiLocations} from '../../common/constants'

import {brandIds} from '../../common/brands'

import styles from './LoginPage.less'
import Background from '../Background'

export default class LoginPage extends React.Component {
  render() {
    const {brand, shouldRedirect, loading} = this.props
    const isFourThree = brand && brand.id === brandIds.fourthree

    if (loading) return <Loading />
    if (shouldRedirect) return <Redirect to={'/'} />

    return (
      <Page title='Signup/Login' requiresLogin={false} disableHeaderOffset={true} footer={null} allBrandPage={true}>
        <BrandedStyleWrapper classNameKey='Wrapper' styles={styles}>
          <Background image={isFourThree ? '' : 'https://misc.boilerroom.tv/loginBackgroundGradient.png'} />
          <Auth uiLocation={uiLocations.loginPage} />
        </BrandedStyleWrapper>
      </Page>
    )
  }
}
