// @flow
import React from 'react'

import type {HomepageThemeType, BrandType, LocationType} from '../../common/types'
import withDefault from '../../common/withDefault'
import {brandIds, getFullURL, getBrandFromId} from '../../common/brands'
import {hasWindow} from '../../common/constants'

import Blocker from '../Blocker/'
import HeaderMeta from '../HeaderMeta/'
import Footer from '../Footer'
import ErrorBoundary from './ErrorBoundary'
import Gate from '../Gate'
import Notification from '../Notifications'
import Redirect from '../Redirect'
import SubscribeOverlay from '../SubscribeOverlay'

import styles from './Page.less'
import {analytics} from '../../common/analytics'

type Props = {
  children: any,
  header?: ?React.Element<*>,
  subNavigation?: ?React.Element<*>,
  footer?: ?React.Element<*>,
  hideFooter?: boolean,
  invertColours?: boolean,
  title?: string,
  description?: string,
  image?: string,
  theme?: ?HomepageThemeType,
  requiresLogin?: boolean,
  redirectUrl?: ?string,
  brand: BrandType,
  brands: Array<BrandType>,
  allBrandPage?: boolean,
  brandId?: ?number,
  loadLocation: LocationType,
  contentColumn?: boolean,
  isRedirecting: boolean,
  isLoggedIn: boolean,
  endRedirect: () => void,
  hideMainNavigation: () => void,
  showMainNavigation: () => void,
  mutate: any,
  disableScroll?: ?boolean,
  enableSubscribeOverlay?: ?boolean,
  userOpenedGate: boolean,
  isPasswordProtected?: ?boolean,
  ssr: boolean,
  optInGateTags?: ?Array<string>,
  disableOptInGate?: boolean,
  disableFooterSignup?: boolean,
}

export default class Page extends React.Component {
  props: Props

  getRedirectUrl() {
    const {brand, redirectUrl} = this.props
    const location = hasWindow ? window.location : this.props.loadLocation

    if (redirectUrl) return redirectUrl

    // This logic ensures only the root URL can be viewed
    // on project/custom subdomains
    const brandHomeUrl = getFullURL(brand)
    const isNotRoot = location.pathname.length > 1
    const isOnASubdomain = brandHomeUrl.indexOf(`//${location.hostname}`) !== 0
    if (isNotRoot && isOnASubdomain) {
      return `${brandHomeUrl}${location.pathname}${location.search}`
    }

    // This logic ensures that things are viewed on the correct
    // subdomain for the active brand
    const itemBrand = getBrandFromId(this.props.brands, this.props.brandId)
    if (!this.props.allBrandPage && itemBrand) {
      const hash = hasWindow ? window.location.hash : ''
      const itemIsOnWrongBrand = itemBrand.id !== brand.id

      if (itemIsOnWrongBrand) {
        return `${getFullURL(itemBrand)}${location.pathname}${location.search}${hash}`
      }
    }
  }

  componentDidMount() {
    if (!hasWindow) return
    if (this.props.isLoggedIn && this.props.isRedirecting) {
      analytics.track('user_logged_in', {}, ['ga'])
      this.props.endRedirect()
    }
    if (this.props.header) {
      this.props.hideMainNavigation()
    } else {
      this.props.showMainNavigation()
    }
  }

  componentDidUpdate() {
    if (window.location.hash.includes('?email-verified=true') && this.props.isLoggedIn) {
      this.props.mutate()
    }
  }

  getHeaderMeta() {
    const className = styles[`Page${this.props.brand.style_suffix}`]

    const disableScroll =
      // if scroll is disabled explicitly
      this.props.disableScroll ||
      // if item requires log in
      (this.props.requiresLogin && !this.props.isLoggedIn) ||
      // if logged out user has opened gate
      (!this.props.isLoggedIn && this.props.userOpenedGate)

    return (
      <HeaderMeta
        title={this.props.title}
        description={this.props.description}
        image={this.props.image}
        theme={this.props.theme}
        brand={this.props.brand}
        pageClassName={className}
        disableScroll={disableScroll}
      />
    )
  }

  render() {
    const redirectUrl = this.getRedirectUrl()
    if (redirectUrl) return <Redirect to={redirectUrl} />
    if (typeof window === 'undefined' && !this.props.ssr) return this.getHeaderMeta()

    const {
      brand,
      children,
      contentColumn,
      footer,
      header,
      subNavigation,
      hideFooter,
      invertColours,
      isPasswordProtected,
      enableSubscribeOverlay,
      optInGateTags,
      isLoggedIn,
      disableOptInGate,
      disableFooterSignup,
    } = this.props

    const isBoilerRoom = brand.id === brandIds.boilerroom
    const isFourThree = brand.id === brandIds.fourthree

    const requiresLogin = withDefault(this.props.requiresLogin, false)

    const contents = contentColumn ? <div className={styles.ContentColumn}>{children}</div> : children

    const shouldDisplaySubscribeOverlay = !isLoggedIn && (isBoilerRoom || isFourThree) && enableSubscribeOverlay

    return (
      <div>
        <Notification />
        {this.getHeaderMeta()}
        {header}
        {shouldDisplaySubscribeOverlay && <SubscribeOverlay isFourThree={isFourThree} />}
        {subNavigation}
        <ErrorBoundary>{isPasswordProtected ? <Blocker>{contents}</Blocker> : contents}</ErrorBoundary>
        {footer}
        {!hideFooter && <Footer invertColours={invertColours} disableSignup={disableFooterSignup} />}
        <Gate
          requiresLogin={requiresLogin}
          optInGateTags={optInGateTags}
          disableOptInGate={disableOptInGate}
        />
      </div>
    )
  }
}
