// @flow
import React from 'react'
import {useQuery} from '@apollo/client'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'

import GET_FAVOURITES from './Query'

import type {State} from '../../stateType'
import {itemizeCollection} from '../../common/itemize'
import {genericItemTypes, uiLocations} from '../../common/constants'

import AccountNavigation from '../AccountNavigation'
import Card from '../Card'
import Grid, {GridItem} from '../Grid'
import Loading from '../Loading'
import MessageWithCTA from '../MessageWithCTA'
import Page from '../Page'

export default () => {
  const {isLoggedIn, loading} = useSelector((state: State) => state.auth)

  if (!isLoggedIn && !loading) return <Redirect to={'/login'} />

  const {data, loading: queryLoading} = useQuery(GET_FAVOURITES)

  const favourites = data && data.profile && data.profile.favourite_recordings
  const isLoading = loading || queryLoading

  return (
    <Page contentColumn={!isLoading} title='Favourites'>
      <AccountNavigation />
      {isLoading ?
        <Loading /> :
        <Grid justifyContent={'flex-start'}>
          {favourites && favourites.length ?
            itemizeCollection(favourites, genericItemTypes.recording).map(recording => (
              <GridItem
                key={recording.id}
                responsiveWidths={{small: 12, medium: 6, large: 4, extraLarge: 4}}
                gutter={true}
              >
                <Card item={recording} uiLocation={uiLocations.favouritesPageCard} />
              </GridItem>
            )) :
            <GridItem width={12}>
              <MessageWithCTA
                message={'You do not have any favourites'}
                buttonText={'BROWSE VIDEOS'}
                internalLink={'/video'}
              />
            </GridItem>
          }
        </Grid>
      }
    </Page>
  )
}
