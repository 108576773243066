// @flow
import React from 'react'
import {useSelector} from 'react-redux'
import striptags from 'striptags'
import LazyLoad from 'react-lazyload'
import moment from 'moment'

import type {BrandType, GenericItemType, ShowType, RecordingType} from '../../common/types'
import {genericItemTypes} from '../../common/constants'
import {brandIds, upcomingRecordingsImage} from '../../common/brands'
import imageUrl from '../../common/imageUrl'
import paths from '../../common/paths'
import isMobile from 'is-mobile'
import {recordingOnlyHasAudio} from '../../common/audioVideo'
import shouldDisplayChartPosition from '../../common/shouldDisplayChartPosition'
import {createImageAltText} from '../../common/createImageAltText'

import artistHoldingIcon from '../Artist/artistHoldingIcon.png'
import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Link from '../Link'
import ItemInfo from '../ItemInfo'
import BroadcastGenres from '../BroadcastGenres'
import FourThreeInfo from '../FourThreeInfo'
import AudioAvailableLink from '../AudioAvailableLink'
import FavouriteToggle from '../FavouriteToggle'
import SignupIcon from '../Svgs/SignupIcon'
import TicketIcon from '../Svgs/TicketIcon'
import RSVPIcon from '../Svgs/RSVPIcon'
import RecordingStatus from '../RecordingStatus'

import styles from './Card.less'
import Bolt from '../Svgs/Bolt'
import IconWithText from '../IconWithText'

const cardImageRatio = 0.5

type CardProps = {
  externalLink?: ?string,
  item?: GenericItemType,
  queue?: ?Array<RecordingType>,
  forceAudio?: boolean,
  horizontal?: Boolean,
  disableChartPosition?: Boolean,
  uiLocation?: string,
}

export default ({
  item,
  externalLink,
  forceAudio,
  horizontal,
  disableChartPosition,
  queue,
  uiLocation,
}: CardProps) => {
  if (!item) return <LoadingCard />

  const brand: BrandType = useSelector(state => state.brand)

  const CardLink = ({children}) => (
    <Link item={item} externalLink={externalLink} forceAudio={forceAudio} uiLocation={uiLocation}>
      {children}
    </Link>
  )
  const isShow = item.type === genericItemTypes.show
  const isFourThree = brand.id === brandIds.fourthree
  const isFourThreeItem = item.brand && item.brand.id === brandIds.fourthree
  const isProduct = item.type === genericItemTypes.product
  const isRecording = item.type === genericItemTypes.recording
  const isArtist = item.type === genericItemTypes.artist
  const isRecordingWithAudioOnly = item.recording && recordingOnlyHasAudio(item.recording)
  const isAudioRecording = isRecordingWithAudioOnly || forceAudio

  const isHorizontalItemType = isRecording || isShow
  const horizontalMode = horizontal && isHorizontalItemType
  const horizontalModeMobile = isMobile() && horizontalMode
  const classNameKey = horizontalMode ? `HorizontalModeCard_${item.type}` : 'Card'

  const defaultImage = upcomingRecordingsImage
  const showFlyerImage = (isShow && item.show && item.show.flyer_image)
  const artistImage = item.artist && (item.artist.image || artistHoldingIcon)

  const image =
    showFlyerImage || item.thumbnail_image || item.background_image || artistImage || defaultImage
  const isSquare = isShow || isProduct || isFourThree || isAudioRecording
  const isRound = isArtist

  return (
    <BrandedStyleWrapper classNameKey={classNameKey} styles={styles} props={{item}}>
      <div className={horizontalMode && styles.HorizontalBrandedComponentWrapper_recording}>
        <BrandedComponent
          brandContents={(isFourThree, isLowHeat) => [
            <Image
              key='CardImage'
              image={image}
              isSquare={isSquare}
              isRound={isRound}
              isShow={isShow}
              item={item}
              queue={queue}
              externalLink={externalLink}
              forceAudio={forceAudio}
              CardLink={CardLink}
              isProduct={isProduct}
              isRecording={isRecording}
              horizontalMode={horizontalMode}
            />,
            <Info
              key='ItemInfo'
              brand={brand}
              isFourThree={isFourThree}
              isFourThreeItem={isFourThreeItem}
              isLowHeat={isLowHeat}
              item={item}
              isRecording={isRecording}
              isShow={isShow}
              isArtist={isArtist}
              CardLink={CardLink}
              horizontalMode={horizontalMode}
              horizontalModeMobile={horizontalModeMobile}
              disableChartPosition={disableChartPosition}
              isAudioRecording={isAudioRecording}
            />,
          ]}
        />
      </div>
    </BrandedStyleWrapper>
  )
}

const Image = ({
  image,
  isSquare,
  isRound,
  isShow,
  item,
  forceAudio,
  externalLink,
  CardLink,
  isProduct,
  isRecording,
  horizontalMode,
  queue,
}) => {
  const landscape = imageUrl(image, 400, 400 * cardImageRatio)
  const square = imageUrl(image, 400, 400)

  const horizontalModeImageType = isSquare ? 'square' : 'rectangle'
  const mainImageClassName = horizontalMode ? `HorizontalModeMainImage_${horizontalModeImageType}` : 'MainImage'

  const wrapperClassName =
    isRound ? 'Image_round' : horizontalMode ? `HorizontalModeImage_${horizontalModeImageType}` : isSquare ? 'Image_square' : 'Image'

  const src = isProduct ? image : (isSquare || isRound) ? square : landscape

  const altText = createImageAltText(item)

  return (
    <div className={styles[wrapperClassName]}>
      <Link externalLink={externalLink} item={item} forceAudio={forceAudio}>
        <LazyLoad once>
          <img className={styles[mainImageClassName]} src={src} alt={altText} />
        </LazyLoad>
      </Link>

      {!forceAudio && isRecording && <AudioAvailableLink item={item} isMini={horizontalMode} />}
    </div>
  )
}

const Info = ({
  brand,
  isFourThree,
  isFourThreeItem,
  isLowHeat,
  isArtist,
  item,
  isRecording,
  isShow,
  CardLink,
  horizontalMode,
  horizontalModeMobile,
  disableChartPosition,
  isAudioRecording,
}) => {
  if (!item) return null
  const isBrandedContent = item.brand && item.brand.id !== brand.id
  const itemBrand = item.brand

  if (isArtist && item.artist) {
    return (
      <CardLink>
        <div className={styles.ArtistInfoWrapper}>
          <div className={styles.ArtistInfoTitle}>Artist</div>
          <div className={styles.ArtistInfoName}>{item.artist.name}</div>
        </div>
      </CardLink>
    )
  }

  if (isFourThree) {
    return (
      <div>
        <SeriesLink item={item} />

        <CardLink>
          <FourThreeInfo
            title={item.title}
            description={striptags(item.summary || item.description)}
            className={item.show ? styles.FourThreeShowInfo : styles.FourThreeInfo}
            truncate={true}
            metaData={item.show ? [moment(item.show.start).format('D MMM YY'), item.show && item.show.city.name] : null}
          />
        </CardLink>
      </div>
    )
  }

  if (isLowHeat) {
    const artists = (item.show && item.show.artists) || []
    return (
      <CardLink className={styles.LowHeatInfo}>
        <div>{item.title}</div>

        <br />

        {artists.map(artist => (
          <div key={artist.name}>{artist.name}</div>
        ))}
      </CardLink>
    )
  }

  const displayAudioCardChartPosition = isAudioRecording && shouldDisplayChartPosition(item, disableChartPosition)

  return (
    <div className={styles.InfoContainer}>
      <div className={styles.InfoWrapper}>
        <div className={styles.Info}>
          <CardLink>
            <BrandBanner isBrandedContent={isBrandedContent} itemBrand={itemBrand} isFourThreeItem={isFourThreeItem} />

            {/* Audio Recording Card Chart Position Indicator */}
            {displayAudioCardChartPosition &&
              <IconWithText
                icon={<Bolt className={styles.BoltIcon} />}
                text={'Trending'}
                color={'#FFE704'}
              />
            }

            {/* Video Recording Card Status */}
            {isRecording && !isAudioRecording &&
              <RecordingStatus recording={item.recording} />
            }

            <div className={styles.TitleWrapper}>
              <ItemTitle item={item} isShow={isShow} isRecording={isRecording} />
              {/* Show Card action label */}
              {item.show && !horizontalModeMobile &&
                <ShowCardActionLabel show={item.show} />
              }
            </div>

            <ItemInfo item={item} />
          </CardLink>
        </div>

        {/* Recording Card FavouriteToggle heart */}
        {isRecording && !isFourThreeItem &&
          <LazyLoad once>
            <FavouriteToggle item={item} displayIconOnly={isRecording} />
          </LazyLoad>
        }
      </div>
      <div className={horizontalMode && styles.HorizontalGenresWrapper_recording}>
        <CardLink>
          <BroadcastGenres recording={item.recording} className={horizontalMode ? styles.HorizontalGenres : ''} />
        </CardLink>
      </div>
    </div>
  )
}

const SeriesLink = ({item}) => {
  const series = item.recording && item.recording.series && item.recording.series[0]
  if (series) {
    const onClick = () => window.location.assign(paths.collection(series))

    return <span className={styles.FourThreeSeriesLabel} onClick={onClick}>
      {series.title}
    </span>
  }

  return null
}

const LoadingCard = () =>
  <BrandedComponent classNameKey='Card' styles={styles}>
    <div className={styles.LoadingImage} />
    <div className={styles.LoadingInfo}>
      <div className={styles.LoadingText} />
      <div className={styles.LoadingText} />
    </div>
  </BrandedComponent>

export const ItemTitle = ({item, isShow, isRecording}: {item: GenericItemType, isShow?: boolean, isRecording?: boolean}) => {
  const className = isShow ? styles.ShowTitle : isRecording ? styles.RecordingTitle : styles.Title

  return <b className={className}>{item.title}</b>
}

const BrandBanner = ({isFourThreeItem, isBrandedContent, itemBrand}) =>
  isBrandedContent && itemBrand ?
    <div className={isFourThreeItem ? styles.ViewOnFourThreeLabel : styles.ViewOnBrandLabel}>
      {`WATCH ON ${itemBrand.title}`}
    </div> :
    null

const ShowCardActionLabel = ({show}: {show: ShowType}) => {
  const isPastShow = moment().diff(show && show.start) >= 0
  const ctaIsEnbledWithTicketsText = show.cta_enabled && show.cta_text.includes('TICKETS')

  if (isPastShow) return null
  const fill = '#808080'

  // a show that has a signup
  if (show.signup_enabled) return <div className={styles.TicketInfoWrapper}><SignupIcon color={fill} />SIGN UP</div>
  // a show that is RSVP guestlist
  if (show.rsvp_enabled) return <div className={styles.TicketInfoWrapper}><RSVPIcon color={fill} />RSVP</div>
  // a show that has tickets via dice or external site
  if (ctaIsEnbledWithTicketsText) return <div className={styles.TicketInfoWrapper}><TicketIcon color={fill} />TICKETS</div>

  return null
}
