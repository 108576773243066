// @flow
import React from 'react'

import styles from './Blocker.less'

type Props = {
  children: any,
  password: string,
  unlocked: boolean,
  setPassword: (event: {target: any}) => void,
}

export default class Blocker extends React.Component {
  ref: ?HTMLInputElement
  props: Props

  componentDidMount() {
    if (this.ref) {
      this.ref.focus()
    }
  }

  setRef = (input: any) => {
    this.ref = input
  }

  render() {
    if (!this.props.unlocked) {
      return (
        <div className={styles.Blocker}>
          <input type='text' value={this.props.password} onChange={this.props.setPassword} ref={this.setRef} />
        </div>
      )
    }

    return this.props.children
  }
}
