// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

import {hasWindow} from '../../common/constants'

export const types = {
  updateScrollTop: 'Scrolling/scrollTop',
}

export const actions = mapValues(types, type => createAction(type))

export type StateType = {
  scrollTop: 0,
}

const initialState = {
  scrollTop: 0,
}

const getScrollTop = () => (hasWindow ? window.scrollY : 0)

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.updateScrollTop:
      return {
        scrollTop: getScrollTop(),
      }
    default:
      return state
  }
}
