import React from 'react'
import moment from 'moment'

import {hasNoYoutubePublishDateAndNoMedia} from '../../common/youtubePublicationDate'
import type {RecordingType} from '../../common/types'
import {recordingOnlyHasAudio} from '../../common/audioVideo'

import styles from './ReleaseTimeInfo.less'

export default ({className = '', recording}: {className?: string, recording: RecordingType}) => {
  if (!recording) return null

  const audioOnlyRecording = recordingOnlyHasAudio(recording)

  if (audioOnlyRecording) return null

  const isUpcomingRecording = hasNoYoutubePublishDateAndNoMedia(recording)

  const releaseTimeClassName = [className, styles.ReleaseTimeInfo].join(' ')

  if (isUpcomingRecording) return <span className={releaseTimeClassName}>Upcoming</span>

  return (
    <p className={releaseTimeClassName}>
      {moment(recording.youtube_publish_date).fromNow()}
    </p>
  )
}
