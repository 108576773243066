// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import {genericItemTypes, itemsPerPage, uiLocations} from '../../common/constants'
import {brandIds} from '../../common/brands'
import {itemizeCollection} from '../../common/itemize'
import type {BrandType, ShowType} from '../../common/types'
import type {State as StateType} from '../../stateType'

import Card from '../Card'
import Grid, {GridItem} from '../Grid'
import Loading from '../Loading'
import Page from '../Page'
import Pagination from '../Pagination'
import Query from '../Query'

import styles from './Events.less'

import {GET_43_SHOWS, GET_BR_SHOWS_PAGINATED} from './Query'

type Props = {
  match: {
    params: {
      slug: string,
      page: ?number,
    },
  },
}

export default (props: Props) => {
  const {id}: BrandType = useSelector((state: StateType) => state.brand)
  const isBoilerRoom = id === brandIds.boilerroom

  if (isBoilerRoom) {
    return (
      <Query
        Component={BoilerRoomEvents}
        query={GET_BR_SHOWS_PAGINATED}
        props={props}
        variables={{
          limit: itemsPerPage,
          offset: props.match.params.page ? (props.match.params.page - 1) * itemsPerPage : 0,
        }}
        fetchPolicy='cache-first'
      />
    )
  }

  return <Query Component={FourThreeEvents} query={GET_43_SHOWS} />
}

type BoilerRoomEventsProps = {
  pastShows: {
    shows: Array<ShowType>,
    shows_count: ?number,
  },
  isBoilerRoom: boolean,
  loading: boolean,
}

const BoilerRoomEvents = ({pastShows, loading, ...props}: BoilerRoomEventsProps) => {
  if (loading) return <Loading />

  const shows = pastShows && pastShows.shows

  return (
    <Page title='Events' brandId={brandIds.boilerroom} contentColumn={true} ssr={true}>
      {shows && <GridOfShows shows={shows} title='Past Events' large={false} />}
      {shows && <Pagination itemCount={pastShows.shows_count} type='events' page={props.match.params.page} />}
    </Page>
  )
}

type FourThreeEventsProps = {
  upcoming: {
    shows: Array<ShowType>,
  },
  archive: {
    shows: Array<ShowType>,
  },
  loading: boolean,
}

const FourThreeEvents = ({upcoming, archive, loading}: FourThreeEventsProps) => {
  if (loading || (!archive && !upcoming)) return <Loading hideHeader={true} />

  const hasUpcomingEvents = upcoming && upcoming.shows && upcoming.shows.length > 0

  return (
    <Page title='Events' brandId={brandIds.fourthree} contentColumn={true}>
      {hasUpcomingEvents && <GridOfShows shows={upcoming.shows} title='Upcoming Events' large={true} />}
      <GridOfShows shows={archive.shows} title='Past Events' large={false} />
    </Page>
  )
}

const GridOfShows = ({shows, title, large}) => (
  <Grid>
    <GridItem width={12}>
      <h3 className={styles.Title}>{title}</h3>
    </GridItem>
    {itemizeCollection(shows, genericItemTypes.show).map(showItem => (
      <GridItem
        key={`show-${showItem.id}`}
        responsiveWidths={{small: 12, medium: 6, large: large ? 6 : 4, extraLarge: large ? 6 : 4}}
      >
        <Card item={showItem} uiLocation={uiLocations.eventsPageCard} />
      </GridItem>
    ))}
  </Grid>
)
