// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

export const types = {
  setPassword: 'Blocker/setPassword',
}

export const actions = mapValues(types, type => createAction(type))

export type StateType = {
  password: string,
  unlocked: boolean,
}

const initialState: StateType = {
  password: '',
  unlocked: false,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.setPassword:
      const password = action.payload.target.value.length < 7 ? action.payload.target.value : ''

      return {
        password,
        unlocked: password === '420666',
      }
    default:
      return state
  }
}
