// @flow
import React from 'react'
import isMobile from 'is-mobile'
import Slick from 'react-slick'

import type {BrandType, PageSize, GenericItemType, HomepageThemeType} from '../../common/types'
import {pageSizes, genericItemTypes, hasWindow, playlistDisplayOptions, uiLocations} from '../../common/constants'
import {brandIds} from '../../common/brands'
import {recordingHasAudio, recordingOnlyHasAudio} from '../../common/audioVideo'
import {playlistRecordingsArePlayable} from '../../common/youtubePublicationDate'

import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Control from '../Control'
import FourThreeInfo from '../FourThreeInfo'
import Card from '../Card'
import Link from '../Link'
import Logo from '../Logo'
import Chevron from '../Svgs/Chevron'
import Tile from '../Tile'
import {ShopProductCard} from '../Shop/Component'

import styles from './Slider.less'
import {analytics} from '../../common/analytics'
import {makeQueueFromItems} from '../../common/makeQueue'

type Props = {
  brand: BrandType,
  externalLink: ?string,
  internalLink: ?string,
  pageSize: PageSize,
  title: string,
  description: string,
  activeRecordingID?: string,
  items: Array<GenericItemType>,
  removeHeader?: boolean,
  removeEmptySpace?: boolean,
  forceAudio: boolean,
  cardsPerRowOverride?: number,
  isVideoOnly?: Boolean,
  logoImage?: ?string,
  theme: ?HomepageThemeType,
  gutters: ?Boolean,
  homepageDisplayMode: ?string,
  enableDescription?: boolean,
  enableProductCard?: boolean,
}

export default class SliderComp extends React.Component {
  state: {
    index: number,
  }

  props: Props

  ref: {
    offsetWidth: number,
  }

  slider: Object

  constructor(props: Props) {
    super(props)
    this.state = {
      index: 0,
    }
  }

  cardsPerRow = () => this.props.cardsPerRowOverride || getCardsPerRow(this.props.pageSize, this.props.brand)

  next = () => {
    this.triggerTrackEvent()
    if (this.state.index >= this.slider.props.children.length - this.cardsPerRow()) {
      this.slider.slickGoTo(0)
    } else {
      this.slider.slickNext()
    }
  }

  previous = () => {
    this.triggerTrackEvent()
    if (this.state.index === 0) {
      this.slider.slickGoTo(this.slider.props.children.length - this.cardsPerRow())
    } else {
      this.slider.slickPrev()
    }
  }

  triggerTrackEvent = () => {
    analytics.track('slide', {title: this.props.title}, ['ga'])
  }

  onSlickChange = (index: number) => this.setState({index})

  setSlider = (c: Object) => {
    this.slider = c
  }

  render() {
    const {
      activeRecordingID,
      description,
      externalLink,
      internalLink,
      pageSize,
      title,
      brand,
      removeHeader,
      removeEmptySpace,
      forceAudio,
      isVideoOnly,
      logoImage,
      theme,
      enableDescription,
      enableProductCard,
    } = this.props

    if (!hasWindow) return null

    const items = filterItems(activeRecordingID, brand, this.props.items, forceAudio, isVideoOnly)

    if (items.length === 0) return null

    const cardsPerRow = removeEmptySpace && items.length < this.cardsPerRow() ? items.length : this.cardsPerRow()
    const shouldShowControls = items.length > cardsPerRow
    const hasPlayableRecordings = playlistRecordingsArePlayable(items)

    // The queue for a card in a slider contains all of the items. Once
    // a user reaches the end of the slider's items in the queue they loop
    // back around to the items at the start.
    const getQueueForItem = item => makeQueueFromItems(item, items)

    const themeStyles = theme && {
      backgroundColor: theme.background_color,
      color: theme.body_text_color,
    }

    const shouldDisplayTileSlider = this.props.homepageDisplayMode === playlistDisplayOptions.tileSlider
    const shouldDisplayHeader = !removeHeader && !shouldDisplayTileSlider

    const displayCardOrTiles = item => {
      if (shouldDisplayTileSlider) return <Tile item={item} uiLocation={uiLocations.sliderTile} forceAudio={forceAudio} />

      if (enableProductCard) return <ShopProductCard product={item.product} />

      return (
        <Card
          externalLink={externalLink}
          item={item}
          forceAudio={forceAudio}
          queue={getQueueForItem(item)}
          uiLocation={uiLocations.sliderCard}
        />
      )
    }

    return (
      <BrandedStyleWrapper styles={styles} classNameKey='Wrapper' style={themeStyles}>
        {shouldDisplayHeader &&
          <Header
            externalLink={externalLink}
            internalLink={internalLink}
            title={title}
            description={description}
            hasPlayableRecordings={hasPlayableRecordings}
            items={items}
            location={location}
            forceAudio={forceAudio}
            logoImage={logoImage}
            enableDescription={enableDescription}
          />
        }

        <div className={isMobile() ? styles.CardWrapper : styles.HoverCardWrapper}>
          {pageSize === pageSizes.small ?
            <div className={styles.ScrollList}>
              <div className={styles.ScrollListContent}>
                {items.map((item, index) => (
                  <div className={shouldDisplayTileSlider ? styles.ScrollListTile : styles.ScrollListItem} key={item.id || `LoadingCard ${index}`}>
                    {displayCardOrTiles(item)}
                  </div>
                ))}
              </div>
            </div> :
            <div>
              <Slick
                ref={this.setSlider}
                afterChange={this.onSlickChange}
                dots={false}
                arrows={false}
                infinite={false}
                speed={500}
                slidesToShow={cardsPerRow}
                slidesToScroll={cardsPerRow}
              >
                {items.map((item, index) => (
                  <div className={styles.Card} key={item.id || `LoadingCard ${index}`}>
                    {displayCardOrTiles(item)}
                  </div>
                ))}
              </Slick>

              {shouldShowControls &&
                <div className={styles.LeftArrowContainer}>
                  <Control type='left' onClick={this.previous} className={styles.Arrow} />
                </div>
              }

              {shouldShowControls &&
                <div className={styles.RightArrowContainer}>
                  <Control type='right' onClick={this.next} className={styles.Arrow} />
                </div>
              }
            </div>
          }
        </div>
      </BrandedStyleWrapper>
    )
  }
}

const Header = ({
  externalLink,
  internalLink,
  title,
  description,
  hasPlayableRecordings,
  items,
  forceAudio,
  logoImage,
  enableDescription,
}) => (
  <div className={styles.Header}>
    <BrandedComponent
      brandContents={(isFourThree, isLowHeat) =>
        isFourThree ?
          <Link externalLink={externalLink} internalLink={internalLink} uiLocation={uiLocations.sliderHeader}>
            <FourThreeInfo title={title} description={description} className={styles.Info} />
          </Link> :
          <Link externalLink={externalLink} internalLink={internalLink} className={styles.HeaderContent} uiLocation={uiLocations.sliderHeader}>
            <div>
              {logoImage ?
                <Logo src={logoImage} /> :
                <h3 className={styles.HeaderLink}>{title}</h3>
              }
              {enableDescription && <p>{description}</p>}
            </div>
            <Chevron />
          </Link>
      }
    />
  </div>
)

export const filterItems = (
  activeRecordingID?: string,
  brand: BrandType,
  items: Array<GenericItemType>,
  forceAudio: ?boolean,
  isVideoOnly: ?Boolean,
) => {
  const filteredItems = []

  for (const item of items) {
    const isRecording = item.type === genericItemTypes.recording
    const isNotActiveRecording = isRecording ? item.id !== activeRecordingID : true

    // filtering out audio only recordings by returning early
    if (isVideoOnly && isRecording && item.recording && recordingOnlyHasAudio(item.recording)) continue

    const isCorrectBrand =
      (item.brand && item.brand.id === brand.id) || brand.id === brandIds.boilerroom || brand.id === brandIds.festival

    const matchesForcedAudioFilter =
      isRecording && item.recording && forceAudio
        ? recordingHasAudio(item.recording)
        : true

    isNotActiveRecording && isCorrectBrand && matchesForcedAudioFilter && filteredItems.push(item)
  }

  return filteredItems
}

const getCardsPerRow = (pageSize: PageSize, brand: BrandType) => {
  const isFourThree = brand.id === brandIds.fourthree
  switch (pageSize) {
    case pageSizes.extraLarge:
    case pageSizes.large:
      return isFourThree ? 3 : 4
    case pageSizes.medium:
      return 2
    default:
      return 1
  }
}
