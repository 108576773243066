// @flow
import React from 'react'
import Page from '../Page'
import Grid, {GridItem} from '../Grid'
import Button from '../Button'
import styles from './Umbro.less'
import ShopNav from '../ShopNav'
import Share from '../Share'
import Link from '../Link'

const Umbro = () => {
  return (
    <Page
      title={'Boiler Room x Umbro'}
      subNavigation={<ShopNav activeSlug={'/umbro'} />}
    >
      <div className={styles.PageWrapper}>
        <div className={styles.HeaderImage} />

        <Grid justifyContent='center'>
          <GridItem
            responsiveWidths={{
              small: 12,
              medium: 12,
              large: 10,
              extraLarge: 6,
            }}
          >
            <div className={styles.TextWrapper}>
              <h1>BOILER ROOM X<br />UMBRO</h1>

              <h4 className={styles.Subheader}>We've teamed up with Umbro to celebrate the power of the pitch and
                dancefloor in uniting communities
                around the world with a limited apparel collection.</h4>
              <p>
                The Boiler Room and Umbro collection offers the ultimate kit to wear from dawn until dusk, football to
                festival field. Inspired by the early days of rave when football, fashion and music collided, the
                collection reflects the freedom, colour and vibrance of the scene and its innate ability to unite people
                from all walks of life, alike the beautiful game.
              </p>

              <p>
                The campaign has been captured by legendary photographer Ewen Spencer best known for documenting UK club
                culture and hedonistic adolescents throughout the ages.
              </p>

              <p>
                Boiler Room x Umbro is available <strong>Friday 30th June 2023 from 9am UTC</strong>, available
                exclusively from
                boilerroom.tv
              </p>

              <Link internalLink={'/shop/umbro'}>
                <Button
                  text={'SHOP NOW'}
                  className={styles.ReminderButton}
                  style={{marginBottom: 32}}
                />
              </Link>
            </div>

            <div className={styles.ImageWrapper}>
              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=750,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_08.jpg'}
                className={styles.Image}
              />
            </div>

            <div className={styles.HorizontalWrapper}>
              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=500,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_10.jpg'}
                className={styles.Image}
              />

              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=500,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_06.jpg'}
                className={styles.Image}
              />
            </div>

            <div className={styles.HorizontalWrapper}>
              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=500,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_03.jpg'}
                className={styles.Image}
              />

              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=500,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_05.jpg'}
                className={styles.Image}
              />
            </div>

            <img
              src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=750,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/LEAD+IMAGE.jpg'}
              className={styles.Image}
              style={{width: '100%', marginBottom: 16}}
            />

            <img
              src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=750,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_02.jpg'}
              className={styles.Image}
              style={{width: '100%', marginBottom: 16}}
            />

            <div className={styles.ImageWrapper}>
              <img
                src={'https://boilerroom.tv/cdn-cgi/image/width=1250,height=750,format=auto/https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/ES_UMBROXBR_07.jpg'}
                className={styles.Image}
              />
            </div>

            <div className={styles.TextWrapper}>
              <Link internalLink={'/shop/umbro'}>
                <Button
                  text={'SHOP NOW'}
                  className={styles.ReminderButton}
                  style={{marginBottom: 32}}
                />
              </Link>

              <br />

              <Share />

              <br />

              <p>
                Credits:<br /><br />
                Photography - Ewen Spencer<br />
                Producer - Billy Stocker<br />
                Photo Assistant 1 - Okus Milsom<br />
                Photo Assistant 2 - Tom Porter<br />
                Styling - Jake Hunte<br />
                MUA - Mari Kuno<br />
                Hair - Blake Henderson <br />
                Hair Assistant - Ryan Humpage <br />
                Production - Village
              </p>
            </div>
          </GridItem>
        </Grid>
      </div>
    </Page>
  )
}

export default Umbro
