import moment from 'moment'

export const required = value => (value ? undefined : 'Required')

export const validPassword = password =>
  password.length > 5 ? undefined : 'Password must be at least 6 characters long'

export const passwordsMatch = (password, passwordRepeat) =>
  password === passwordRepeat ? undefined : 'Passwords do not match'

export const validEmail = value =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)

const daysInMonth = (m, y) => {
  switch (m) {
    case 2:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28
    case 4:
    case 6:
    case 9:
    case 11:
      return 30
    default:
      return 31
  }
}

const isValidDate = (d, m, y) => {
  m = parseInt(m, 10)
  return m > 0 && m < 13 && d > 0 && d <= daysInMonth(m, y)
}

export const validAge = (dateOfBirth, minimumAge, value) => {
  if (Object.keys(dateOfBirth).length > 2) {
    const {birthDay, birthMonth} = dateOfBirth
    const minimumBirthDate = moment().subtract(minimumAge, 'years')
    const birthDate = moment(`${value}-${birthMonth}-${birthDay}`, 'YYYY-MM-DD')
    return birthDate.isBefore(minimumBirthDate)
  } else {
    return true
  }
}

export const validDay = (dateOfBirth, value) => {
  if (value > 31) return false
  if (Object.keys(dateOfBirth).length > 2) {
    const {birthMonth, birthYear} = dateOfBirth
    return isValidDate(value, birthMonth, birthYear)
  } else {
    return true
  }
}

export const validMonth = (dateOfBirth, value) => {
  if (value > 12) return false
  if (Object.keys(dateOfBirth).length > 2) {
    const {birthDay, birthYear} = dateOfBirth
    return isValidDate(birthDay, value, birthYear)
  } else {
    return true
  }
}

export const validYear = value => (value <= moment().year() && value > 1900 ? undefined : 'Invalid Date')
