// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

export const types = {
  openRsvp: 'Show/OpenRsvp',
  closeRsvp: 'Show/CloseRsvp',
}
export const actions = mapValues(types, type => createAction(type))

export type StateType = {
  rsvpOpen: boolean,
}

const initialState: StateType = {
  rsvpOpen: false,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.openRsvp:
      return {
        rsvpOpen: true,
      }

    case types.closeRsvp:
      return {
        rsvpOpen: false,
      }

    default:
      return state
  }
}
