// @flow
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import copy from 'clipboard-copy'

import LinkSvg from '../Svgs/Link'
import {brandIds} from '../../common/brands'
import type {BrandType} from '../../common/types'

import styles from './Share.less'

import {actions} from '../Notifications/state'
import {analytics} from '../../common/analytics'

const Share = ({className = '', dark}: {className?: string, dark?: boolean}) => {
  const dispatch = useDispatch()
  const brand: BrandType = useSelector(state => state.brand)
  const isFourThree = brand.id === brandIds.fourthree
  const copyToClipboard = () => {
    dispatch(actions.setSuccess('Link copied to clipboard.'))
    copy(window.location.href + '?utm_source=website&utm_medium=copy-link&utm_campaign=copy-link')
    analytics.track('share_copy-link')
  }

  const darkMode = dark || isFourThree

  return (
    <button
      className={[className, darkMode ? styles.SharePillDark : styles.SharePill].join(' ')}
      onClick={copyToClipboard}
    >
      <LinkSvg dark={darkMode} />
      Share Link
    </button>
  )
}

export default Share
