import React from 'react'

import BrandedStyleWrapper from '../BrandedStyleWrapper'
import OptIn from '../OptIn'

import styles from './Preferences.less'

export default () => (
  <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
    <div className={styles.Container}>
      <h4>MAILING PREFERENCES</h4>
      <OptIn />
    </div>
  </BrandedStyleWrapper>
)
