// @flow
import React from 'react'

import {useDispatch, useSelector} from 'react-redux'

import type {DigitalProductType} from '../../common/types'
import {parsePrice} from '../../common/shop'
import {genericItemTypes, signupSources, uiLocations} from '../../common/constants'

import {actions as gateActions} from '../Gate/state'

import tick from '../../images/tick.svg'
import ticket from '../../images/ticket.svg'
import styles from './ContentPurchaseCTA.less'

type Props = {
  digitalProduct: DigitalProductType,
  openCheckout: Function,
}

export default (props: Props) => {
  if (!props.digitalProduct) return null

  const dispatch = useDispatch()
  const openGate = () =>
    dispatch(gateActions.openGate(signupSources.digitalProduct, uiLocations.digitalProductCTA, props.digitalProduct.id, genericItemTypes.digitalProduct))
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

  const priceInPounds = parsePrice(props.digitalProduct.price / 100)

  if (props.digitalProduct.has_purchased) {
    return (
      <div className={styles.Wrapper}>
        <div className={styles.Icon}>
          <img src={tick} />
        </div>
        <div className={styles.Content}>
          <div className={styles.SubHeading}>Purchased</div>
          <div className={styles.BodyText}>
            You've bought <b>{props.digitalProduct.title}</b>.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.Wrapper} onClick={isLoggedIn ? props.openCheckout : openGate}>
      <div className={styles.Icon}>
        <img src={ticket} />
      </div>
      <div className={styles.Content}>
        <div className={styles.Headline}>Watch for {priceInPounds}</div>
        <div className={styles.BodyText}>
          Purchase <b>{props.digitalProduct.title}</b>.
        </div>
      </div>
    </div>
  )
}
