// @flow
import React from 'react'
import {gql} from '@apollo/client'
import {Query} from '@apollo/client/react/components'

import type {State} from '../../stateType'

import connect from '../../common/safeConnect'
import {ProfileFragment} from '../../common/fragments'

import {actions} from '../Gate/state'

import Component from './Component'

const mapStateToProps = ({auth, brand, gate}: State) => ({
  isLoggedIn: auth.isLoggedIn,
  loading: auth.loading,
  brand,
  userOpenedGate: gate.userOpenedGate,
  itemId: gate.itemId,
  itemType: gate.itemType,
})

const mapDispatchToProps = {
  closeGate: actions.closeGate,
}

const GET_CONSENT = gql`
  query GetConsent {
    profile {
      ...FullProfile
    }
  }

  ${ProfileFragment}
`

const Gate = props => (
  <Query query={GET_CONSENT}>
    {({data, loading, refetch}) => (
      <Component
        {...props}
        data={data}
        loading={props.loading || loading} // profile query
        refetch={refetch}
      />
    )}
  </Query>
)

export default connect(mapStateToProps, mapDispatchToProps)(Gate)
