// @flow
import React from 'react'

import GenreButton from '../GenreButton'
import BrandedComponent from '../BrandedComponent'
import Query from '../Query'

import GET_GENRES from './Query'
import styles from './Genres.less'

export default () => <BrandedComponent brandContents={isFourThree => !isFourThree && <GenresQuery />} />

const GenresQuery = () => <Query query={GET_GENRES} Component={Genres} />

const Genres = ({genres, loading}) => {
  if (loading || !genres) return null

  return (
    <div className={styles.Genres}>
      <h4>Browse by Genre</h4>
      {[...genres].sort(sortAlphabetically).map(genre => (
        <GenreButton genre={genre.title} slug={genre.slug} key={genre.slug} />
      ))}
    </div>
  )
}

const sortAlphabetically = (a, b) => {
  if (a.title < b.title) return -1
  if (a.title > b.title) return 1
  return 0
}
