import React, {useState, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useQuery} from '@apollo/client'
import {captureException} from '@sentry/browser'

import type {RecordingType} from '../../common/types'
import {genericItemTypes} from '../../common/constants'
import {actions as playerActions} from '../PersistentPlayer/state'
import imageUrl from '../../common/imageUrl'

import Button from '../Button'
import FavouriteToggle from '../FavouriteToggle'
import Link from '../Link'

import styles from './SoundcloudPlayer.less'

import GET_AUDIO_RECORDING_BY_SLUG from './Query'

export default ({id, isAudioPlaying, queue}: {id: number, isAudioPlaying: boolean, queue: Array<RecordingType>}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [retries, setRetries] = useState(0)
  const [errorText, setErrorText] = useState('')
  const dispatch = useDispatch()
  const player = useRef()

  if (!id) return null

  // query for the required recording fields on load of the audio player to prevent hitting the soundcloud API
  // with too many requests due to the soundcloud_stream_url, the tracks/:id/stream endpoint has a daily 15k rate limit
  const {data, loading} = useQuery(GET_AUDIO_RECORDING_BY_SLUG, {
    variables: {
      id,
    },
  })

  const recordingLoaded = data && data.recording && data.recording.slug

  const maxRetries = 1

  const reloadAudio = () => {
    if (retries >= maxRetries) {
      console.error('Audio source reloads exceeded.')
      setRetries(0)
      dispatch(playerActions.toggleAudioPlaying())
      return
    }
    if (player.current) {
      // reload audio and retain current playback position
      setRetries(retries + 1)
      player.current.load()
      player.current.currentTime = currentTime
    }
  }

  // error handling - no dependancy array to call after each render
  useEffect(() => {
    const handleErrorMessage = (errorMessage, userMessage) => {
      if (userMessage) setErrorText(userMessage)
      if (errorMessage) {
        const message = `audio player error occured: ${errorMessage}`
        console.error(message)
        captureException(message)
      }
    }

    // https://html.spec.whatwg.org/multipage/media.html#error-codes
    const handleErrors = event => {
      if (event.target.error) {
        const {code, message} = event.target.error
        switch (code) {
          case 1:
            handleErrorMessage(message) // MEDIA_ERR_ABORTED
            break
          case 2:
            handleErrorMessage(message, 'Network error') // MEDIA_ERR_NETWORK
            reloadAudio()
            break
          case 3:
            handleErrorMessage(message, 'Media decoding error') // MEDIA_ERR_DECODE
            reloadAudio()
            break
          case 4:
            handleErrorMessage(message, 'Media not supported') // MEDIA_ERR_SRC_NOT_SUPPORTED
            reloadAudio()
        }
      }
    }
    if (player.current) {
      // listen for errors
      player.current.addEventListener('error', handleErrors)
    }

    return () => {
      // clean up the event listener when the component unmounts
      if (player.current) {
        player.current.removeEventListener('error', handleErrors)
      }
    }
  })

  useEffect(() => {
    // update the currentTime state based on the player.current timeupdate event listener
    const updateTime = () => {
      if (player.current) {
        setCurrentTime(player.current.currentTime)
      }
    }

    // only dispatch loadNext() if there is more than one recording in the queue
    // otherwise close the player for a single recording
    const handleRecordingEnded = () => {
      if (queue.length > 0) {
        dispatch(playerActions.loadNext())
      } else {
        dispatch(playerActions.toggleAudioPlaying())
      }
    }

    if (player.current) {
      // call the updateTime function whenever the timeupdate event occurs
      player.current.addEventListener('timeupdate', updateTime)

      // dispatch loadNext thunk when the audio has ended to load the next recording in the queue
      player.current.addEventListener('ended', handleRecordingEnded)
    }

    return () => {
      // clean up the event listeners when the component unmounts
      if (player.current) {
        player.current.removeEventListener('timeupdate', updateTime)
        player.current.removeEventListener('ended', handleRecordingEnded)
      }
    }
  }, [recordingLoaded])

  // reset all state when passing a new id into component
  useEffect(() => {
    setCurrentTime(0)
    setDuration(0)
    setRetries(0)
  }, [id])

  // listen to isAudioPlaying state to keep PlayerIntialiser and SoundcloudPlayer play/pause buttons in sync
  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
    const playerHasLoadedEnoughDataToPlay = player.current && player.current.readyState >= 3

    if (playerHasLoadedEnoughDataToPlay) {
      isAudioPlaying ? player.current.play() : player.current.pause()
    } else if (isAudioPlaying) {
      // enable the user to manually reload the audio on clicking the play button
      setErrorText('')
      reloadAudio()
    }
  }, [isAudioPlaying])

  // mediaSession API to set up lock screen: https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API
  useEffect(() => {
    if (player.current) {
      const mediaSession = navigator.mediaSession
      if (!mediaSession) return null

      mediaSession.metadata = new window.MediaMetadata({
        title,
        artist: 'Boiler Room',
        artwork: [
          {
            src: imageUrl(thumbnail_image, 512, 512),
          },
        ],
      })

      mediaSession.setActionHandler('play', () => dispatch(playerActions.toggleAudioPlaying()))

      mediaSession.setActionHandler('pause', () => dispatch(playerActions.toggleAudioPlaying()))

      mediaSession.setActionHandler('nexttrack', queue.length > 0 ? () => dispatch(playerActions.loadNext()) : null)

      mediaSession.setActionHandler('previoustrack', null)

      player.current.source = {
        sources: [
          {
            src: soundcloud_stream_url,
          },
        ],
      }

      return () => {
        mediaSession.setActionHandler('play', null)
        mediaSession.setActionHandler('pause', null)
        mediaSession.setActionHandler('nexttrack', null)
        mediaSession.setActionHandler('previoustrack', null)
      }
    }
  }, [recordingLoaded])

  if (loading || !data) return null

  const {slug, title, soundcloud_stream_url, thumbnail_image} = data.recording

  const handlePlayPauseButton = () => {
    setRetries(0)
    dispatch(playerActions.toggleAudioPlaying())
  }

  const formatTime = secs => {
    const roundedSeconds = Math.floor(secs)
    const minutes = Math.floor(roundedSeconds / 60)
    const seconds = Math.floor(roundedSeconds % 60)
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${formattedSeconds}`
  }

  const handleSeek = e => {
    const selectedTime = ++e.target.value
    setCurrentTime(selectedTime)
    player.current.currentTime = selectedTime
  }

  // this is used to calculate the remaining duration of the audio as a percentage
  // it is passed to the css variable --audio-progress
  // it is used to set the remaining time on the slider to dark grey
  const audioProgressPercentage = `${currentTime && duration ? (currentTime / duration) * 100 : 0}%`

  return (
    <div className={styles.SoundcloudPlayerWrapper}>
      <div className={styles.TitleAndButtonsWrapper}>
        <Link internalLink={`/audio/${slug}`} className={styles.RecordingTitle}>{title}</Link>

        <div className={styles.ControlsWrapper}>
          <FavouriteToggle
            item={{
              type: genericItemTypes.recording,
              recording: data.recording,
            }}
            displayIconOnly
          />
          <Button
            className={isAudioPlaying ? styles.PauseButton : styles.PlayButton}
            onClick={handlePlayPauseButton}
            disableDefaultStyles
          />
        </div>
      </div>

      <div className={styles.SeekSlider}>
        <input
          type='range'
          max={duration}
          value={currentTime}
          onChange={e => handleSeek(e)}
          style={{'--audio-progress': audioProgressPercentage}}
        />
        <div className={styles.TimesWrapper}>
          <span>{formatTime(currentTime)}</span>
          {errorText && <span className={styles.ErrorText}>{errorText}</span>}
          <span>{formatTime(duration)}</span>
        </div>

      </div>

      <audio
        title={title}
        src={soundcloud_stream_url}
        ref={player}
        onLoadedMetadata={e => setDuration(e.target.duration)}
        autoPlay
      />

    </div>
  )
}
