// @flow
import React from 'react'
import queryString from 'query-string'
import {useSelector} from 'react-redux'

import {genericItemTypes} from '../../common/constants'
import type {RecordingType, MatchType, LocationType, StaticContext} from '../../common/types'
import embedUrl from '../../common/embedUrl'
import paths from '../../common/paths'
import {itemizeCollection} from '../../common/itemize'
import {brandIds, getFullURL} from '../../common/brands'
import previewableQueryVariables from '../../common/previewableQueryVariables'
import {isAudioMode, recordingOnlyHasAudio} from '../../common/audioVideo'

import GET_RECORDING from './Query'

import {LowHeatPageOverlay} from '../LowHeatIcon'
import Background from '../Background'
import BrandedComponent from '../BrandedComponent'
import Loading from '../Loading'
import NotFound from '../NotFound'
import Page from '../Page'
import Query from '../Query'
import ShopSlider from '../ShopSlider'
import Slider from '../Slider'
import Tracklist from '../Tracklist'
import RecordingPlayer from '../RecordingPlayer'
import Artists from '../Artists'
import UpNextButton from '../UpNextButton'

import styles from './Recording.less'
import RecordingInfo from '../RecordingInfo'

type Props = {
  location: LocationType,
  match: MatchType,
  staticContext: StaticContext,
}

type RecordingProps = {
  recording: ?RecordingType,
  loading: boolean,
  refetch: Function,
  location: LocationType,
  isLoggedIn: boolean,
  host: string,
}

export default (props: Props) => {
  const isLoggedIn: boolean = useSelector(state => state.auth.isLoggedIn)
  const host: string = useSelector(state => state.loadLocation.host)

  return (
    <Query
      Component={Recording}
      props={{location: props.location, isLoggedIn, host}}
      query={GET_RECORDING}
      variables={previewableQueryVariables(props)}
    />
  )
}

export class Recording extends React.Component {
  props: RecordingProps

  componentDidMount = () => this.checkIfShouldRefetch()

  componentDidUpdate = (prevProps: RecordingProps) => {
    // If a user has just logged in, check to see if a refetch is required
    const userHasJustLoggedIn = !prevProps.isLoggedIn && this.props.isLoggedIn
    if (userHasJustLoggedIn) this.checkIfShouldRefetch()
  }

  checkIfShouldRefetch = () => {
    // If a recording has digital products, refetch in the client
    const recordingHasDigitalProducts = this.props.recording && this.props.recording.digital_products.length > 0
    if (recordingHasDigitalProducts && this.props.isLoggedIn) {
      this.props.refetch()
    }
  }

  getRedirectUrl = () => {
    const {recording} = this.props
    if (!recording) return null

    if (recording.redirect_url) {
      return recording.redirect_url
    }

    const isEmbed = queryString.parse(this.props.location.search).embedded === 'true'
    if (isEmbed) {
      return embedUrl({
        hlsUrl: recording.hls_url,
        vimeoId: recording.vimeo_video_id,
        youtubeId: recording.youtube_video_id,
      })
    }
    // checks if recording has a soundcloud id and if it contains youtube/vimeo ids'
    const audioMode = isAudioMode(recording, this.props.location)
    const onlyHasAudio = recordingOnlyHasAudio(recording)
    const shouldUrlContainAudio = audioMode || onlyHasAudio

    // Ensure the recording is on the correct URL
    // This is used for 4:3 recordings
    const currentUrl = `//${this.props.host}${this.props.location.pathname}`
    const correctUrl = `${getFullURL(recording.brand)}${paths.recording(recording, shouldUrlContainAudio)}`
    const onWrongUrl = currentUrl.indexOf(correctUrl) !== 0
    if (onWrongUrl) return correctUrl

    return null
  }

  render() {
    const {recording, loading} = this.props

    if (!loading && !recording) return <NotFound />
    if (loading || !recording) return <Loading />

    const {
      brand,
      background_image,
      description,
      id,
      playlists,
      requires_login,
      series,
      show_background_image,
      show,
      thumbnail_image,
      title,
      sponsor,
      tracklist,
      theme,
    } = recording

    const isLowHeat = brand && brand.id === brandIds.lowheat
    const isAudioPage = this.props.location && this.props.location.pathname.indexOf('/audio/') === 0

    return (
      <Page
        title={title}
        description={description}
        image={background_image || thumbnail_image}
        requiresLogin={requires_login}
        redirectUrl={this.getRedirectUrl()}
        brandId={brand.id}
        theme={theme}
        ssr={true}
      >
        <BrandedComponent
          brandContents={isFourThree =>
            !isFourThree && background_image && show_background_image ?
              <React.Fragment>
                <Background className={styles.PlayerBackground} image={background_image} />
                <div
                  className={styles.Gradient}
                  style={{
                    backgroundImage: `linear-gradient(to top, ${theme && theme.background_color || '#000'}, rgba(0,0,0,0))`,
                  }}
                />
              </React.Fragment>
              :
              null
          }
        />
        <div className={styles.Recording}>
          <RecordingPlayer recording={recording} location={this.props.location} />

          {!isAudioPage && <UpNextButton location={this.props.location} />}

          <RecordingInfo recording={recording} location={this.props.location} />

          {recording.artists && recording.artists.length ?
            <div className={styles.RecordingArtists}>
              <Artists artists={recording.artists} />
            </div> :
            null
          }

          <Tracklist recording={recording} tracklist={tracklist} />
        </div>

        {show && show.recordings.length > 1 &&
          <Slider
            title={show.title}
            internalLink={paths.show(show)}
            items={itemizeCollection(show.recordings, genericItemTypes.recording)}
            activeRecordingID={id}
          />
        }

        {playlists &&
          playlists.map(playlist => (
            <Slider
              title={playlist.title}
              description={playlist.description}
              key={playlist.id}
              items={playlist.items}
              internalLink={paths.collection(playlist)}
              activeRecordingID={id}
              forceAudio={isAudioPage}
            />
          ))
        }

        {series[0] && series[0].recordings && series[0].recordings.length > 1 &&
          <Slider
            title={`More: ${series[0].title}`}
            description={series[0].description}
            items={itemizeCollection(series[0].recordings, genericItemTypes.recording)}
            internalLink={paths.collection(series[0])}
            activeRecordingID={id}
          />
        }

        <ShopSlider sponsor={sponsor} />

        {isLowHeat && <LowHeatPageOverlay />}
      </Page>
    )
  }
}
