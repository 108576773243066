import {brandIds} from './brands'

const paths = {
  article: article => `/article/${article.slug}`,
  show: show => `/session/${show.slug}`,
  recording: (recording, isAudioPath) => {
    if (isAudioPath) {
      return `/audio/${recording.slug}`
    }

    if (recording.brand && recording.brand.id === brandIds.fourthree) {
      return `/film/${recording.slug}`
    }

    return `/recording/${recording.slug}`
  },
  collection: collection => {
    if (collection.type === 'showcollection') {
      return `/session/${collection.slug}`
    }

    return `/${collection.type}/${collection.slug}`
  },
  playlist: playlist => `/playlist/${playlist.slug}`,
  sponsor: sponsor => sponsor.url,
  product: product => `/product/${product.slug}`,
  link: link => link.url,
  artist: artist => `/artist/${artist.slug}`,
}

export default {
  ...paths,
  item: item => paths[item.type](item),
}
