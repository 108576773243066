// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import type {BrandType} from '../../common/types'
import type {State as StateType} from '../../stateType'

type Props = {
  children: any,
  styles: any,
  classNameKey?: string,
  style?: ?any,
  component?: any,
  props?: any,
}

export default (defaultProps: Props) => {
  const brand: BrandType = useSelector((state: StateType) => state.brand)
  const {children, styles, classNameKey, style, props} = defaultProps

  const Component = defaultProps.component
  const className = (styles && classNameKey && styles[`${classNameKey}${brand.style_suffix}`]) || styles[classNameKey]
  return Component ?
    <Component className={className} style={style} {...props}>
      {children}
    </Component> :
    <div className={className} style={style} {...props}>
      {children}
    </div>
}
