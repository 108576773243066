import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import Component from './Component'
import {actions} from './state'
import {actions as authActions} from '../Auth/state'

const mapStateToProps = ({auth, brand, loadLocation, gate, blocker, brands}: State) => ({
  brand,
  brands,
  loadLocation,
  isRedirecting: auth.isRedirecting,
  isLoggedIn: auth.isLoggedIn,
  userOpenedGate: gate.userOpenedGate,
  pageUnlocked: blocker.unlocked,
})

const mapDispatchToProps = {
  ...actions,
  endRedirect: authActions.endRedirect,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
