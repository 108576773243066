// @flow
import React from 'react'

import styles from './Page.less'

type Props = {
  children: any,
}

export default class ErrorBoundary extends React.Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  props: Props

  state: {
    error: boolean,
  }

  componentDidCatch(error: any) {
    this.setState({error})
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.ErrorMessage}>
          <h2>Something went wrong.</h2>
          <p>Try reloading the page.</p>
        </div>
      )
    }
    return this.props.children
  }
}
