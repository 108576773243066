// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import Component from './Component'

import {actions} from '../Notifications/state'

const mapStateToProps = ({brand, brands, auth}: State) => ({brand, brands, user: auth.user})

const mapDispatchToProps = {
  setError: actions.setError,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
