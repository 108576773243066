export default [
  {
    size: 'S',
    width: 18,
    length: 28,
  },
  {
    size: 'M',
    width: 20,
    length: 29,
  },
  {
    size: 'L',
    width: 22,
    length: 30,
  },
  {
    size: 'XL',
    width: 24,
    length: 31,
  },
  {
    size: 'XXL',
    width: 26,
    length: 32,
  },
]
