// @flow
import React from 'react'

export default ({filled, color}: {color?: string, filled?: boolean}) => {
  const strokeColor = color || '#fff'
  const fillColor = filled ? strokeColor : 'none'

  return (
    <svg
      viewBox='0 0 40 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 1.61804L24.0148 13.9742L24.127 14.3197H24.4903H37.4823L26.9715 21.9562L26.6776 22.1697L26.7899 22.5152L30.8046 34.8713L20.2939 27.2348L20 27.0213L19.7061 27.2348L9.19535 34.8713L13.2101 22.5152L13.3224 22.1697L13.0285 21.9562L2.51771 14.3197H15.5097H15.873L15.9852 13.9742L20 1.61804Z'
        fill={fillColor}
        stroke={strokeColor}
        vectorEffect='non-scaling-stroke'
        strokeWidth='2px'
      />
    </svg>
  )
}
