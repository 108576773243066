import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useMutation, useQuery} from '@apollo/client'
import {genericItemTypes, signupSources, uiLocations} from '../../common/constants'
import {analytics} from '../../common/analytics'
import {hasNoYoutubePublishDateAndNoMedia, hasFutureYoutubePublishDate} from '../../common/youtubePublicationDate'
import captureMutationErrors from '../../common/captureMutationErrors'

import type {State} from '../../stateType'
import {actions as gateActions} from '../Gate/state'
import {actions as notificationActions} from '../Notifications/state'

import Heart from '../Svgs/Heart'
import Button from '../Button'
import Link from '../Link'
import Bell from '../Svgs/Bell'

import styles from './FavouriteToggle.less'

import IS_FAVOURITED from './Query'
import UPDATE_FAVOURITE from './Mutation'

export default ({item, displayIconOnly}) => {
  if (!item) return null

  const dispatch = useDispatch()

  const [isLocalFavourited, setIsLocalFavourited] = useState(false)

  const [updateFavourite] = useMutation(UPDATE_FAVOURITE)

  const {error, refetch} = useQuery(IS_FAVOURITED, {
    variables: {
      item_type: item.type,
      item_id: item[item.type].id,
    },
    onCompleted: data => setIsLocalFavourited(data.is_favourited),
  })

  const {isLoggedIn} = useSelector((state: State) => state.auth)

  const trackFavouriteEvent = () => {
    const data = {
      item_type: item.type,
      item_id: item[item.type].id,
      favourited: !isLocalFavourited,
    }

    if (item.type === 'city') {
      data['city'] = item.city.name
    }

    const eventName = item.type === 'city' ? 'favourite_city' : 'favourite_recording'

    analytics.track(eventName, {...data})
  }

  const isCity = item.type === genericItemTypes.city
  const isRecording = item.type === genericItemTypes.recording
  const signupSource = isRecording ? signupSources.favouriteRecording : signupSources.favouriteCity
  const favouritedColor = isLocalFavourited && '#E7271E'
  const isUpcomingRecording = isRecording && (hasNoYoutubePublishDateAndNoMedia(item.recording) || hasFutureYoutubePublishDate(item.recording))

  const handleClick = e => {
    e.preventDefault()
    analytics.track('Lead', {}, ['fb'])

    if (!isLoggedIn) return dispatch(gateActions.openGate(signupSource, uiLocations.favouriteToggle, item[item.type].id, item.type))
    try {
      setIsLocalFavourited(!isLocalFavourited)
      updateFavourite({
        variables: {
          item_type: item.type,
          item_id: item[item.type].id,
          favourited: !isLocalFavourited,
        },
      }).then(({data, errors}) => {
        // error handling - useMutation returns the error in the response
        if (errors && errors.length > 0) {
          captureMutationErrors(errors)
          setIsLocalFavourited(!isLocalFavourited)
          dispatch(notificationActions.setError('Favouriting error, please try again.'))
          return null
        }

        // refetch called to keep components in sync when multiple are on display, e.g audio player and recording page
        refetch()

        if (data.update_favourite && !isLocalFavourited) {
          if (isCity) {
            dispatch(
              notificationActions.setSuccess(
                <div>
                  You are now subscribed to {item.city.name}.{' '}
                  <Link className={styles.SuccessLink} internalLink={'/account'}>
                    View your cities.
                  </Link>
                </div>,
              ),
            )
          }

          // recording favourite success message
          if (isRecording) {
            const notificationMessage = isUpcomingRecording ? 'Reminder set, recording added to favourites.' : 'Recording added to favourites.'

            dispatch(
              notificationActions.setSuccess(
                <div>
                  {notificationMessage}
                  <Link className={styles.SuccessLink} internalLink={'/favourites'}>
                    View your favourites.
                  </Link>
                </div>,
              ),
            )
          }
        }
      })
      trackFavouriteEvent()
    } catch (err) {
      console.error(err)
      setIsLocalFavourited(!isLocalFavourited)
    }
  }

  if (error) return null

  const icon = isUpcomingRecording ?
    <Bell color={favouritedColor} /> : <Heart color={favouritedColor} />

  if (displayIconOnly) {
    return (
      <button className={styles.MiniIconButton} onClick={handleClick}>
        {icon}
      </button>
    )
  }

  if (isCity) {
    return (
      <Button
        text={
          isLocalFavourited ?
            `Subscribed to ${item.city.name}` :
            `Subscribe to ${item.city.name}`
        }
        onClick={handleClick}
        className={styles.Button}
      />
    )
  }

  const reminderText = () => {
    if (isUpcomingRecording) {
      return isLocalFavourited ? 'Reminder Set' : 'Remind Me'
    }

    return isLocalFavourited ? 'Favourited' : 'Favourite'
  }

  return (
    <button className={styles.FavouritePill} onClick={handleClick}>
      {icon}
      {reminderText()}
    </button>
  )
}
