import {gql} from '@apollo/client'
import {ShowCardFragment} from '../../common/fragments'

export default gql`
  query ShowsQuery {
    currentShows: shows(status: ["live", "live_off_camera", "upcoming", "relooping"]) {
      shows {
        ...ShowCard
      }
    }

    pastShows: shows(status: ["archived", "offair"], limit: 12, orderBy: "start", orderDirection: "desc") {
      shows {
        ...ShowCard
      }
    }
  }
  ${ShowCardFragment}
`
