// @flow
import React from 'react'
import marked from 'marked'

import type {HomepageType} from '../../common/types'

import Background from '../Background'

import styles from './HeroPanel.less'

marked.setOptions({
  renderer: new marked.Renderer(),
  breaks: true,
})

export default ({homepage}: {homepage: HomepageType}) => {
  const {theme} = homepage
  if (!theme) return null

  return (
    <div className={styles.HeroPanel}>
      <Background
        image={theme.hero_panel_header_background_image}
        video={theme.hero_panel_header_background_video}
        playing={true}
        muted={true}
      />
      <div
        className={styles.Gradient}
        style={{
          backgroundImage: `linear-gradient(to top, ${theme.background_color || '#000000'}, rgba(0,0,0,0))`,
        }}
      />
      <div dangerouslySetInnerHTML={{__html: marked(theme.hero_panel_header_content)}} className={styles.Content} />
    </div>
  )
}
