// @flow
import React from 'react'
import {Helmet} from 'react-helmet'
import striptags from 'striptags'

import type {HomepageThemeType, BrandType} from '../../common/types'

import imageUrl from '../../common/imageUrl'

const ogImageDimensions = {
  width: 1200,
  height: 630,
}

const getHeadingFontStyles = headingFont => `
  @font-face {
    font-family: HeadingFont;
    src: url('${headingFont}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: HeadingFont;
  }
`

const getHeadingColorStyles = headingColor => `
  h1, h2, h3, h4, h5, h6 {
    color: ${headingColor};
  }
`

export default class HeaderMeta extends React.Component {
  props: {
    title?: string,
    description?: string,
    image?: string,
    theme?: ?HomepageThemeType,
    brand: BrandType,
    pageClassName: string,
    disableScroll?: ?boolean,
  }

  render() {
    const title = this.props.title ? `${this.props.title} - ${this.props.brand.title}` : this.props.brand.page_title
    const description = striptags(this.props.description) || this.props.brand.description
    const image = this.props.image
      ? imageUrl(this.props.image, ogImageDimensions.width, ogImageDimensions.height)
      : this.props.brand.image
    const theme = this.props.theme
    const backgroundColor = theme && theme.background_color

    return (
      <Helmet defaultTitle={this.props.brand.title}>
        <link rel='apple-touch-icon' href={this.props.brand.apple_touch_icon} />
        <link rel='icon' href={this.props.brand.favicon} />

        <meta charSet='utf-8' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />

        <title>{title}</title>
        <meta name='description' content={description} />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={image} />
        <meta property='og:image:width' content={ogImageDimensions.width} />
        <meta property='og:image:height' content={ogImageDimensions.height} />
        <meta property='og:url' content='' />
        <meta property='og:site_name' content={this.props.brand.title} />

        <meta name='twitter:title' content={title} />
        <meta name='twitter:site' content='@boilerroomtv' />
        <meta name='twitter:creator' content='@boilerroomtv' />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content={this.props.brand.title} />

        <meta name='google-site-verification' content='6wZcVtZGCPducDFypuDYCAh1jEtU6LrInopnTbVKdVc' />

        <meta property='fb:app_id' content='413738625751345' />

        {theme && theme.heading_font_file && <style>{getHeadingFontStyles(theme.heading_font_file)}</style>}
        {theme && theme.heading_font_color && <style>{getHeadingColorStyles(theme.heading_font_color)}</style>}
        <body
          className={this.props.pageClassName}
          style={`
            background-color: ${backgroundColor || ''};
            overflow: ${this.props.disableScroll ? 'hidden' : ''};
          `}
        />
      </Helmet>
    )
  }
}
