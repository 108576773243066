// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import Link from '../Link'
import {hasWindow} from '../../common/constants'
import type {State} from '../../stateType'

type Props = {
  item: {
    name: string,
    link: string,
  },
  className: string,
  activeClassName?: string,
  isParentPath?: ?boolean,
  icon?: any,
  uiLocation: string,
}

export default (props: Props) => {
  const {link} = props.item
  const {isLoggedIn} = useSelector((state: State) => state.auth)
  const isActive =
    hasWindow && (location.href === link || location.pathname === link || isActiveParentLink(link, props.isParentPath))
  const className = (isActive && props.activeClassName) || props.className

  const name = props.item.name === 'Account' && !isLoggedIn ? 'Login or sign up' : props.item.name // if the link is 'Account' and the user is not logged in, change to Login

  // If link starts with a forward slash use internalLink
  if (/^\/[a-zA-Z0-9]/.test(link)) {
    return (
      <Link className={className} internalLink={link} uiLocation={props.uiLocation}>
        {props.icon}
        {name}
      </Link>
    )
  }

  return (
    <Link className={className} externalLink={link}>
      {name}
    </Link>
  )
}

const isActiveParentLink = (itemLink, isParentPath) => {
  if (hasWindow && isParentPath) {
    // Shop pages
    if (itemLink === '/shop' && location.pathname.includes('/shop/')) return true
    if (itemLink === '/shop' && location.pathname.includes('/product/')) return true
    if (itemLink === '/shop' && location.pathname.includes('/basket')) return true
    // Account pages
    if (itemLink === '/account' && location.pathname.includes('/login')) return true
    if (itemLink === '/account' && location.pathname.includes('/favourites')) return true
    if (itemLink === '/account' && location.pathname.includes('/invite-requests')) return true
    // Video pages
    if (itemLink === '/video' && location.pathname.match(/^\/(video|recording)\//)) return true
    if (itemLink === '/video' && (location.pathname.includes('/schedule') || location.pathname.includes('/charts'))) return true
    if (itemLink === '/schedule' && location.pathname.includes('/schedule')) return true
    // Audio pages
    if (itemLink === '/audio' && location.pathname.includes('/audio')) return true
    // Event pages
    if (itemLink === '/upcoming' && location.pathname.includes('/session/')) return true
  }

  return false
}
