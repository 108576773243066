import Cookies from 'js-cookie'

export default url => {
  // swap out Shopify URL for our custom Shopify domain
  const checkoutUrl = url.replace('boiler-room-tv.myshopify.com', 'checkout.boilerroom.tv')

  // create a query string of UTM parameters from those available
  const utmParams = ['utm_campaign', 'utm_source', 'utm_medium']
    .filter(key => Cookies.get(key))
    .map(key => `${key}=${Cookies.get(key)}`)
    .join('&')

  if (utmParams) {
    // create or append UTM parameters as query string
    const queryStringDivider = checkoutUrl.includes('?') ? '&' : '?'
    return checkoutUrl + queryStringDivider + utmParams
  }

  return checkoutUrl
}
