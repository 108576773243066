// @flow
// $FlowFixMe
import React, {useState} from 'react'
import {spring, Motion} from 'react-motion'

import Form from '../Form'
import Input from '../Input'
import Button from '../Button'
import Checkbox from '../Checkbox'
import CompleteTick from '../Svgs/CompleteTick'
import BrandedStyleWrapper from '../BrandedStyleWrapper'

import {validEmail} from '../../common/validation'

import styles from '../Login/Login.less'

type LoginProps = {
  emailLogin: (email: string, onError: () => void, uiLocation: string, itemId: ?number, itemType: ?string) => void,
  verifyCode: ({code: string, email: string}, onError: () => void, uiLocation: string, itemId: ?number, itemType: ?string) => void,
  onError: () => void,
  clearError: () => void,
  error: boolean,
  uiLocation: string,
  itemId: ?number,
  itemType: ?string,
}

const Login = (props: LoginProps) => {
  const [displayCode, setDisplayCode] = useState(false)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [getCodePressed, setGetCodePressed] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [displayEmailError, setDisplayEmailError] = useState(false)

  const updateEmail = (e: any) => {
    const email = e.target.value
    setEmail(email)
    if (validEmail(email)) {
      setDisplayEmailError(false)
    }
  }

  const updateCode = (e: any) => {
    props.clearError()
    setCode(e.target.value)
  }

  const emailLogin = (e: any) => {
    e.preventDefault()
    setGetCodePressed(true)
    if (!validEmail(email)) {
      setDisplayEmailError(true)
      return
    }
    if (!checkboxChecked) return
    props.emailLogin(email, props.onError, props.uiLocation, props.itemId, props.itemType)
    setDisplayCode(true)
  }

  const verifyCode = (e: any) => {
    if (e) e.preventDefault()
    props.verifyCode(
      {
        code,
        email,
      },
      props.onError,
      props.uiLocation,
      props.itemId,
      props.itemType,
    )
  }

  const onSubmit = displayCode ? verifyCode : emailLogin
  const buttonText = displayCode ? 'Login' : 'Get login code'

  const boxStyles = props.error ? [styles.OTPBox, styles.Error].join(' ') : styles.OTPBox

  // $FlowFixMe
  React.useEffect(() => {
    if (code.length === 4) {
      verifyCode()
    }
  }, [code])

  return (
    <BrandedStyleWrapper styles={styles} classNameKey={'Wrapper'}>
      <Form
        className={styles.Form}
        onSubmit={onSubmit}
        noValidate
      >
        <div style={{display: displayCode ? 'none' : 'block'}}>
          <Input
            id='login email'
            type='email'
            value={email}
            onChange={updateEmail}
            placeholder='email'
            correct={validEmail(email)}
            highlightRequired={getCodePressed && !validEmail(email)}
            label='Email'
            required
            autoFocus
            displayEmailError={displayEmailError}
          />
        </div>
        {displayCode &&
          <div className={styles.OTPWrapper}>
            <div className={styles.OTPHeader}>
              <EnvelopeAnimation />
              <b>CHECK YOUR INBOX</b>
              We emailed you a login code
            </div>
            <div className={styles.OTPBoxWrapper}>
              <div className={boxStyles}>
                {code.length === 0 && <div className={styles.Caret} />}
                {code[0] || ''}
              </div>
              <div className={boxStyles}>
                {code.length === 1 && <div className={styles.Caret} />}
                {code[1] || ''}
              </div>
              <div className={boxStyles}>
                {code.length === 2 && <div className={styles.Caret} />}
                {code[2] || ''}
              </div>
              <div className={boxStyles}>
                {code.length === 3 && <div className={styles.Caret} />}
                {code[3] || ''}
              </div>
            </div>
            <Input
              id='login code'
              type='tel'
              value={code}
              onChange={updateCode}
              placeholder='0000'
              className={[styles.OTPcodeInput, styles.OTPhiddenInput].join(' ')}
              autoFocus
              required
              maxLength={4}
              pattern='[0-9]{0,4}'
              autoComplete='off'
              autoCorrect='off'
            />

            <div className={styles.ResendOTPcode} onClick={emailLogin}>
              RESEND CODE
            </div>
          </div>
        }
        <div style={{display: displayCode ? 'none' : 'block'}}>
          <Checkbox
            id='website terms of use consent'
            required
            highlightRequired={getCodePressed}
            className={getCodePressed && !checkboxChecked ? styles.CheckboxWithVisualValidation : styles.Checkbox}
            checked={checkboxChecked}
            onChange={() => setCheckboxChecked(!checkboxChecked)}
            text={
              'I accept Boiler Room\'s [Terms and Conditions](https://boilerroom.tv/page/website-terms-of-use/). Your personal data will be processed in line with Boiler Room\'s [Privacy Policy](https://boilerroom.tv/page/privacy-policy/).'
            }
          />
        </div>
        <Button className={styles.Button} text={buttonText} type='submit' />
      </Form>
    </BrandedStyleWrapper>
  )
}

const EnvelopeAnimation = () => {
  // Delay checkmark visibility by 1 second
  // $FlowFixMe
  const [checkmarkVisible, setCheckmarkVisible] = React.useState(false)
  // $FlowFixMe
  React.useEffect(() => {
    const revealCheckmarkTimeout = setTimeout(() => setCheckmarkVisible(true), 1000)
    return () => clearTimeout(revealCheckmarkTimeout)
  }, [])

  // react-motion animations
  const flapLineLeftProps = {
    defaultStyle: {x1: 0, y1: 35, x2: 50, y2: 70},
    style: {x1: 0, y1: 35, x2: 50, y2: spring(0)},
  }
  const flatLineRightProps = {
    defaultStyle: {x1: 100, y1: 35, x2: 50, y2: 70},
    style: {x1: 100, y1: 35, x2: 50, y2: spring(0)},
  }
  const letterProps = {
    defaultStyle: {x: 10, y: 40, width: 80, height: 50},
    style: {x: 10, y: spring(10, {stiffness: 60}), width: 80, height: spring(90, {stiffness: 60})},
  }

  return (
    <div className={styles.EnvelopeAnimation}>
      <svg viewBox='-10 -10 120 120'>
        {/* top of envelope line */}
        <line x1='1' y1='35' x2='99' y2='35' stroke='white' />

        {/* letter flap line left side */}
        <Motion {...flapLineLeftProps}>
          {interpolatedStyle => (
            <line
              x1={interpolatedStyle.x1}
              y1={interpolatedStyle.y1}
              x2={interpolatedStyle.x2}
              y2={interpolatedStyle.y2}
              stroke='white'
            />
          )}
        </Motion>

        {/* letter flap line right side */}
        <Motion {...flatLineRightProps}>
          {interpolatedStyle => (
            <line
              x1={interpolatedStyle.x1}
              y1={interpolatedStyle.y1}
              x2={interpolatedStyle.x2}
              y2={interpolatedStyle.y2}
              stroke='white'
            />
          )}
        </Motion>

        {/* letter */}
        <Motion {...letterProps}>
          {interpolatedStyle => (
            <path
              d={`M ${interpolatedStyle.x} ${interpolatedStyle.y} h ${interpolatedStyle.width} v ${interpolatedStyle.height} h -${interpolatedStyle.width} Z`}
              stroke='white'
              fill='black'
            />
          )}
        </Motion>

        {/* main envelope body */}
        <path d='M 0 35 L 50 70 L 100 35 L 100 100 L 0 100 Z' fill='black' stroke='white' />
      </svg>
      {checkmarkVisible &&
        <CompleteTick
          className={styles.CompleteTick}
          backgroundColor='transparent'
          tickColor='#53bd5b'
        />
      }
    </div>
  )
}

export default Login
