import {gql} from '@apollo/client'
import {GenericItemFragment} from '../../common/fragments'

export default gql`
  query ChartsPlaylistQuery($slug: String!) {
    collection_by_slug(slug: $slug) {
      title
      summary
      items {
        ...FullGenericItem
      }
    }
  }

  ${GenericItemFragment}
`
