// @flow
import React from 'react'

import Chevron from '../Svgs/Chevron'

import styles from './Control.less'

type Props = {
  type: 'left' | 'right' | 'mute' | 'unmute',
  className?: string,
  onClick?: Function,
  color?: string,
  borderColor?: string,
  background?: string,
}

const types = {
  left: 'left',
  right: 'right',
  mute: 'mute',
  unmute: 'unmute',
}

export default ({type, className, onClick, color, borderColor, background}: Props) => (
  <div
    onClick={onClick}
    className={[styles.Control, className].join(' ')}
    style={{
      borderColor: borderColor || '#FFFFFF',
      background: background || '#FFFFFF',
    }}
  >
    <Svg type={type} color={color || '#000000'} />
  </div>
)

const Svg = ({type, color}) => {
  if (type === types.left) {
    return <Chevron className={styles.LeftChevron} color={color} direction='left' />
  }

  if (type === types.right) {
    return <Chevron className={styles.RightChevron} color={color} />
  }

  if (type === types.unmute) {
    return (
      <svg width='19px' height='18px' viewBox='0 0 19 18' version='1.1'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='02_actions' transform='translate(-235.000000, -309.000000)' fill={color}>
            <g id='mute' transform='translate(235.000000, 309.000000)'>
              <path d='M18.289,1.304 L11.533,7.953 L11.533,15.6315 L6.989,12.4255 L1.325,18 L0,16.696 L5.4455,11.3365 L5.0645,11.0675 L1.671,11.0675 L1.671,5.437 L5.0645,5.437 L11.5335,0.8725 L11.5335,5.345 L16.9645,0 L18.289,1.304 L18.289,1.304 Z' />
            </g>
          </g>
        </g>
      </svg>
    )
  }

  if (type === types.mute) {
    return (
      <svg width='20px' height='15px' viewBox='0 0 20 15' version='1.1'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='02_actions' transform='translate(-124.000000, -310.000000)' fill={color}>
            <g id='sound' transform='translate(124.000000, 310.000000)'>
              <g id='Page-1'>
                <g>
                  <path
                    d='M16.5324389,14.3400531 C16.4911103,14.3803977 16.4355134,14.4030301 16.3774565,14.4030301 C16.3193996,14.4030301 16.2642948,14.3803977 16.2234581,14.3400531 L14.7808919,12.9196272 C14.7400553,12.8792826 14.7169309,12.8246697 14.7169309,12.7675969 C14.7169309,12.710524 14.7400553,12.6559111 14.7808919,12.6155665 C16.2347743,11.1838244 17.0357627,9.28319913 17.0357627,7.264 C17.0357627,5.24529287 16.2347743,3.34466757 14.7808919,1.91292549 C14.7400553,1.87258087 14.7169309,1.81796803 14.7169309,1.76089515 C14.7169309,1.70382227 14.7400553,1.64920943 14.7808919,1.60886481 L16.2234581,0.188438905 C16.3080834,0.104305608 16.4468296,0.104305608 16.5319469,0.188438905 C18.4542205,2.08119209 19.5130209,4.59436684 19.5130209,7.264 C19.5130209,9.93412517 18.4547125,12.4468079 16.5324389,14.3400531 L16.5324389,14.3400531 L16.5324389,14.3400531 Z M12.7331563,11.2374533 L11.4342563,9.95872555 C11.3973557,9.92231699 11.3766914,9.87311623 11.3766914,9.82194744 C11.3766914,9.77028664 11.3973557,9.72108588 11.4342563,9.68467732 C12.0920704,9.03719534 12.45468,8.17765809 12.45468,7.264 C12.45468,6.35083392 12.0920704,5.49129667 11.4342563,4.84332268 C11.3973557,4.80740612 11.3766914,4.75820536 11.3766914,4.70654457 C11.3766914,4.65537578 11.3973557,4.60617502 11.4342563,4.56976646 L12.7331563,3.29103874 C12.7700569,3.25463018 12.8202417,3.23396586 12.8723945,3.23396586 C12.9245473,3.23396586 12.97424,3.25463018 13.0111406,3.29103874 C14.0906053,4.35377513 14.6849504,5.76485289 14.6849504,7.264 C14.6849504,8.76363912 14.0906053,10.1747169 13.0111406,11.2374533 C12.9343874,11.3132224 12.8099095,11.3132224 12.7331563,11.2374533 L12.7331563,11.2374533 L12.7331563,11.2374533 Z M3.33777946,10.0354787 L0,10.0354787 L0,4.49301328 L3.33777946,4.49301328 L9.7023896,1.77635684e-15 L9.7023896,14.528 L3.33777946,10.0354787 L3.33777946,10.0354787 L3.33777946,10.0354787 Z'
                    id='path-1-copy'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
