import React from 'react'
import {useSelector} from 'react-redux'

import {brandIds} from '../../common/brands'
import type {State} from '../../stateType'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Link from '../Link'
import NavigationLink from '../NavigationLink'
import {appleMusicUrl, uiLocations} from '../../common/constants'
import Query from '../Query'
import Logo from '../Svgs/Logo'
import FourThreeLogo from '../Svgs/FourThreeLogo'
import SubscribeFooter from '../SubscribeFooter'

import styles from './Footer.less'
import GET_NAVIGATION from './Query.js'

export default ({disableSignup}: {disableSignup?: ?boolean}) => {
  const {brand} = useSelector(({brand}: State) => ({brand}))

  return (
    <Query
      Component={Footer}
      query={GET_NAVIGATION}
      props={{brand, disableSignup}}
    />
  )
}

const Footer = ({boiler_room_footer, fourthree_footer, global_footer, projects_footer, brand, disableSignup}) => {
  const boilerRoomLinks = boiler_room_footer && boiler_room_footer.navigation_elements
  const globalFooterLinks = global_footer && global_footer.navigation_elements
  const projectsFooterLinks = projects_footer && projects_footer.navigation_elements

  const isFourThree = brand.id === brandIds.fourthree
  const logoColour = isFourThree ? 'black' : 'white'

  return (
    <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
      {!disableSignup &&
        <div className={isFourThree ? styles.FourThreeFooterBackground : ''}>
          <SubscribeFooter isFourThree={isFourThree} uiLocation={uiLocations.footer} />
        </div>
      }
      <div className={styles.Footer}>
        {!isFourThree && <AppleMusicBanner />}
        <div className={styles.TopFooter}>
          <Links links={boilerRoomLinks} title={'Boiler Room'} titleLink={'https://boilerroom.tv'} />
          <Links links={projectsFooterLinks} title={'PROJECTS'} titleLink={null} />
          <Links links={globalFooterLinks} title={'Information'} titleLink={null} />
        </div>
        <div className={styles.BottomFooter}>
          <ul className={styles.Logos}>
            <li>
              <Link externalLink={'https://boilerroom.tv'}>
                <Logo className={styles.Logo} colour={logoColour} />
              </Link>
            </li>
            <li>
              <Link externalLink={'https://fourthree.boilerroom.tv'}>
                <FourThreeLogo className={styles.FourThreeLogo} colour={logoColour} />
              </Link>
            </li>
          </ul>
          <div className={styles.CopyrightNotice}>&copy; {new Date().getFullYear()} BOILER ROOM</div>
        </div>
      </div>
    </BrandedStyleWrapper>
  )
}

const Links = ({links, title, titleLink}) => {
  const titleItem = {name: title, link: titleLink}
  return (
    <ul>
      <li className={styles.Title}>
        {titleLink ?
          <NavigationLink item={titleItem} uiLocation={uiLocations.footerLink} /> :
          title
        }
      </li>
      {links && links.map((link, index) =>
        <li className={styles.Link} key={index}>
          <NavigationLink item={link} uiLocation={uiLocations.footerLink} />
        </li>,
      )}
    </ul>
  )
}

const AppleMusicBanner = () =>
  <a
    href={appleMusicUrl}
    target='_blank'
    className={styles.AppleBanner}
  >
    <img src={require('./applemusic.svg')} />
  </a>
