// @flow
import {genericItemTypes} from './constants'
import type {GenericItemType} from './types'

export const createImageAltText = (item?: GenericItemType) => {
  if (!item) return null

  switch (item.type) {
    case genericItemTypes.recording:
      return `Photo of set ${item.title}.`
    case genericItemTypes.show:
      return `Flyer for event ${item.title}.`
    case genericItemTypes.product:
      return `Photo of apparel ${item.title}.`
    case genericItemTypes.artist:
      return `Photo of artist ${item.title}.`
    case genericItemTypes.article:
      return `Image for article ${item.title}.`
  }

  return 'Thumbnail Image'
}
