export const website = require('./website.svg')
export const facebook = require('./facebook.svg')
export const instagram = require('./instagram.svg')
export const soundcloud = require('./soundcloud.svg')
export const twitter = require('./twitter.svg')
export const youtube = require('./youtube.svg')
export const vimeo = require('./vimeo.svg')
export const spotify = require('./spotify.svg')
export const apple = require('./apple.svg')
export const discord = require('./discord.svg')
