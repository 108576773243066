// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import Link from '../Link'

import type {State} from '../../stateType'
import {brandIds} from '../../common/brands'
import {hasWindow} from '../../common/constants'

import styles from './AccountNavigation.less'

export default () => {
  const {id} = useSelector((state: State) => state.brand)
  if (id !== brandIds.boilerroom) return null

  const NavLink = ({pathname, text}) => (
    <Link
      internalLink={pathname}
      className={hasWindow && window.location.pathname.includes(pathname) ? styles.ActiveNav : styles.Nav}
    >
      {text}
    </Link>
  )

  return (
    <div className={styles.Wrapper}>
      <div className={styles.NavHeadingsWrapper}>
        <NavLink pathname={'/favourites'} text={'FAVOURITES'} />
        <NavLink pathname={'/invite-requests'} text={'INVITES'} />
        <NavLink pathname={'/account'} text={'ACCOUNT'} />
      </div>
    </div>
  )
}
