// @flow
import React from 'react'
import {Link} from 'react-router-dom'
import {determineDefaultHomepage} from './../../../lib/defaultHomepage.js'

import {genericItemTypes, baseUrl} from '../../common/constants'
import {brandIds, getFullURL} from '../../common/brands'
import type {GenericItemType, BrandType} from '../../common/types'
import paths from '../../common/paths'
import {recordingHasAudio, recordingOnlyHasAudio} from '../../common/audioVideo.js'
import {analytics} from '../../common/analytics'

type Props = {
  internalLink?: ?string,
  externalLink?: ?string,
  item?: ?GenericItemType,

  className?: ?string,
  style?: any,
  children: any,
  uiLocation?: string,

  brand: BrandType,
  forceAudio: String,
}

const isToDifferentBrand = (item: GenericItemType, brand: BrandType) =>
  (!item.brand && brand.id !== brandIds.boilerroom) || // !item.brand === boiler room brand
  (item.brand && item.brand.id !== brand.id)

export default ({internalLink, externalLink, children, className, style, uiLocation, item, brand, forceAudio}: Props) => {
  const styling = {style, className}
  const isSubDomain =
    determineDefaultHomepage() !== determineDefaultHomepage('boilerroom.tv') &&
    determineDefaultHomepage() !== determineDefaultHomepage('fourthree')

  let link
  let isRegularLink
  if (item) {
    // use a regular link if the brand is different
    const differentBrand = isToDifferentBrand(item, brand)
    const nextBrandUrl = getFullURL(item.brand)
    const isSponsor = item.type === genericItemTypes.sponsor
    const isLink = item.type === genericItemTypes.link
    // links to /audio/:slug if on the Audio homepage or a recording only has audio content
    const shouldLinkToAudioRecording =
      (forceAudio && item.recording && recordingHasAudio(item.recording)) ||
      (item.recording && recordingOnlyHasAudio(item.recording))
    const pathname = shouldLinkToAudioRecording ? paths.recording(item, true) : paths.item(item)

    link = (differentBrand || isSubDomain) && !isLink ? `${nextBrandUrl}${pathname}` : pathname

    isRegularLink = differentBrand || isSponsor || isSubDomain || isLink
  } else if (brand.id !== brandIds.boilerroom && internalLink) {
    // use an internal link if we are on a brand page
    link = internalLink
    isRegularLink = false
  } else if (internalLink) {
    // use a regular link if we are on a subdomain
    link = isSubDomain ? `//${baseUrl}${internalLink}` : internalLink
    isRegularLink = isSubDomain
  } else if (externalLink) {
    // For external links
    link = externalLink
    isRegularLink = true
  }

  if (isRegularLink) {
    return (
      <a href={link} {...styling}>
        {children}
      </a>
    )
  }

  if (link) {
    return (
      <Link
        to={link}
        onClick={() => analytics.track('link_click', {ui_location: uiLocation, link}, ['ga'])}
        {...styling}
      >
        {children}
      </Link>
    )
  }

  return <span {...styling}>{children}</span>
}
