// @flow
import React from 'react'
import moment from 'moment-timezone'
import groupBy from 'lodash.groupby'

import Player from '../Player'
import Button from '../Button'
import {hasWindow} from '../../common/constants'
import getLocalTimezone from '../../common/getLocalTimezone'

import type {StreamsType, RoomType} from '../../common/types'

import styles from './MultiStream.less'

export default class MultiStream extends React.Component {
  constructor(props: any) {
    super(props)

    const roomsObject = groupBy(props.streams, 'room')
    this.rooms = Object.keys(roomsObject).map(name => ({
      name,
      streams: roomsObject[name],
    }))

    const stateFromHash = this.getStateFromHash()
    // Only populate if both a room and a stream exist for both indexes
    const shouldPopulateFromHash =
      stateFromHash &&
      this.rooms[stateFromHash.roomIndex] &&
      this.rooms[stateFromHash.roomIndex].streams[stateFromHash.streamIndex]

    const unhashedState = {
      streamIndex: this.chooseActiveStream(this.rooms[0].streams),
      roomIndex: 0,
    }

    this.state = shouldPopulateFromHash ? stateFromHash : unhashedState
  }

  props: {
    geoblocked: boolean,
    streams: Array<StreamsType>,
    title: string,
    displayChat?: boolean,
  }

  state: {
    streamIndex: number,
    roomIndex: number,
  }

  rooms: Array<RoomType>

  componentDidUpdate = () => this.storeStateInHash()
  componentDidMount = () => this.storeStateInHash()

  storeStateInHash = () => {
    window.location.hash = encodeURI(`${this.state.roomIndex}-${this.state.streamIndex}`)
  }

  getStateFromHash = () => {
    const [roomIndex, streamIndex] = decodeURI(hasWindow ? window.location.hash.replace('#', '') : '')
      .split('-')
      .map(index => parseInt(index, 10))
    return {roomIndex, streamIndex}
  }

  chooseActiveStream = (streams: Array<StreamsType>) => {
    // If an active stream title is in the URL, select this stream
    // regardless of broadcast status
    const windowHash = decodeURI(hasWindow ? window.location.hash.replace('#', '') : '')
    const indexFromHash = streams.findIndex(stream => stream.title === windowHash)
    if (indexFromHash >= 0) return indexFromHash

    const getStartTimeFromNow = stream =>
      moment.tz().unix() - moment.tz(stream.scheduled_start_time, getLocalTimezone()).unix()
    const startedStreams = streams
      .filter(stream => getStartTimeFromNow(stream) > 0)
      .map(stream => getStartTimeFromNow(stream))

    // If no streams have started, activate the first
    if (startedStreams.length === 0) return 0

    const closestStreamToNow = Math.min(...startedStreams)
    const indexOfClosestStreamToNow = streams.findIndex(stream => getStartTimeFromNow(stream) === closestStreamToNow)

    return indexOfClosestStreamToNow > 0 ? indexOfClosestStreamToNow : 0
  }

  generateChangeStream = (streamIndex: number) => () => {
    this.setState({
      streamIndex,
    })
  }

  generateChangeRoom = (roomIndex: number) => () =>
    this.setState({
      roomIndex,
      streamIndex: this.chooseActiveStream(this.rooms[roomIndex].streams),
    })

  render() {
    const {geoblocked, title, displayChat} = this.props
    const {roomIndex, streamIndex} = this.state

    const isMultiRoomShow = this.rooms.length > 1
    const activeStream = this.rooms[roomIndex].streams[streamIndex]

    return (
      <ShowSelector
        streamIndex={streamIndex}
        roomIndex={roomIndex}
        rooms={this.rooms}
        isMultiRoomShow={isMultiRoomShow}
        activeStream={activeStream}
        displayChat={displayChat}
        geoblocked={geoblocked}
        title={title}
        generateChangeStream={this.generateChangeStream}
        generateChangeRoom={this.generateChangeRoom}
      />
    )
  }
}

const ShowSelector = ({
  roomIndex,
  streamIndex,
  rooms,
  isMultiRoomShow,
  activeStream,

  displayChat,

  geoblocked,
  title,

  generateChangeStream,
  generateChangeRoom,
}) => (
  <div>
    {isMultiRoomShow &&
      <div className={styles.RoomTabs}>
        <div className={styles.RoomFlexBox}>
          <RenderRoomButtons rooms={rooms} generateChangeRoom={generateChangeRoom} roomIndex={roomIndex} />
        </div>
      </div>
    }

    <div className={isMultiRoomShow ? styles.MultiRoomStreamTabs : styles.StreamTabs}>
      <div className={styles.StreamFlexBox}>
        <RenderStreamButtons
          rooms={rooms}
          roomIndex={roomIndex}
          streamIndex={streamIndex}
          generateChangeStream={generateChangeStream}
        />
      </div>
    </div>

    <Player
      overrideUrl={activeStream.embed_override_url}
      youtubeId={activeStream.youtube_video_id}
      geoblocked={geoblocked}
      title={title}
      displayChat={displayChat}
    />
  </div>
)

// If a stream has a scheduled start time, display it in the users
// local timezone
const getStreamTitle = ({scheduled_start_time, title}: StreamsType) =>
  scheduled_start_time ? `${moment.tz(scheduled_start_time, getLocalTimezone()).format('HH:mm')} - ${title}` : title

const RenderStreamButtons = ({rooms, roomIndex, streamIndex, generateChangeStream}) =>
  rooms[roomIndex].streams.map((stream, index) => (
    <Button
      key={'stream' + index}
      className={index === streamIndex ? styles.StreamButton_Active : styles.StreamButton}
      onClick={generateChangeStream(index)}
      text={getStreamTitle(stream)}
    />
  ))

const RenderRoomButtons = ({rooms, generateChangeRoom, roomIndex}) =>
  rooms.map((room, index) => (
    <Button
      key={room.name}
      className={index === roomIndex ? styles.RoomButton_Active : styles.RoomButton}
      onClick={generateChangeRoom(index)}
      text={room.name}
    />
  ))
