import React from 'react'

const RSVPIcon = ({color}: {color?: string}) => {
  const fill = color || '#fff'

  return (
    <svg
      width='22'
      height='12'
      viewBox='0 0 24 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        stroke={fill}
        strokeLinejoin='bevel'
        d='M21.5 2.78573C20.6082 2.18221 20.5 0.928589 20.5 0.928589H11.5H3C3 0.928589 3.2828 2.05885 2.5 2.78573C1.7172 3.51262 1 3.25002 1 3.25002V5.57145C1 5.57145 2.01709 5.45537 2.01709 6.9643C2.01709 8.47323 1 8.47323 1 8.47323V10.6786C1 10.6786 1.69543 10.3958 2.5 11.1429C3.30457 11.89 3 12.5357 3 12.5357H21C21 12.5357 20.6507 11.9315 21.5 11.1429C22.3493 10.3543 23 10.6786 23 10.6786V8.47323C23 8.47323 22 8.47323 22 6.9643C22 5.45537 23 5.57145 23 5.57145V3.25002C23 3.25002 22.3918 3.38925 21.5 2.78573Z'
      />
      <path
        stroke={fill}
        d='M7.41379 4H6C5.44772 4 5 4.44772 5 5V8C5 8.55228 5.44772 9 6 9H7.41379M7.41379 4V9M7.41379 4H16.5862M7.41379 9H16.5862M16.5862 9H18C18.5523 9 19 8.55228 19 8V5C19 4.44772 18.5523 4 18 4H16.5862M16.5862 9V4'
      />
    </svg>
  )
}

export default RSVPIcon
