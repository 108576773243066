import React from 'react'

const Icon = ({className}: {className?: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='11'
      fill='none'
      viewBox='0 0 12 11'
      className={className}
    >
      <path
        stroke='currentColor'
        strokeWidth='0.5'
        d='M3.16 1.238H1.5a.5.5 0 00-.5.5v8.505a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V1.738a.5.5 0 00-.5-.5H8.864m-4.844 0H7.88'
      />
      <path
        fill='currentColor'
        stroke='currentColor'
        d='M2.53 1.836v.238h-.495V3.46h-.436V1.836h.93zm1.886.238h-.144v-.238H7.63v.238h-.396V3.46H4.916V2.074h-.5zm5.198 0h-.292v-.238h1.08V3.46h-.288V2.074h-.5z'
      />
      <path
        stroke='currentColor'
        strokeWidth='0.3'
        d='M3.18 0.15H3.87V2.424H3.18z'
      />
      <path
        stroke='currentColor'
        strokeWidth='0.3'
        d='M8.031 0.15H8.721V2.424H8.031z'
      />
      <path
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        d='M2.25 6.25H9.77V6.75H2.25z'
      />
      <path
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        d='M2.25 8.25H9.77V8.75H2.25z'
      />
    </svg>
  )
}

export default Icon
