// @flow
import React from 'react'

type Direction = 'left' | 'right' | 'up' | 'down'

export default ({color, direction}: {color?: string, direction?: Direction}) => {
  const fill = color || '#fff'
  const dir = direction || 'left'

  if (dir === 'right') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='none'>
        <path d='M1 4.72222H8.59991V2L12.9999 5.5L8.59991 9V6.27778H1V4.72222Z' fill={fill} stroke='white' />
      </svg>
    )
  }

  if (dir === 'left') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='none'>
        <path d='M13 4.72222H5.40009V2L1.00009 5.5L5.40009 9V6.27778H13V4.72222Z' fill={fill} stroke='white' />
      </svg>
    )
  }
}
