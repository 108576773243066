import {connect} from 'react-redux'

export default (mapStateToProps, mapDispatchToProps = {}, ...args) => {
  Object.keys(mapDispatchToProps).forEach(key => {
    if (!mapDispatchToProps[key]) {
      console.error(`Action '${key}' does not exist!`)
    }
  })

  return connect(mapStateToProps, mapDispatchToProps, ...args)
}
