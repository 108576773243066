// @flow
import React from 'react'

export default class Gasworks extends React.PureComponent {
  props: {|
    className?: string,
    colour?: string,
  |}

  render() {
    const {className, colour} = this.props
    const fill = colour || '#FFF'

    return (
      <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 316'>
        <g>
          <g>
            <g id='G'>
              <path
                className='cls-1'
                fill={fill}
                d='M170.5,140.7c-12.4-0.3-24.9-0.6-37.3-0.9c0-4.3,0-6.5,0-10.8c0-4.3,0-10.6-12.5-11.3c-12-0.7-12,5.3-12,10.7 c0,19.9,0,39.8,0,59.7c0,4.2,0,11.5,12.3,10.8c12.3-0.7,12.3-7.4,12.3-11.7c0-5.2,0-7.9,0-13.1c-6,0.1-9,0.2-15,0.3 c0-7.8,0-11.7,0-19.6c17.4,0.1,34.9,0.1,52.3,0.2c0,19.4,0,38.8,0,58.2c-12.4,0.9-24.9,1.8-37.3,2.8c0-4.3,0-6.4,0-10.7 c-7.8,8.3-18.8,12.3-29.5,13.4c-25.4,2.7-34-16.8-33.9-38.6c0-15.7,0-31.4,0-47.1c-0.1-32.1,21-38.5,49.8-36.1 c11.5,1,24.5,2.2,34.1,7.1c10.7,5.4,17.8,12.8,16.8,26.7C170.5,134.8,170.5,136.8,170.5,140.7z'
              />
            </g>
            <g id='A'>
              <path
                className='cls-1'
                fill={fill}
                d='M207.8,105.7c17.3,1.1,34.6,2.1,51.9,3.1c10.6,32.5,21.4,64.9,32.6,97.2c-12.7,0.6-25.3,1.3-38,2 c-2.2-8.9-3.3-13.4-5.5-22.5c-9.9,0.3-19.9,0.6-29.8,1c-2.3,9.5-3.4,14.3-5.7,24c-12.7,0.8-25.3,1.7-38,2.6 C185.7,177,196.6,141.3,207.8,105.7z M234.2,123.4c-3.7,15.1-7.3,30.2-10.9,45.4c8.6-0.1,13-0.2,21.6-0.3 C241.3,153.5,237.7,138.5,234.2,123.4z'
              />
            </g>
            <g id='S'>
              <path
                className='cls-1'
                fill={fill}
                d='M315.2,159.6c-11.4-4.1-22.2-10.1-22.3-23.3c-0.2-26.4,30.8-26.5,50.3-25.3c21.5,1.3,47.6,2.5,45.1,30.1 c-12.3-0.1-24.6-0.3-36.9-0.5c0-2.5,0-3.8,0-6.3c0-4.6-0.7-7.6-10.7-7.8c-9.8-0.3-9.8,4-9.8,7.8c0,5,0.5,6.8,7.3,9.5 c11,4.5,22.1,8.8,33.3,13.1c10.9,3.9,21,8.7,20.7,21.7c-0.2,9.4-3.4,14.3-10.3,18.3c-11.6,6.9-27.2,8-40.3,8.2 c-13.2,0.2-27,0.8-39.6-4.2c-10.7-4.3-11.9-15.5-10.9-25.5c12.8-0.2,25.7-0.4,38.5-0.6c0,2.9,0,4.3,0,7.2c0,2.2,0,7.4,11.4,7.1 c12.1-0.3,12.3-4.5,12.3-6.7c0-6.9-0.2-7.5-14.6-13.2C329.3,165.4,324.6,163.5,315.2,159.6z'
              />
            </g>
            <g id='W'>
              <path
                className='cls-1'
                fill={fill}
                d='M391.1,114.1c12.3,0.4,24.6,0.7,36.9,0.9c3.9,22.1,7.7,44.3,11.6,66.4c0.7,0,1.1,0,1.8,0c6.2-21.9,12.4-43.9,18.7-65.8 c15,0.2,29.9,0.4,44.9,0.5c7.4,21.6,14.7,43.2,22.1,64.8c0.5,0,0.8,0,1.4,0c3-21.6,5.9-43.1,8.9-64.7c12.5,0,24.9,0,37.4-0.1 c-6.4,28-12.9,55.9-19.4,83.9c-16.8-0.1-33.6,0-50.4,0.1c-7.3-21.9-14.6-43.8-21.9-65.7c-7,22-14,44.1-21,66.1 c-16.9,0.2-33.7,0.6-50.6,1C404.7,172.4,397.8,143.2,391.1,114.1z'
              />
            </g>
            <g id='O'>
              <path
                className='cls-1'
                fill={fill}
                d='M578.2,139.8c0-6.7,1.7-13.4,11.4-18.5c12.4-6.5,27.9-7.6,41.9-7.5c18.6,0.1,52.9,0.4,51.3,27.2c0,11.2,0,22.3,0,33.5 c0,6.9,0,15.4-11.8,21.6c-12,6.2-27.6,6.2-40.6,5.8c-17.3-0.6-53.5-1.2-52.2-27.3C578.2,163,578.2,151.4,578.2,139.8z M617.1,179.5c0,3.8,0,7.2,13.4,7.4c10.5,0.1,13.4-2.1,13.4-7.1c0-14.6,0-29.2,0-43.8c0-5.6-3.6-7-14.1-6.9 c-9.6,0.1-12.8,1.9-12.8,7C617.1,150.5,617.1,165,617.1,179.5z'
              />
            </g>
            <g id='R'>
              <path
                className='cls-1'
                fill={fill}
                d='M692.8,113.9c18.2-0.6,36.5-1.2,54.7-1.9c11.3-0.6,24.3-1,33.9,4.7c6.9,4.1,7.7,11.3,7.7,18c0.2,12.7-9.2,18.1-20.3,21.3 c12.3,1.3,21.5,5.6,20.7,19.1c0,8.1,0,12.2,0,20.3c0,6.4,2,8.4,5.5,10.8c-11.9-0.6-23.8-1.1-35.8-1.7c-2.7-3.1-5.5-6-5.5-13.6 c0-2.2,0.4-13.4,0.5-16c0.3-12.8-16.4-10.5-25.1-10.2c0,12.9,0,25.7,0,38.6c-12.1-0.4-24.3-0.9-36.5-1.2 C692.8,172.8,692.8,143.4,692.8,113.9z M729.3,128.5c0,8.3,0,12.4,0,20.6c11.6-0.1,15-0.1,16.9-0.4c7.7-1,8.4-4.4,8.4-11.5 c0-8-2-9.4-13.7-9.1C736.2,128.3,733.9,128.4,729.3,128.5z'
              />
            </g>
            <g id='K'>
              <path
                className='cls-1'
                fill={fill}
                d='M909.8,103.1c-10.6,17.6-21.3,35-32.5,52.2c12.6,19.2,24.7,38.6,36.6,58.2c-13.6-1-27.1-1.9-40.7-2.8 c-10.6-17.3-21.4-34.5-32.5-51.4c0,16.5,0,32.9,0,49.4c-12.8-0.8-25.6-1.5-38.5-2.1c0-32.3,0-64.6,0-96.9 c12.8-0.6,25.7-1.3,38.5-2.1c0,15.3,0,30.6,0,45.8c10.1-15.8,19.9-31.6,29.6-47.6C883.4,105,896.6,104.1,909.8,103.1z'
              />
            </g>
            <g id='S'>
              <path
                className='cls-1'
                fill={fill}
                d='M933.4,160c-12.4-5.5-22.4-12.4-22.3-27.1c0.3-28.2,26.7-33.8,50.2-35.9c29.9-2.6,46.4,5.3,45,36.8 c-12.3,0.4-24.5,0.8-36.8,1.2c0-3.3,0-5,0-8.3c0-6.1-0.7-9.9-10.7-9.4c-9.8,0.5-9.8,6-9.8,10.7c0,6.2,0.5,8.5,7.3,11.7 c11.2,5.4,22.2,10.9,33.2,16.7c13.6,6.8,20.7,15.6,20.7,31.1c0,9.1-2.2,19.5-10.2,25.2c-10.9,7.8-27.7,7.1-40.2,6 c-13-1.1-27.9-3.3-39.6-10.1c-10.8-6.6-11.9-19.2-10.9-30.6c12.8,0.3,25.6,0.7,38.4,1.1c0,3.6,0,5.4,0,9c0,2.8,0,9.3,11.4,10 c12,0.7,12.3-4.9,12.3-7.8c0-9.2-0.2 10.2-14.5-17.9C947.5,167.2,942.8,164.8,933.4,160z'
              />
            </g>
          </g>
        </g>
        <g id='OUTER'>
          <path
            className='cls-1'
            fill={fill}
            d='M1021.9,65.7c0,61.5,0,123.1,0,184.6c-320.1-39.7-643.8-39.7-963.8,0c0-61.5,0-123.1,0-184.6 C378.2,105.3,701.8,105.3,1021.9,65.7 M1041,44.2c-332.1,51.5-669.9,51.5-1002,0c0,75.9,0,151.8,0,227.6 c332.1-51.5,669.9-51.5,1002,0C1041,195.9,1041,120.1,1041,44.2L1041,44.2z'
          />
        </g>
      </svg>
    )
  }
}
