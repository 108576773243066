// @flow
import React from 'react'
import marked from 'marked'

import Logo from '../Logo'
import Sponsor from '../Sponsor'
import Stripe from '../Stripe'
import Button from '../Button'
import RsvpStatus from '../RsvpStatus'
import BrandedComponent from '../BrandedComponent'
import BroadcastData from '../BroadcastData'
import Signup from '../Signup'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import ContentPurchaseCTA from '../ContentPurchaseCTA'

import type {DigitalProductType, ShowType} from '../../common/types'
import {analytics} from '../../common/analytics'
import {broadcastStates, genericItemTypes, signupSources, uiLocations} from '../../common/constants'

import styles from './ShowInfo.less'
import zoomLogo from './ZoomLogo.svg'

type Props = {
  show: ShowType,
  handleRsvpClick?: () => void,
}

export default ({show, handleRsvpClick}: Props) => {
  // $FlowFixMe
  const [checkoutOpen, setCheckoutOpen] = React.useState(false)
  // $FlowFixMe
  const [descriptionCollapsed, setDescriptionCollapsed] = React.useState(show.broadcast_status === broadcastStates.archived)
  const rsvpStatus = show && show.user_rsvp_status && show.user_rsvp_status.status
  const showRSVPButton = show.rsvp_enabled && !rsvpStatus

  // Dirty hack to make True Music Fund always appear on one line
  const parsedTitle = show.title.replace('True Music Fund:', 'True&nbsp;Music&nbsp;Fund:')
  const showIsArchived = show.broadcast_status === broadcastStates.archived
  const showIsNotOffAirOrArchived =
    show.broadcast_status !== broadcastStates.offair && !showIsArchived
  const firstDigitalProduct: ?DigitalProductType = showIsNotOffAirOrArchived ? show.digital_products[0] : undefined
  const showAccessibilityInformation =
    showIsNotOffAirOrArchived &&
    !show.accessibility_information_not_required
    && show.accessibility_information

  return (
    <BrandedStyleWrapper styles={styles} classNameKey={'Wrapper'}>
      <BrandedComponent
        brandContents={(isFourThree, isLH, isGW) => (
          <React.Fragment>
            {show.logo_image ?
              <Logo src={show.logo_image} large={true} /> :
              <h2 className={styles.Title} dangerouslySetInnerHTML={{__html: parsedTitle}} />
            }

            <BroadcastData show={show} />

            {rsvpStatus && <RsvpStatus className={styles.RsvpStatus} show={show} />}

            <ShowCTAButton show={show} />

            {show.signup_enabled &&
              <Signup
                className={styles.ShowSignup}
                color={'#fff'}
                signup={show.signup}
                signupSource={signupSources.showSubscribe}
                uiLocation={uiLocations.show}
                favouriteItemId={show.id}
                favouriteItemType={genericItemTypes.show}
                item={show}
              />
            }

            {showRSVPButton &&
              <Button
                text={'Request Invite'}
                onClick={handleRsvpClick}
                className={styles.CTAButton}
              />
            }

            {firstDigitalProduct &&
              <ContentPurchaseCTA
                digitalProduct={firstDigitalProduct}
                openCheckout={() => setCheckoutOpen(true)}
              />
            }

            {show.video_call_url && <ZoomPartyLink url={show.video_call_url} />}

            {!showIsArchived ?
              <Description
                description={show.description}
                descriptionCollapsed={descriptionCollapsed}
                setDescriptionCollapsed={setDescriptionCollapsed}
                buttonText={'READ MORE'}
                charsToDisplay={80}
                limit={250}
              /> :
              <p
                dangerouslySetInnerHTML={{__html: marked(show.description)}}
              />
            }

            {showAccessibilityInformation &&
              <div className={styles.AccessibilityInformation}>
                <h4>ACCESSIBILITY INFORMATION</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(show.accessibility_information),
                  }}
                />
              </div>
            }

            <Sponsor sponsor={show.sponsor} />
          </React.Fragment>
        )}
      />
      {checkoutOpen && <Stripe digitalProduct={firstDigitalProduct} closeCheckout={() => setCheckoutOpen(false)} />}
    </BrandedStyleWrapper>
  )
}

export const diceTicketOverlayOpenTracking = () => {
  analytics.track('InitiateCheckout', {}, ['fb'])
  analytics.track('open_dice_ticket_overlay', {}, ['ga'])
}

const ZoomPartyLink = ({url}) => {
  return <div className={styles.ZoomButton}>
    Watch on{' '}
    <a href={url} target='_blank'>
      <img src={zoomLogo} className={styles.ZoomLogo} alt='Zoom' />
    </a>
  </div>
}

type DescriptionProps = {
  description: string,
  descriptionCollapsed: boolean,
  setDescriptionCollapsed: boolean => boolean,
  limit: number,
  charsToDisplay: number,
  buttonText: string,
}

export const Description = ({description, descriptionCollapsed, setDescriptionCollapsed, limit, charsToDisplay, buttonText}: DescriptionProps) => {
  // Only truncate descriptions if they exceed specified character limit

  const shouldTruncate = description.length > limit
  if (shouldTruncate && descriptionCollapsed) {
    return (
      <div className={styles.CollapsedDescription}>
        <p
          dangerouslySetInnerHTML={{__html: marked(description.slice(0, charsToDisplay).trim() + '...')}}
        />
        <Button
          text={buttonText}
          onClick={() => setDescriptionCollapsed(false)}
          disableDefaultStyles={true}
          className={styles.ReadMoreButton}
        />
      </div>
    )
  }

  return (
    <div
      className={styles.Description}
      dangerouslySetInnerHTML={{__html: marked(description)}}
    />
  )
}

const ShowCTAButton = ({show}: {show: ShowType}) => {
  const ctaEnabled = show.cta_enabled && show.cta_enabled
  const isParentShow = show.child_shows && show.child_shows.length > 0

  const showCTA =
    <div onClick={diceTicketOverlayOpenTracking}>
      <Button
        className={styles.CTAButton}
        diceEventId={show.dice_event_id}
        href={show.cta_url}
        text={show.cta_text}
        target='_blank'
      />
    </div>

  if (isParentShow) {
    return (
      <React.Fragment>
        {ctaEnabled && showCTA}
        {show.child_shows.map(childShow =>
          childShow.cta_enabled &&
          <div onClick={diceTicketOverlayOpenTracking} key={childShow.id}>
            <Button
              className={styles.CTAButton}
              diceEventId={childShow.dice_event_id}
              href={childShow.cta_url}
              text={childShow.cta_text}
              target='_blank'
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  if (ctaEnabled) return showCTA

  return null
}
