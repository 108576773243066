// @flow
import {connect} from 'react-redux'

import {actions} from '../Notifications/state'
import Component from './Component'

const mapDispatchToProps = {
  setError: actions.setError,
}

export default connect(null, mapDispatchToProps)(Component)
