// @flow
import connect from '../../common/safeConnect'

import {actions} from '../Auth/state'

import Component from './Component'

const mapDispatchToProps = {
  emailLogin: actions.emailLogin,
  verifyCode: actions.verifyCode,
}

export default connect(null, mapDispatchToProps)(Component)
