// @flow
import React from 'react'

import {brandIds} from '../../common/brands'

import Grid, {GridItem} from '../Grid'
import Link from '../Link'
import Page from '../Page'

import Content from './Content'
import styles from './Contact.less'

export default () => (
  <Page allBrandPage={false} brandId={brandIds.fourthree}>
    <Grid className={styles.Contact}>
      <GridItem width={12} className={styles.Content}>
        <h3 className={styles.Header}>Want to work with 4:3?</h3>
        {Content.map(section => (
          <Section key={section.header} body={section.body} header={section.header} />
        ))}
        <p>
          Get in touch via{' '}
          <Link className={styles.Email} externalLink={'mailto:fourthree@boilerroom.tv'}>
            fourthree@boilerroom.tv
          </Link>
        </p>
      </GridItem>
    </Grid>
  </Page>
)

const Section = ({body, header}) => (
  <div>
    <p className={styles.Subheader}>{header}</p>
    <p>{body}</p>
  </div>
)
