// @flow
import React from 'react'

import {uiLocations} from '../../common/constants'

import Page from '../Page'
import SubscribeFooter from '../SubscribeFooter'
import ShopNav from '../ShopNav'

import styles from './Newsletter.less'

export default () => (
  <Page title='Sign up to our mailing list' subNavigation={<ShopNav />} ssr={true} optInGateTags={['shop']}>
    <div className={styles.Wrapper}>
      <SubscribeFooter uiLocation={uiLocations.newsletter} />
    </div>
  </Page>
)
