// @flow
import React from 'react'

export default ({color}: {color?: string}) => {
  const fill = color || 'none'
  const strokeColor = color || '#fff'

  return (
    <svg width='20' height='20' viewBox='0 0 16 14' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.67723 2.25875L7.67736 2.25886L7.6774 2.25889L7.67743 2.25892L8.00001 2.53159L8.32259 2.25892L8.32262 2.25889L8.32266 2.25886L8.32279 2.25875L8.32339 2.25825L8.32769 2.25464L8.34711 2.23859C8.36476 2.2241 8.39164 2.20224 8.42695 2.17417C8.49759 2.11799 8.6017 2.03712 8.73272 1.94077C8.99551 1.74751 9.36284 1.49466 9.78287 1.25406C10.2046 1.01245 10.6677 0.790052 11.1231 0.650001C11.5826 0.508674 11.9992 0.462127 12.3452 0.532212C13.356 0.736947 14.1279 1.15073 14.6516 1.78672C15.175 2.42238 15.4967 3.33629 15.5 4.63024C15.5006 4.86567 15.4117 5.20224 15.2058 5.64018C15.0038 6.06962 14.7085 6.55665 14.3428 7.0811C13.6116 8.12962 12.6285 9.28618 11.6343 10.3686C10.6422 11.4489 9.64851 12.4453 8.90213 13.1722C8.52918 13.5354 8.21852 13.8308 8.00138 14.0352C8.00102 14.0355 8.00065 14.0359 8.00029 14.0362C8.00003 14.036 7.99976 14.0357 7.9995 14.0355C7.7827 13.8311 7.47252 13.5357 7.10009 13.1725C6.35477 12.4455 5.36237 11.4492 4.37102 10.3688C3.37773 9.28631 2.39487 8.12963 1.66296 7.08094C1.29687 6.55639 1.00085 6.06916 0.797865 5.63942C0.59088 5.20122 0.500618 4.86397 0.500024 4.62773C0.496776 3.33541 0.818323 2.42248 1.34284 1.78726C1.86811 1.15111 2.64354 0.737048 3.65483 0.532212C4.00085 0.462127 4.41738 0.508674 4.87691 0.650001C5.33229 0.790053 5.79538 1.01245 6.21715 1.25406C6.63718 1.49466 7.00451 1.74751 7.26729 1.94077C7.39832 2.03712 7.50243 2.11799 7.57307 2.17417C7.60838 2.20224 7.63526 2.2241 7.65291 2.23859L7.67232 2.25464L7.67663 2.25825L7.67723 2.25875Z'
        stroke={strokeColor}
        strokeWidth='1px'
        vectorEffect='non-scaling-stroke'
      />
    </svg>
  )
}
