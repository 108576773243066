// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

import {signupSources} from '../../common/constants'
import {analytics} from '../../common/analytics'

export const types = {
  openGate: 'Gate/openGate',
  closeGate: 'Gate/closeGate',
}

export const plainActions = mapValues(types, type => createAction(type))

const openGate = (signupSource: string, uiLocation: string, itemId: number | string, itemType: string) => dispatch => {
  analytics.track('open_auth_gate', {ui_location: uiLocation})
  dispatch(plainActions.openGate({signupSource, itemId, itemType}))
}

export const actions = {
  ...plainActions,

  // thunks
  openGate,
}

export type StateType = {
  userOpenedGate: boolean,
  signupSource: ?string,
  itemId: ?number | ?string,
  itemType: ?string,
}

const initialState: StateType = {
  userOpenedGate: false,
  signupSource: signupSources.unknown,
  itemId: null,
  itemType: null,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.openGate:
      return {
        userOpenedGate: true,
        signupSource: action.payload.signupSource,
        itemId: action.payload.itemId,
        itemType: action.payload.itemType,
      }
    case types.closeGate:
      return {
        userOpenedGate: false,
        signupSource: signupSources.unknown,
      }
    default:
      return state
  }
}
