import React from 'react'

const SignupIcon = ({color}: {color?: string}) => {
  const fill = color || '#fff'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='12'
      fill='none'
      viewBox='0 0 19 12'
    >
      <path
        stroke={fill}
        strokeLinejoin='bevel'
        strokeWidth='1.5'
        d='M1.5 1.5v9h16v-9zM1.5 1.5h16M1.5 1.5l7.75 4.977L17.5 1.5'
      />
    </svg>
  )
}

export default SignupIcon
