// @flow
import React from 'react'

import Button from '../Button'
import Link from '../Link'

import styles from './MessageWithCTA.less'

type Props = {
  message: string,
  buttonText: string,
  internalLink: string,
}

export default ({message, buttonText, internalLink}: Props) => (
  <div className={styles.Wrapper}>
    <div className={styles.Message}>{message}</div>
    <Link internalLink={internalLink}>
      <Button text={buttonText} />
    </Link>
  </div>
)
