// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import type {State} from '../../stateType'
import type {StateType as PlayerType} from '../PersistentPlayer/state'
import {isAudioMode, recordingHasNoMedia} from '../../common/audioVideo'
import {isUserAllowedToWatch} from '../../common/digitalProducts'
import imageUrl from '../../common/imageUrl'

import type {RecordingType, LocationType} from '../../common/types'

import Player from '../Player'
import PlayerInitialiser from '../PlayerInitialiser'
import Background from '../Background'
import {makeQueue} from '../../common/makeQueue'

import styles from './RecordingPlayer.less'
import {mediaFormats} from '../../common/constants'

type Props = {
  recording: RecordingType,
  location: LocationType,
}

export default ({recording, location}: Props) => {
  const {isLoggedIn} = useSelector((state: State) => state.auth)
  const {itemSlug, format}: PlayerType = useSelector((state: State) => state.player)
  const isAllowedToWatch = isUserAllowedToWatch(recording.digital_products)
  const isAudio = isAudioMode(recording, location)
  const hasNoMedia = recordingHasNoMedia(recording)
  const requiresLogin = recording.requires_login && !isLoggedIn

  const {background_image, thumbnail_image} = recording

  if (!isAllowedToWatch) {
    return <Player paywalled={true} backgroundImage={background_image || thumbnail_image} />
  }

  if (recording.geoblocked) {
    return <Player geoblocked={true} />
  }

  if (hasNoMedia || requiresLogin) return <Player backgroundImage={background_image || thumbnail_image} />

  // audio player
  if (isAudio) {
    const audioImage = recording.thumbnail_image || (recording.show && recording.show.flyer_image)

    return (
      <div className={styles.Audio}>
        <div className={styles.AudioArtwork}>
          <img src={imageUrl(audioImage, 500, 500)} />
        </div>
      </div>
    )
  }
  // if the current recording is playing then display nothing
  // as the expanded PersistentPlayer will take the place of
  // this component
  const videoIsPlaying = itemSlug === recording.slug && format === mediaFormats.video
  if (videoIsPlaying) return null

  const queue = makeQueue(recording, recording.show && recording.show.recordings || [])

  return (
    <div className={styles.Video}>
      <Background image={recording.thumbnail_image} dim={0.5} />

      <PlayerInitialiser
        queue={queue}
        location={location}
        autoplay={true}
        formatOverride={mediaFormats.video}
        disableQueueActivation
      />
    </div>
  )
}
