import React from 'react'
import {gql} from '@apollo/client'
import {Query} from '@apollo/client/react/components'

import connect from '../../common/safeConnect'
import type {State} from '../../stateType'
import {GenericItemFragment, BrandFragment, ThemeFragment} from '../../common/fragments'
import {itemsPerPage} from '../../common/constants'

import Component from './Component'

const mapStateToProps = ({brand}: State) => ({brand})

const GET_COLLECTION_BY_SLUG = gql`
  query GenericListQuery($slug: String!, $limit: Int, $offset: Int) {
    collection_by_slug(slug: $slug) {
      id
      title
      description
      background_image
      logo_image
      display_logo_and_title
      redirect_url
      type
      slug

      theme {
        ...FullTheme
      }

      items(limit: $limit, offset: $offset) {
        ...FullGenericItem
      }

      items_count
      requires_login

      sponsor {
        logo_image
        url
      }

      brand {
        ...FullBrand
      }
    }
  }

  ${GenericItemFragment}
  ${BrandFragment}
  ${ThemeFragment}
`

const GenericList = props => (
  <Query
    query={GET_COLLECTION_BY_SLUG}
    variables={{
      type: props.match.params.type,
      slug: props.match.params.slug,
      limit: itemsPerPage,
      offset: props.match.params.page ? (props.match.params.page - 1) * itemsPerPage : 0,
    }}
    fetchPolicy='cache-and-network'
    notifyOnNetworkStatusChange={true}
  >
    {({data, loading, fetchMore}) => <Component {...props} data={data} loading={loading} />}
  </Query>
)

export default connect(mapStateToProps)(GenericList)
