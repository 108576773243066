// @flow
import React from 'react'
import Modal from 'react-modal'

import CloseIcon from '../Svgs/CloseIcon'

import styles from './Modal.less'

// NOTE react-modal requires styling to be formatted like the below
const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 50,
  },
  content: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    zIndex: 10,
  },
}

type Props = {
  children: any,
  closeModal: () => void,
  modalOpen: boolean,
}

export default ({children, closeModal, modalOpen}: Props) => (
  <Modal isOpen={modalOpen} style={modalStyles} ariaHideApp={false}>
    <CloseIcon className={styles.closeIcon} onClick={closeModal} />
    {children}
  </Modal>
)
