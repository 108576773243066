// @flow
import React from 'react'

import type {RecordingType} from '../../common/types'

import styles from './BroadcastGenres.less'

type Props = {
  recording: ?RecordingType,
  className?: ?string,
}

export default ({recording, className}: Props) => {
  if (!recording || !recording.genres) return null
  const firstThreeGenres = recording.genres.slice(0, 3)

  return (
    <div className={[className, styles.BroadcastGenres].join(' ')}>
      {firstThreeGenres.map(({title}, index) => (
        <span key={`${title}`}>{title}</span>
      ))}
    </div>
  )
}
