import {ShowType} from './types'

/*
  The below function is here to order shows to a specific format. We want to display all future shows first, starting with the closest start date and ascending,
  then returning to the default date descending order returned by the shows query. The reason for this is to highlight the first upcoming show for a city in the
  best way.
*/

export const sortShowsForCityPage = (shows: Array<ShowType>) => {
  const isShowInPast = show => new Date(show.start) < new Date()

  const firstHistoricShowIndex = shows.findIndex(isShowInPast)

  const futureShows = shows.slice(0, firstHistoricShowIndex)

  return [...futureShows.reverse(), ...shows.slice(firstHistoricShowIndex)]
}
