// @flow
import React from 'react'

export default ({className = '', color}: {className?: string, color?: string}) => {
  const fill = color || '#000'

  return (
    <div className={className}>
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill={fill}>
        <path d='m3,9v11h14V9M4,9V6c0-3.3 2.7-6 6-6c3.3,0 6,2.7 6,6v3H14V6c0-2.2-1.8-4-4-4-2.2,0-4,1.8-4,4v3' />
      </svg>
    </div>
  )
}
