// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'

import {mediumScreen, largeScreen, extraLargeScreen, pageSizes, hasWindow} from '../../common/constants'

export const types = {
  updateDimensions: 'Page/UpdateDimensions',
  hideMainNavigation: 'Page/HideMainNavigation',
  showMainNavigation: 'Page/ShowMainNavigation',
}

export const actions = mapValues(types, type => createAction(type))

export type StateType = {
  width: number,
  height: number,
  pageSize: string,
  hideMainNavigation: boolean,
}

const getDimensions = () => {
  const width = hasWindow ? window.innerWidth : 1000
  const height = hasWindow ? window.innerHeight : 1000

  return {
    width,
    height,
    pageSize: getPageSize(width),
  }
}

const getPageSize = pageWidth => {
  if (pageWidth >= extraLargeScreen) return pageSizes.extraLarge
  if (pageWidth >= largeScreen) return pageSizes.large
  if (pageWidth >= mediumScreen) return pageSizes.medium
  return pageSizes.small
}

const initialState: StateType = {
  width: getDimensions().width,
  height: getDimensions().height,
  pageSize: getDimensions().pageSize,
  hideMainNavigation: false,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.updateDimensions:
      return {
        ...state,
        ...getDimensions(),
      }
    case types.hideMainNavigation:
      return {
        ...state,
        hideMainNavigation: true,
      }
    case types.showMainNavigation:
      return {
        ...state,
        hideMainNavigation: false,
      }
    default:
      return state
  }
}
