// @flow
import {connect} from 'react-redux'

import type {State} from '../../stateType'

import Component from './Component'
import {actions} from './state'
import {actions as gateActions} from '../Gate/state'

const mapStateToProps = ({show, page, auth}: State) => ({
  ...show,
  pageSize: page.pageSize,
  isLoggedIn: auth.isLoggedIn,
})

const mapDispatchToProps = {
  ...actions,
  openGate: gateActions.openGate,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
