import {gql} from '@apollo/client'

export default gql`
  mutation UpdateFeedback(
    $uuid: String,
    $anonymous_id: String,
    $show_slug: String,
    $rating: Int
    $positive_tags: [String]
    $positive_text: String,
    $negative_tags: [String],
    $negative_text: String,
    $complete: Boolean
  ) {
    update_feedback(
      anonymous_id: $anonymous_id,
      uuid: $uuid,
      show_slug: $show_slug,
      rating: $rating,
      positive_tags: $positive_tags,
      positive_text: $positive_text,
      negative_tags: $negative_tags,
      negative_text: $negative_text,
      complete: $complete,
    ) {
      complete
      anonymous_id
    }
  }
`
