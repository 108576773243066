// @flow
import React from 'react'

import {uiLocations} from '../../common/constants'

import Link from '../Link'
import Arrow from '../Svgs/Arrow'

import type {CityType} from '../../common/types'

import styles from './CityUpdatesLink.less'

export default ({city, arrowColor, className}: {city?: CityType, arrowColor?: string, className?: string}) => {
  if (!city) return null
  const color = arrowColor || '#000'

  return (
    <Link
      className={[styles.ShowCompleteCityLink, className].join(' ')}
      internalLink={`/city/${city.slug}`}
      uiLocation={uiLocations.cityUpdatesLink}
    >
      Get {city.name} updates
      <Arrow direction='right' color={color} />
    </Link>
  )
}
