import React from 'react'

function Icon({className}: {className?: string}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      fill='none'
      viewBox='0 0 10 10'
      className={className}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M0 1a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1H1a1 1 0 01-1-1V1zm1.95 2.95a.5.5 0 01.5-.5H5.6a.5.5 0 01.5.5v2.1a.5.5 0 01-.5.5H2.45a.5.5 0 01-.5-.5v-2.1zm6.1-.3l-1.7.636v1.48l1.7.584v-2.7z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default Icon
