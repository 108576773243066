import {gql} from '@apollo/client'

export default gql`
  mutation RsvpMutation(
    $show_id: Int!
    $consent_box_data: [ConsentBoxDataInput!]
    $firstNames: String!
    $lastNames: String!
    $dob: String!
    $gender: String!
    $email: String
    $phonenumber: String!
    $emailMarketingOptIn: Boolean
  ) {
    rsvp: rsvp(
      show_id: $show_id
      consent_box_data: $consent_box_data
      firstNames: $firstNames
      lastNames: $lastNames
      dob: $dob
      gender: $gender
      email: $email
      phonenumber: $phonenumber
      emailMarketingOptIn: $emailMarketingOptIn
    )
  }
`
