// @flow
import React from 'react'

import Page from '../Page'
import Button from '../Button'
import Card from '../Card'
import LoadingCards from '../LoadingCards'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Grid, {GridItem} from '../Grid'
import {gridItemWidths, uiLocations} from '../../common/constants'

import {brandIds} from '../../common/brands'
import type {GenericCollectionType, GenericItemType} from '../../common/types'

import styles from './SearchResults.less'
import {analytics} from '../../common/analytics'

type Props = {
  query: string,
  data: {
    loading: boolean,
    search_items: GenericCollectionType,
  },
  onSeeMore: Function,
  brandId: number,
}

export default class SearchResults extends React.Component {
  props: Props

  componentDidUpdate(props: Props) {
    analytics.track('search_complete', {user_search_query: props.query})
  }

  render() {
    const {data, onSeeMore, query, brandId} = this.props

    const loading = data && data.loading
    const search_items = data && data.search_items

    const searchResults = search_items && search_items.items
    const itemsCount = search_items && search_items.items_count

    return (
      <Page
        title={`Search results for ${query}`}
        allBrandPage={true}
        contentColumn={brandId === brandIds.fourthree}
        ssr={true}
      >
        <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
          <Grid className={styles.Header}>
            <GridItem width={12}>
              <h4>Search Results:</h4>

              <h1>{query}</h1>
            </GridItem>
          </Grid>

          <Results items={searchResults} itemsCount={itemsCount} loading={loading} loadMore={onSeeMore} />
        </BrandedStyleWrapper>
      </Page>
    )
  }
}

type ResultsProps = {
  items: Array<GenericItemType>,
  itemsCount: number,
  loading?: boolean,
  loadMore: () => void,
}

export const Results = ({items, itemsCount, loading, loadMore}: ResultsProps) => {
  if (loading && !items) {
    return (
      <Grid>
        <LoadingCards />
      </Grid>
    )
  }

  if (items && items.length > 0) {
    const hasMore = itemsCount > items.length
    const isLoadingMore = loading && items

    return (
      <Grid>
        {items.map(item => (
          <GridItem responsiveWidths={gridItemWidths} key={`result_${item.id}`}>
            <Card item={item} uiLocation={uiLocations.searchResultsPageCard} />
          </GridItem>
        ))}

        {isLoadingMore && <LoadingCards />}

        {hasMore &&
          <GridItem className={styles.LoadMore} width={12}>
            <Button onClick={loading ? () => null : loadMore} text='Load More' />
          </GridItem>
        }
      </Grid>
    )
  }

  return (
    <Grid>
      <GridItem width={12}>
        <h1>No results found</h1>
      </GridItem>
    </Grid>
  )
}
