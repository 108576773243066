// @flow

import type {BrandType, ProfileType} from './types'
import {consents} from './constants'
import getRequestHeaders from './getRequestHeaders'

// This function checks a users profile to see if they have given mailing
// consent
export const hasOptedInToMailingList = (profile: ProfileType, brand?: ?BrandType) => {
  if (!profile || !profile.consents) return false
  const brandTitle = (brand && brand.title) || consents.boilerRoomMailingList

  // Check for consent
  const consent = profile.consents.find(consent => consent.title.includes(brandTitle))
  if (consent && consent.given) return true

  return false
}

export const getUpdateMailingConsentArgs = (given: boolean, isFourThree: boolean, tags: Array<string> = [], uiLocation?: string) => ({
  variables: {
    consent: {
      title: isFourThree ? consents.fourThreeMailingList : consents.boilerRoomMailingList,
      given,
    },
    tags: isFourThree ? ['4:3 Mailing List', ...tags] : tags,
  },
  context: {
    headers: getRequestHeaders(uiLocation),
  },
})
