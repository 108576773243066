import React from 'react'

const HeadphoneIcon = () =>
  <svg width='13' height='13' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5V7.88889C9.5 8.77868 8.77868 9.5 7.88889 9.5C6.9991 9.5 6.27778 8.77868 6.27778 7.88889V7C6.27778 6.11021 6.9991 5.38889 7.88889 5.38889C8.1052 5.38889 8.31155 5.43152 8.5 5.50883V5C8.5 3.067 6.933 1.5 5 1.5C3.067 1.5 1.5 3.067 1.5 5V5.50883C1.68845 5.43152 1.8948 5.38889 2.11111 5.38889C3.0009 5.38889 3.72222 6.11021 3.72222 7V7.88889C3.72222 8.77868 3.0009 9.5 2.11111 9.5C1.22132 9.5 0.5 8.77868 0.5 7.88889V5ZM1.5 7.88889C1.5 8.2264 1.7736 8.5 2.11111 8.5C2.44862 8.5 2.72222 8.2264 2.72222 7.88889V7C2.72222 6.66249 2.44862 6.38889 2.11111 6.38889C1.7736 6.38889 1.5 6.66249 1.5 7V7.88889ZM8.5 7C8.5 6.66249 8.2264 6.38889 7.88889 6.38889C7.55138 6.38889 7.27778 6.66249 7.27778 7V7.88889C7.27778 8.2264 7.55138 8.5 7.88889 8.5C8.2264 8.5 8.5 8.2264 8.5 7.88889V7Z'
      fill='white'
      strokeWidth='1px'
      vectorEffect='non-scaling-stroke'
    />
  </svg>

export default HeadphoneIcon
