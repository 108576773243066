// @flow
import React from 'react'

type Direction = 'left' | 'right' | 'up' | 'down'

export default ({color, direction}: {color?: string, direction?: Direction}) => {
  const fill = color || '#fff'
  const dir = direction || 'left'

  if (dir === 'right') {
    return (
      <svg width='15' height='13' viewBox='0 0 15 13' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.5497 5.96967C14.8426 6.26256 14.8426 6.73744 14.5497 7.03033L9.77672 11.8033C9.48382 12.0962 9.00895 12.0962 8.71606 11.8033C8.42316 11.5104 8.42316 11.0355 8.71606 10.7426L12.9587 6.5L8.71606 2.25736C8.42316 1.96447 8.42316 1.48959 8.71606 1.1967C9.00895 0.903806 9.48382 0.903806 9.77672 1.1967L14.5497 5.96967ZM0.980957 5.75L14.0194 5.75V7.25L0.980957 7.25L0.980957 5.75Z'
          fill={fill}
        />
      </svg>
    )
  }

  if (dir === 'left') {
    return (
      <svg width='15' height='13' viewBox='0 0 15 13' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill={fill}
          d='M0.450557 7.03033C0.157663 6.73744 0.157663 6.26256 0.450557 5.96967L5.22353 1.1967C5.51642 0.903806 5.99129 0.903806 6.28419 1.1967C6.57708 1.48959 6.57708 1.96447 6.28419 2.25736L2.04155 6.5L6.28419 10.7426C6.57708 11.0355 6.57708 11.5104 6.28419 11.8033C5.99129 12.0962 5.51642 12.0962 5.22353 11.8033L0.450557 7.03033ZM14.0193 7.25L0.980886 7.25V5.75L14.0193 5.75V7.25Z'
        />
      </svg>
    )
  }

  if (dir === 'down') {
    return (
      <svg width='13' height='15' viewBox='0 0 13 15' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.03045 14.5494C6.73756 14.8423 6.26269 14.8423 5.96979 14.5494L1.19682 9.77647C0.903928 9.48358 0.903928 9.00871 1.19682 8.71581C1.48971 8.42292 1.96459 8.42292 2.25748 8.71581L6.50012 12.9585L10.7428 8.71581C11.0357 8.42292 11.5105 8.42292 11.8034 8.71581C12.0963 9.00871 12.0963 9.48358 11.8034 9.77647L7.03045 14.5494ZM7.25012 0.980713L7.25012 14.0191H5.75012L5.75012 0.980713L7.25012 0.980713Z'
          fill={fill}
        />
      </svg>
    )
  }

  if (dir === 'up') {
    return (
      <svg width='13' height='15' viewBox='0 0 13 15' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.96979 0.450312C6.26269 0.157419 6.73756 0.157419 7.03045 0.450312L11.8034 5.22328C12.0963 5.51618 12.0963 5.99105 11.8034 6.28394C11.5105 6.57684 11.0357 6.57684 10.7428 6.28394L6.50012 2.0413L2.25748 6.28394C1.96459 6.57684 1.48971 6.57684 1.19682 6.28394C0.903928 5.99105 0.903928 5.51618 1.19682 5.22328L5.96979 0.450312ZM5.75012 14.019L5.75012 0.980642H7.25012L7.25012 14.019H5.75012Z'
          fill={fill}
        />
      </svg>
    )
  }
}
