import React from 'react'

import type {RecordingType} from '../../common/types'

import {hasFutureYoutubePublishDate, publishedInLastNDays, isPremieringOnYoutube} from '../../common/youtubePublicationDate'

import styles from './RecordingStatus.less'

export default ({recording, className}: {recording: RecordingType, className: string}) => {
  if (!recording) return null

  const isUpcoming = hasFutureYoutubePublishDate(recording)
  const isNewRecording = publishedInLastNDays(recording, 2)
  const isLive = isPremieringOnYoutube(recording)

  if (isLive) return <div className={[styles.Live, className].join(' ')}>LIVE</div>

  if (isUpcoming) return <div className={[styles.Upcoming, className].join(' ')}>UPCOMING</div>

  if (isNewRecording) return <div className={[styles.New, className].join(' ')}>NEW</div>

  return null
}
