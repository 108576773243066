// @flow
import React from 'react'

export default class Logo extends React.PureComponent {
  props: {|
    className?: string,
    colour?: string,
    onClick?: Function,
    style?: Object,
  |}

  render() {
    const {className, colour, onClick, style} = this.props
    const fill = colour || '#FFF'

    return (
      <div className={className} onClick={onClick} style={style}>
        <svg width='302px' height='302px' viewBox='0 0 302 302' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='logoWhite' transform='translate(4.000000, 4.000000)'>
              <circle id='Oval' stroke={fill} strokeWidth='7' cx='147' cy='147' r='147' />
              <path
                d='M19.0763536,112.798373 L46.5169042,112.798373 C58.7083408,112.798373 63.8259763,116.472261 63.8259763,123.264606 C63.8259763,128.718867 59.7088652,131.276282 54.4229171,132.559198 L54.4229171,132.667666 C60.1006593,133.001485 65.5549199,135.730018 65.5549199,142.630832 C65.5549199,145.750224 64.386083,148.364678 62.6057106,149.867335 C59.9912562,152.09093 57.3721264,152.987662 48.693746,152.987662 L19.0763536,152.987662 L19.0763536,112.798373 Z M36.0001771,129.048011 L41.0112147,129.048011 C45.9081739,129.048011 47.468805,127.324678 47.468805,124.653185 C47.468805,121.816184 46.2429288,120.258358 40.9541755,120.258358 L36.0001771,120.258358 L36.0001771,129.048011 Z M36.0001771,145.859627 L41.0112147,145.859627 C46.8572695,145.859627 48.9658513,144.356035 48.9658513,141.018772 C48.9658513,137.678703 46.8572695,136.176047 41.0112147,136.176047 L36.0001771,136.176047 L36.0001771,145.859627 Z'
                id='Fill-1'
                fill={fill}
                fillRule='nonzero'
              />
              <path
                d='M116.967848,147.081763 C112.630061,151.259653 105.673143,154.04242 95.3163131,154.04242 C75.6695671,154.04242 68.4377393,144.910531 68.4377393,132.893017 C68.4377393,125.101083 71.5524559,119.481315 76.1754397,116.195481 C80.0129651,113.469753 84.9641582,111.743615 95.3163131,111.743615 C114.967734,111.743615 122.205173,120.869893 122.205173,132.893017 C122.205173,139.070087 120.310722,143.912812 116.967848,147.081763 Z M95.3163131,120.869893 C89.7526493,120.869893 85.6925774,124.653185 85.6925774,132.893017 C85.6925774,141.12724 89.7526493,144.910531 95.3163131,144.910531 C100.884652,144.910531 104.947529,141.12724 104.947529,132.893017 C104.947529,124.653185 100.884652,120.869893 95.3163131,120.869893 Z'
                id='Fill-2'
                fill={fill}
                fillRule='nonzero'
              />
              <polygon
                id='Fill-3'
                fill={fill}
                fillRule='nonzero'
                points='126.14275 112.798373 143.060028 112.798373 143.060028 152.987662 126.14275 152.987662'
              />
              <polyline
                id='Fill-4'
                fill={fill}
                fillRule='nonzero'
                points='148.177664 112.798373 165.095877 112.798373 165.095877 143.188133 183.62989 143.188133 183.62989 152.987662 148.177664 152.987662 148.177664 112.798373'
              />
              <polyline
                id='Fill-5'
                fill={fill}
                fillRule='nonzero'
                points='186.127461 112.798373 224.642975 112.798373 224.642975 121.261687 202.491178 121.261687 202.491178 128.661828 223.248787 128.661828 223.248787 137.124207 202.491178 137.124207 202.491178 144.524347 224.862717 144.524347 224.862717 152.987662 186.127461 152.987662 186.127461 112.798373'
              />
              <path
                d='M229.532454,112.798373 L258.139036,112.798373 C269.104597,112.798373 275.890396,114.969605 275.890396,123.876142 C275.890396,129.166765 272.222118,132.502158 267.210146,133.39328 L267.210146,133.947776 C271.10471,134.061854 274.336311,135.450433 275.004885,139.516115 L277.173312,152.987662 L260.256034,152.987662 L258.85904,144.027825 C258.083867,139.070087 257.361058,138.401512 251.572978,138.401512 L246.452537,138.401512 L246.452537,152.987662 L229.532454,152.987662 L229.532454,112.798373 Z M246.452537,130.613318 L252.907322,130.613318 C256.529781,130.613318 259.306938,129.49591 259.306938,125.712618 C259.306938,122.150004 256.749523,120.926933 252.626801,120.926933 L246.452537,120.926933 L246.452537,130.613318 Z'
                id='Fill-6'
                fill={fill}
                fillRule='nonzero'
              />
              <path
                d='M31.4641548,156.379159 L60.0679319,156.379159 C71.0362975,156.379159 77.8220971,158.55039 77.8220971,167.456928 C77.8220971,172.745681 74.1538193,176.082944 69.1390414,176.97126 L69.1390414,177.534172 C73.0364112,177.64264 76.2652064,179.036829 76.9337811,183.096901 L79.0994021,196.565642 L62.1821241,196.565642 L60.7935458,187.605806 C60.0108926,182.653678 59.2927592,181.985103 53.4990684,181.985103 L48.3814328,181.985103 L48.3814328,196.565642 L31.4641548,196.565642 L31.4641548,156.379159 Z M48.3814328,174.194104 L54.839023,174.194104 C58.455872,174.194104 61.2358337,173.076696 61.2358337,169.293404 C61.2358337,165.730789 58.6765484,164.505848 54.5641126,164.505848 L48.3814328,164.505848 L48.3814328,174.194104 Z'
                id='Fill-7'
                fill={fill}
                fillRule='nonzero'
              />
              <path
                d='M128.795543,190.668159 C124.452145,194.837634 117.498033,197.620401 107.143073,197.620401 C87.4972616,197.620401 80.2598234,188.496927 80.2598234,176.473803 C80.2598234,168.679999 83.3773452,163.059296 87.9975238,159.776267 C91.8350492,157.047734 96.7862424,155.32253 107.143073,155.32253 C126.792624,155.32253 134.027257,164.453484 134.027257,176.473803 C134.027257,182.653678 132.130001,187.491728 128.795543,190.668159 Z M107.143073,164.453484 C101.574733,164.453484 97.5174667,168.236776 97.5174667,176.473803 C97.5174667,184.713636 101.574733,188.496927 107.143073,188.496927 C112.706736,188.496927 116.769613,184.713636 116.769613,176.473803 C116.769613,168.236776 112.706736,164.453484 107.143073,164.453484 Z'
                id='Fill-8'
                fill={fill}
                fillRule='nonzero'
              />
              <path
                d='M185.659926,190.668159 C181.320269,194.837634 174.363351,197.620401 164.016807,197.620401 C144.365386,197.620401 137.130753,188.496927 137.130753,176.473803 C137.130753,168.679999 140.247339,163.059296 144.870323,159.776267 C148.705043,157.047734 153.661847,155.32253 164.016807,155.32253 C183.665423,155.32253 190.894446,164.453484 190.894446,176.473803 C190.894446,182.653678 189.005605,187.491728 185.659926,190.668159 Z M164.016807,164.453484 C158.448468,164.453484 154.387461,168.236776 154.387461,176.473803 C154.387461,184.713636 158.448468,188.496927 164.016807,188.496927 C169.57673,188.496927 173.642413,184.713636 173.642413,176.473803 C173.642413,168.236776 169.57673,164.453484 164.016807,164.453484 Z'
                id='Fill-9'
                fill={fill}
                fillRule='nonzero'
              />
              <polyline
                id='Fill-10'
                fill={fill}
                fillRule='nonzero'
                points='194.669321 156.379159 220.327629 156.379159 228.730164 183.319447 228.839567 183.319447 236.968127 156.379159 262.739578 156.379159 262.739578 196.565642 247.042566 196.565642 247.042566 166.342325 246.934098 166.342325 235.913368 196.565642 219.544976 196.565642 209.192821 166.342325 209.030119 166.342325 209.030119 196.565642 194.669321 196.565642 194.669321 156.379159'
              />
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
