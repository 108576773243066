import {hasWindow} from '../common/constants'

// returns an object with dynamically set headers i.e x-boiler-room-item-id and static ones i.e x-boiler-room-origin-application
export default (uiLocation, itemId, itemType) => ({
  'x-boiler-room-origin-application': 'web',
  ...(hasWindow && {'x-boiler-room-url': window.location.href}),
  ...(uiLocation && {'x-boiler-room-ui-location': uiLocation}),
  ...(itemId && {'x-boiler-room-item-id': itemId}),
  ...(itemType && {'x-boiler-room-item-type': itemType}),
})
