// @flow
import React from 'react'
import marked from 'marked'

import styles from './Checkbox.less'
import Checkmark from '../Svgs/Checkmark'
import LoadingSpinner from '../Svgs/LoadingSpinner'

type CheckboxProps = {
  checked?: boolean | string,
  className?: string,
  color?: string,
  highlightRequired?: boolean,
  id: string | number,
  loading?: boolean,
  loadingColour?: string,
  name?: string | number,
  onChange?: Function,
  required?: boolean,
  text: string,
  textStyle?: string,
}

export default ({
  checked,
  className,
  color,
  highlightRequired,
  id,
  loading,
  loadingColour,
  name,
  onChange,
  required,
  text,
  textStyle,
}: CheckboxProps) => {
  const markedText = text && marked(text)
  const showRequired = required && highlightRequired && !checked
  const checkboxClassname = showRequired ? styles.InvalidFormField : ''

  const handleKeyPress = e => {
    e.preventDefault()
    if (e.charCode === 13 && onChange) return onChange()
  }

  return (
    <label className={[className, styles.Label].join(' ')} htmlFor={id} style={{opacity: loading ? 0.6 : 1}}>
      <label className={styles.Checkbox}>
        <input
          type='checkbox'
          checked={checked}
          id={id}
          name={name}
          onChange={onChange}
          required={required || null}
          onKeyPress={e => handleKeyPress(e)}
        />
        <span className={checkboxClassname}>
          {loading ? <LoadingSpinner color={loadingColour} /> : <Checkmark color={color} />}
        </span>
      </label>
      <div className={[styles.Text, textStyle].join(' ')}>
        <span dangerouslySetInnerHTML={{__html: markedText}} />
        {required && <Required checked={checked} highlightRequired={highlightRequired} />}
      </div>
    </label>
  )
}

const Required = ({checked, highlightRequired}) =>
  <span className={(!checked && highlightRequired) ? styles.HighlightedRequiredText : styles.RequiredText}> Required</span>
