import React from 'react'

import Button from '../Button'

const CloseIcon = ({className, onClick, fill}: {className?: String, onClick?: any, fill?: String}) => {
  const customFill = fill || '#fff'
  const buttonContent =
    <svg width='15px' height='14px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' stroke='none' strokeWidth='1'>
        <g id='br_home_small_menu' transform='translate(-293.000000, -17.000000)' fill={customFill}>
          <g id='close' transform='translate(293.000000, 17.000000)'>
            <rect
              id='Rectangle-1'
              transform='translate(7.500000, 7.500000) rotate(-315.000000) translate(-7.500000, -7.500000) '
              x='-1'
              y='6'
              width='17'
              height='3'
            />
            <rect
              id='Rectangle-1-Copy'
              transform='translate(7.500000, 7.500000) rotate(-45.000000) translate(-7.500000, -7.500000) '
              x='-1'
              y='6'
              width='17'
              height='3'
            />
          </g>
        </g>
      </g>
    </svg>
  return <Button onClick={onClick} className={className} disableDefaultStyles={true} text={buttonContent} />
}

export default CloseIcon
