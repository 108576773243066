// @flow
import React from 'react'
import Cookies from 'js-cookie'

import {hasWindow, uiLocations} from '../../common/constants'

import Button from '../Button'
import SubscribeFooter from '../SubscribeFooter'

import styles from './SubscribeOverlay.less'

type Props = {isFourThree?: boolean}

export default class SubscribeOverlay extends React.Component {
  state: {
    isVisible: boolean,
  }

  timeout: number
  props: Props

  constructor() {
    super()
    this.state = {
      isVisible: false,
    }

    if (hasWindow && !Cookies.get('SubscribeOverlayDismissed') && Cookies.get('_iub_cs-40994308')) {
      this.startRevealTimer()
    }
  }

  startRevealTimer() {
    // Reveal overlay after 10 seconds
    this.timeout = setTimeout(() => {
      Cookies.set('SubscribeOverlayDismissed', 'true')
      document.addEventListener('keydown', this.escapeClose, false)
      this.setState({isVisible: true})
    }, 10 * 1000)
  }

  componentWillUnmount = () => {
    clearInterval(this.timeout)
    document.removeEventListener('keydown', this.escapeClose, false)
  }

  close = () => this.setState({isVisible: false})

  escapeClose = (event: any) => event.key === 'Escape' && this.close()

  render() {
    const {isFourThree} = this.props
    return this.state.isVisible ?
      <div className={styles.SubscribeOverlay}>
        <div className={styles.CloseArea} onClick={this.close} />

        <div className={isFourThree ? styles.MailingList_fourthree : styles.MailingList}>
          <SubscribeFooter isFourThree={isFourThree} uiLocation={uiLocations.popUp} />

          <Button disableDefaultStyles={true} className={styles.NoThanks} onClick={this.close} text='No Thanks' />
        </div>
      </div>
      : null
  }
}
