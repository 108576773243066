import {gql} from '@apollo/client'

export default gql`
  query CitiesQuery {
    cities {
      name
      shows_count
      recordings_count
      artists_count
      slug
    }
  }
`
