// @flow
import React from 'react'

import type {SponsorType, HomepageThemeType} from '../../common/types'
import imageUrl from '../../common/imageUrl'
import Sponsor from '../Sponsor'

import Grid, {GridItem} from '../Grid'
import Button from '../Button'

import styles from './HomepageFooter.less'

export default class HomepageFooter extends React.Component {
  props: {
    theme: HomepageThemeType,
    sponsor?: ?SponsorType,
  }

  render() {
    const {sponsor, theme} = this.props
    const {
      footer_title,
      footer_description,
      footer_cta_text,
      footer_cta_link,
      footer_background_image,
      footer_background_color,
    } = theme

    return (
      <Grid className={styles.Footer} style={getBackgroundStyles(footer_background_image, footer_background_color)}>
        <GridItem
          responsiveWidths={{
            small: 12,
            medium: 6,
            large: 6,
            extraLarge: 4,
          }}
        >
          {footer_title && <h2>{footer_title}</h2>}
          {footer_description && <p>{footer_description}</p>}
          {footer_cta_text && footer_cta_link && <Button text={footer_cta_text} href={footer_cta_link} />}
          {sponsor && <Sponsor sponsor={sponsor} className={styles.Sponsor} />}
        </GridItem>
      </Grid>
    )
  }
}

const getBackgroundStyles = (image, solidColor) => {
  if (image) {
    return {
      backgroundColor: solidColor,
      backgroundImage: `url(${imageUrl(image, 1200, 80)})`,
    }
  }

  if (solidColor) {
    return {
      backgroundColor: solidColor,
    }
  }

  return {}
}
