// @flow
import React from 'react'
import {Route} from 'react-router-dom'
import {captureMessage} from '@sentry/browser'

import type {StaticContext} from '../../common/types'
import {hasWindow} from '../../common/constants'

import Page from '../Page/'
import Button from '../Button/'
import BrandedStyleWrapper from '../BrandedStyleWrapper/'

import styles from './NotFound.less'

export default class NotFound extends React.Component {
  componentDidMount() {
    captureMessage('404 - Page Not Found')
  }

  renderPage(context: {staticContext?: StaticContext}) {
    if (context.staticContext) {
      context.staticContext.statusCode = 404
    }

    return (
      <Page title='404 Page Not Found' disableHeaderOffset={true} requiresLogin={false} allBrandPage={true}>
        <BrandedStyleWrapper styles={styles} classNameKey='NotFound'>
          <h2>Page Not Found</h2>
          <Button text={hasWindow ? `Back to ${window.location.hostname}` : ''} href='/' className={styles.Button} />
        </BrandedStyleWrapper>
      </Page>
    )
  }

  render() {
    return <Route render={this.renderPage} />
  }
}
