import {connect} from 'react-redux'
import type {State} from '../../stateType'

import {actions} from '../Auth/state'

import Component from './Component'

const mapStateToProps = ({auth, brand}: State) => ({
  loading: auth.loading,
  shouldRedirect: auth.isLoggedIn,
  brand: brand,
})

const mapDispatchToProps = {...actions}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
