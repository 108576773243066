// @flow
import {stringify} from 'query-string'

import type {RecordingType} from './types'

export default (recording: RecordingType) => {
  const {hls_url, youtube_video_id, vimeo_video_id, embed_override_url} = recording

  // Embed URLs
  if (embed_override_url) {
    return embed_override_url
  }

  // YouTube
  if (youtube_video_id) {
    const params = stringify({
      autohide: 1,
      autoplay: 1,
      enablejsapi: 1,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
      theme: 'dark',
      html5: 1,
    })

    return `//www.youtube.com/embed/${youtube_video_id}?${params}`
  }

  // Vimeo
  if (vimeo_video_id) {
    const params = stringify({
      autoplay: 0,
      title: 0,
    })

    return `https://player.vimeo.com/video/${vimeo_video_id}?${params}`
  }

  // JW Player
  if (hls_url) {
    const hlsId = hls_url.split('/').pop().replace('.m3u8', '')
    return `//content.jwplatform.com/players/${hlsId}-0GfGFmQj.html`
  }

  console.warn('There is no stream for this video')
  return null
}
