// @flow
import React from 'react'
import {Mutation} from '@apollo/client/react/components'

type Props = {
  Component: any,
  mutationName: string,
  mutationSchema: string,
  props?: Object,
  context?: any,
}

export default ({Component, mutationSchema, mutationName, props, context}: Props) => (
  <Mutation mutation={mutationSchema} context={context}>{mutation => <Component {...props} {...{[mutationName]: mutation}} />}</Mutation>
)
