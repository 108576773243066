// @flow
import React from 'react'
import {useQuery} from '@apollo/client'

import {signupSources} from '../../common/constants'

import Signup from '../Signup'

import SIGNUP_BY_SLUG from '../SignupPage/Query'

import styles from './SubscribeFooter.less'

export default ({isFourThree, uiLocation}: {isFourThree?: boolean, uiLocation?: string}) => {
  const {data, loading} = useQuery(SIGNUP_BY_SLUG, {
    variables: {
      slug: isFourThree ? 'fourthree-newsletter' : 'newsletter',
    },
    onError: () => console.error('There was an error fetching the footer signup.'),
  })

  if (loading) return null

  return (
    <Signup
      className={isFourThree ? styles.SubscribeFooter_fourthree : styles.SubscribeFooter}
      signup={data && data.signup_by_slug}
      signupSource={signupSources.newsletterSubscribe}
      uiLocation={uiLocation}
    />
  )
}
