// @flow
import React from 'react'
import {useQuery} from '@apollo/client'
import moment from 'moment-timezone'

import type {MatchType} from '../../common/types'
import getScheduleRedirectUrl from '../../common/getScheduleRedirectUrl'
import {itemize} from '../../common/itemize'
import {genericItemTypes, uiLocations} from '../../common/constants'
import getLocalTimezone from '../../common/getLocalTimezone'

import Page from '../Page'
import Share from '../Share'
import ArrowBold from '../Svgs/ArrowBold'
import Loading from '../Loading'
import VideoScheduleNav from '../VideoScheduleNav'
import Link from '../Link'
import Card from '../Card'

import GET_RECORDINGS_BY_DATE from './Query'

import styles from './Schedule.less'

export default ({match}: {match: MatchType}) => {
  const {slug} = match.params

  const isUpcoming = match.path && match.path.includes('upcoming')

  // set this weeks date range to use as default state starting from Monday
  const activeWeek = moment(slug).startOf('isoWeek')
  const nextWeek = moment(activeWeek).add(7, 'days')

  const {data, loading} = useQuery(GET_RECORDINGS_BY_DATE, {
    variables: {
      yt_published_from: isUpcoming ? nextWeek : activeWeek,
      yt_published_to: isUpcoming ? null : nextWeek,
    },
  })

  const recordings = data && data.recordings && data.recordings.recordings

  // create an array of unique youtube_publish_dates to be used for YoutubePublishDateSubHeader - formatted with the year included for equality comparison
  // with the recordings array as well as to be able correctly format the subheadings using moment.js
  const recordingPublishDates = recordings && [...new Set(data.recordings.recordings.map(recording =>
    recording.youtube_publish_date && moment(recording.youtube_publish_date).format('YYYY-MM-DD')))]

  const pageTitle = isUpcoming
    ? 'Upcoming Broadcasts'
    : `${activeWeek.format('DD-MM-YYYY')} to ${moment(activeWeek).add(6, 'days').format('DD-MM-YYYY')} Schedule`

  return (
    <Page
      title={pageTitle}
      description='Broadcast Schedule'
      redirectUrl={getScheduleRedirectUrl(slug)}
      subNavigation={<VideoScheduleNav />}
      ssr
    >
      <div className={styles.Wrapper}>
        <div className={styles.HeaderWrapper}>
          <Link className={styles.ScheduleHeader} internalLink={'/schedule'}>
            <h3>BROADCAST SCHEDULE</h3>
          </Link>
          <div className={styles.PlayAndShareButtonWrapper}>
            <Share className={styles.ShareLink} />
          </div>
        </div>

        <ScheduleNavigation activeWeek={activeWeek} slug={slug} isUpcoming={isUpcoming} />

        <Schedule
          recordingPublishDates={recordingPublishDates}
          recordings={recordings}
          loading={loading}
        />
      </div>
    </Page>
  )
}

const ScheduleNavigation = ({slug, isUpcoming, activeWeek}) => {
  const thisWeekIsActive = slug ?
    moment(slug).format('YYYY-MM-DD') === moment().startOf('isoWeek').format('YYYY-MM-DD') : !isUpcoming

  const previousWeekLink = isUpcoming ? '/schedule' : `/schedule/${moment(activeWeek).subtract(7, 'days').format('YYYY-MM-DD')}`
  const activeWeekLink = isUpcoming && '/schedule'
  const nextWeekLink = () => {
    if (isUpcoming) return
    return thisWeekIsActive ? '/schedule/upcoming' : `/schedule/${moment(activeWeek).add(7, 'days').format('YYYY-MM-DD')}`
  }

  const previousTabTitle =
    <span>{moment(activeWeek).subtract(7, 'days').format('DD/MM')} - {moment(activeWeek).subtract(1, 'days').format('DD/MM')}</span>

  const selectedTabTitle =
    <span>{moment(activeWeek).format('DD/MM')} - {moment(activeWeek).add(6, 'd').format('DD/MM')}</span>

  const nextTabTitle = thisWeekIsActive || isUpcoming
    ? 'UPCOMING'
    : <span>{moment(activeWeek).add(7, 'd').format('DD/MM')} - {moment(activeWeek).add(13, 'd').format('DD/MM')}</span>

  return (
    <div className={styles.DateSelectorWrapper}>
      <Link className={styles.LeftArrow} internalLink={previousWeekLink}>
        <ArrowBold />
      </Link>
      <Link className={styles.PreviousWeekTab} internalLink={previousWeekLink}>
        {previousTabTitle}
      </Link>
      <Link className={isUpcoming ? styles.SelectedWeekTab : styles.ActiveTab} internalLink={activeWeekLink}>
        {selectedTabTitle}
      </Link>
      <Link className={isUpcoming ? styles.UpcomingActiveTab : styles.NextWeekAndUpcomingTab} internalLink={nextWeekLink()}>
        {nextTabTitle}
      </Link>
      <Link className={isUpcoming ? styles.DimmedArrow : styles.RightArrow} internalLink={nextWeekLink()}>
        <ArrowBold direction='right' />
      </Link>
    </div>
  )
}

const Schedule = ({recordingPublishDates, recordings, loading}) => {
  if (loading) {
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
      </div>
    )
  }

  if (recordingPublishDates.length > 0) {
    return (
      recordingPublishDates.map(date =>
        <ScheduleBlock
          date={date}
          recordings={recordings}
          key={date}
        />
      )
    )
  }

  return (
    <span className={styles.EmptyStateMessage}>
      THIS WEEK'S SCHEDULE IS EMPTY
    </span>
  )
}

const ScheduleBlock = ({recordingPublishDates, recordings, date}) => {
  if (!date) { // unscheduled upcoming
    return <Recordings recordings={recordings.filter(recording => !recording.youtube_publish_date)} />
  }

  return (
    <React.Fragment>
      <div className={styles.ScheduleBlockTitle}>
        {moment(date).format('dddd D MMMM')}
      </div>
      <Recordings
        recordings={
          recordings.filter(recording =>
            moment(recording.youtube_publish_date).format('YYYY-MM-DD') === date
          )
        }
      />
    </React.Fragment>
  )
}

const getPublishTime = recording => {
  const clientTimezone = getLocalTimezone()
  if (recording.youtube_publish_date) {
    const timezoneAbbr = moment.tz(clientTimezone).zoneAbbr()
    const localTimeCalendarFormat = moment.tz(recording.youtube_publish_date, clientTimezone).format('HH:mm')
    return `${localTimeCalendarFormat} ${timezoneAbbr}`
  }
  return 'TBA'
}

const Recordings = ({recordings}) =>
  recordings.map(recording =>
    <div key={'recording_' + recording.id} className={styles.RecordingWrapper}>
      <div className={styles.ReleaseTime}>
        {getPublishTime(recording)}
      </div>
      <Card
        item={itemize(recording, genericItemTypes.recording)}
        key={recording.id}
        uiLocation={uiLocations.schedulePageCard}
      />
    </div>
  )
