// @flow
import rudder from './rudder'

import {hasWindow} from './constants'

type CartData = {
  product_title: string,
  product_id: string,
  product_value: number
}

const fbEvents = ['Lead', 'PageView', 'InitiateCheckout']

class Analytics {
  track(event_name: string, event_data: any, destinations?: Array<string>) {
    const sendToFacebook = destinations && destinations.includes('fb')
    const sendToTiktok = destinations && destinations.includes('tiktok')
    const sendToGoogle = destinations && destinations.includes('ga')
    const sendToRudder = destinations && destinations.includes('rudder')

    // send to facebook if we don't pass optional destinations or if the destinations array includes facebook AND only if FB pixel exists on the window
    if ((!destinations || sendToFacebook) && hasWindow && window.fbq) {
      // use the fb pixel track function for non-custom events
      if (fbEvents.includes(event_name)) {
        window.fbq('track', event_name)
      } else {
        window.fbq('trackCustom', event_name, event_data)
      }
    }

    // send to tiktok if we don't pass optional destinations or if the destinations array includes tiktok AND only if tiktok pixel exists on the window
    if ((!destinations || sendToTiktok) && hasWindow && window.ttq) {
      window.ttq.track(event_name, event_data)
    }

    // send to google if we don't pass optional destinations or if the destinations array includes google AND only if google gtag exists on the window
    if ((!destinations || sendToGoogle) && hasWindow && window.gtag) {
      window.gtag('event', event_name, event_data)
    }

    // send to rudderstack if we don't pass optional destinations or if the destinations array includes rudderstack
    if (!destinations || sendToRudder) {
      rudder.trackEvent(event_name, event_data)
    }
  }

  addToCart(event_data: CartData) {
    if (hasWindow && window.fbq) {
      window.fbq('track', 'AddToCart', {
        content_name: event_data.product_title,
        content_ids: [event_data.product_id],
        content_type: 'product',
        value: event_data.product_value,
        currency: 'GBP',
      })
    }

    if (hasWindow && window.ttq) {
      window.ttq.track('AddToCart', {
        content_name: event_data.product_title,
        content_id: event_data.product_id,
        content_type: 'product',
        quantity: 1,
        value: event_data.product_value,
        currency: 'GBP',
      })
    }

    if (hasWindow && window.gtag) {
      window.gtag('event', 'AddToCart', {
        currency: 'GBP',
        value: event_data.product_value,
        items: [
          {
            item_id: event_data.product_id,
            item_name: event_data.product_title,
            price: event_data.product_value,
          },
        ],
      })
    }
  }

  trackConversion(send_to: string) {
    if (hasWindow && window.gtag) {
      window.gtag('event', 'conversion', {send_to})
    }
  }

  identify(uuid: string) {
    rudder.identifyUser(uuid)
  }
}

export const analytics = new Analytics()
