// @flow
import React from 'react'
import styles from './Search.less'
import SearchIcon from '../Svgs/SearchIcon'

type Props = {
  value: string,
  onChange: (event: any) => void,
  placeholder: ?string,
}
const Search = ({value, onChange, placeholder}: Props) => {
  return (
    <div className={styles.Wrapper}>
      <input
        placeholder={placeholder}
        className={styles.Input}
        value={value}
        onChange={onChange}
      />
      <div className={styles.ImageWrapper}>
        <SearchIcon />
      </div>
    </div>
  )
}

export default Search
