// @flow
import {currencyCodes, currencySymbols, shopCurrencies} from './constants'
import type {ShopifyProductType, ShopifyCheckoutType} from './types'

const parseCurrency = (code?: string) => {
  if (code === currencyCodes.usd) return currencySymbols.usd
  if (code === currencyCodes.eur) return currencySymbols.eur
  if (code === currencyCodes.aud) return currencySymbols.aud
  if (code === currencyCodes.jpy) return currencySymbols.jpy

  return currencySymbols.gbp
}

export const parsePrice = (price: string | number, currencyCode?: string) => {
  if (price) {
    const p = Number(price)
    const isInt = Number(price) === p && p % 1 === 0
    const isFloat = Number(price) === p && p % 1 !== 0
    const currency = parseCurrency(currencyCode)

    if (isInt) return `${currency}${p}`
    else if (isFloat) return `${currency}${p.toFixed(2)}`
    else return null
  } else return null
}

export const parseDiscount = (price: string | number, compareAtPrice: string | number) => {
  const difference = compareAtPrice && Number(compareAtPrice) > Number(price) && Number(compareAtPrice) - Number(price)

  if (difference) {
    const discountPercent = (difference / Number(compareAtPrice)) * 100
    return `${discountPercent.toFixed(0)}%`
  } else return null
}

export const getPrices = (shopifyProduct: ShopifyProductType) => {
  const minPrice = parsePrice(shopifyProduct.priceRange.minVariantPrice.amount, shopifyProduct.priceRange.minVariantPrice.currencyCode)
  const maxPrice = parsePrice(shopifyProduct.priceRange.maxVariantPrice.amount, shopifyProduct.priceRange.maxVariantPrice.currencyCode)

  const hasMultiplePrices = minPrice !== maxPrice

  const compareAtPrice =
    shopifyProduct.variants &&
    shopifyProduct.variants.edges[0] &&
    shopifyProduct.variants.edges[0].node.compareAtPrice &&
    parsePrice(shopifyProduct.variants.edges[0].node.compareAtPrice.amount, shopifyProduct.priceRange.minVariantPrice.currencyCode)

  const currencySymbol = shopCurrencies.find(entry => entry.code === shopifyProduct.priceRange.minVariantPrice.currencyCode)

  const discountPercent =
    minPrice && compareAtPrice && currencySymbol && parseDiscount(minPrice.replace(currencySymbol.symbol, ''), compareAtPrice.replace(currencySymbol.symbol, ''))

  return {
    price: hasMultiplePrices && minPrice && maxPrice ? `${minPrice} to ${maxPrice}` : minPrice,
    compareAtPrice: compareAtPrice || null,
    discountPercent: discountPercent || null,
  }
}

export const emptyBasket = (basket: ShopifyCheckoutType) => basket.lineItems.edges.length === 0
