import {gql} from '@apollo/client'

export default gql`
  mutation UpdateMailingConsentMutation(
    $consent: ConsentDataInput
    $email: String
    $phone_number: String
    $tags: [String]
    $favourite_item_id: ID
    $favourite_item_type: String
    $signup_id: Int
  ) {
    update_mailing_consent(
      consent: $consent
      tags: $tags
      email: $email
      phone_number: $phone_number
      favourite_item_id: $favourite_item_id
      favourite_item_type: $favourite_item_type
      signup_id: $signup_id
    )
  }
`
