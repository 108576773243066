import {gql} from '@apollo/client'

export default gql`
  query ShopNavigationQuery($slug: String!) {
    navigation: navigation_by_slug(slug: $slug) {
      navigation_elements {
        name
        link
      }
    }
  }
`
