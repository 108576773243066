// @flow
import React from 'react'
import Cookies from 'js-cookie'

import type {MatchType, StaticContext} from '../../common/types'

import getParameterByName from '../../common/getParameterByName'

import Homepage from '../Homepage'
import Redirect from '../Redirect'
import SearchResults from '../SearchResults'

type Props = {
  location: {
    pathname: string,
    search: string,
  },

  staticContext: StaticContext,
  match: MatchType,

  startRedirect: () => void,
  isLoggedIn: boolean,
}

export default (props: Props) => {
  // Redirect to correct page after logging in
  const redirectURL = Cookies.get('redirectURL')
  if (redirectURL && props.isLoggedIn) {
    Cookies.remove('redirectURL')
    props.startRedirect()
    return <Redirect to={redirectURL} />
  }

  // Search Results
  const query = getParameterByName('s', props.location.search)
  if (query) {
    return <SearchResults query={query} />
  }

  return <Homepage {...props} />
}
