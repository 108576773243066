// @flow
import React from 'react'
import {Motion, spring} from 'react-motion'
import isMobile from 'is-mobile'

import type {HomepageType} from '../../common/types'
import {hasWindow} from '../../common/constants'
import {lowHeatLogo, brandIds} from '../../common/brands'

import Page from '../Page'
import Background from '../Background'
import Link from '../Link'

import styles from './LowHeat.less'

const backgroundImage = 'https://cdn.boilerroom.tv/wp-content/uploads/2018/08/Lowheat-background.png'
const backgroundVideo =
  'https://player.vimeo.com/external/318802713.sd.mp4?s=2fd1aae3dbd7ed5406dd30637e135e05adf7c99a&profile_id=165'

type Props = {
  homepage: HomepageType,
}

export default class Scroller extends React.Component {
  props: Props

  state: {
    mouseX: number,
    mouseY: number,
  }

  constructor(props: Props) {
    super(props)
    let yMultiplier, xValue, yValue
    if (hasWindow) {
      yMultiplier = window.outerWidth > window.outerHeight ? 1.1 : 0.5
      xValue = -1 * window.outerWidth
      yValue = -1 * (window.innerHeight * yMultiplier)
    }

    this.state = {
      mouseX: isMobile() ? 50 : xValue || 0,
      mouseY: isMobile() ? 200 : yValue || 0,
    }
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.updateCoordinates)
  }

  updateCoordinates = (e: any) => {
    if (!isMobile()) {
      this.setState({
        mouseX: -((e.clientX / window.innerWidth) * window.innerWidth) * 2,
        mouseY: -((e.clientY / window.innerHeight) * window.innerHeight) * 3,
      })
    }
  }

  render() {
    const collections = this.props.homepage.collections

    const motionStyles = {
      left: spring(this.state.mouseX, {damping: 40, stiffness: 100}),
      top: spring(this.state.mouseY, {damping: 40, stiffness: 100}),
    }

    return (
      <Page hideFooter={true} brandId={brandIds.lowheat}>
        <Background image={backgroundImage} video={backgroundVideo} playing={true} />
        <div className={styles.Scroller} style={{overflow: isMobile() ? 'visible' : 'hidden'}}>
          <Motion style={motionStyles}>
            {animated => (
              <div
                className={styles.Contents}
                style={{
                  transform: `translate(${animated.left}px, ${animated.top}px)`,
                  position: isMobile() ? 'relative' : 'absolute',
                  left: isMobile() ? 0 : '50vw',
                  top: isMobile() ? 0 : '50vw',
                }}
              >
                <img src={lowHeatLogo} className={styles.Logo} />
                {collections.map((collection, index) => (
                  <Collection collection={collection} index={index} key={collection.title} />
                ))}
              </div>
            )}
          </Motion>
        </div>
      </Page>
    )
  }
}

// the item limit for each playlist
const limits = [1, 4, 10, 10]

const Collection = ({collection, index}) => (
  <div className={styles.Collection}>
    {collection.items.slice(0, limits[index] || 0).map(item => (
      <Item key={item.title} item={item} index={index} />
    ))}
  </div>
)

const Item = ({item, index}) => (
  <Link className={styles.Item} item={item}>
    <Background
      image={item.thumbnail_image || item.background_image}
      playing={true}
      video={index < 3 ? item.trailer_video : null}
      className={styles.ItemImage}
    />
    <h1>{item.title}</h1>
  </Link>
)
