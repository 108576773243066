// @flow
// $FlowFixMe not sure why this import breaks flow
import React, {useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {useStripe, useElements, CardElement, Elements} from '@stripe/react-stripe-js'
import marked from 'marked'

import type {DigitalProductType} from '../../common/types'
import {hasWindow} from '../../common/constants'

import Padlock from '../Svgs/Padlock'
import tick from '../../images/tick.svg'

import Form from '../Form'
import Input from '../Input'
import Button from '../Button'
import Spinner from '../Spinner'

import styles from './Stripe.less'

const stripeStyles = {
  style: {
    base: {
      color: 'black',
      fontSize: '16px',
      paddingTop: '5px',
      paddingBottom: '5px',
      fontFamily: 'Helvetica',
    },
  },
}

const Payment = ({digitalProduct, closeCheckout}) => {
  if (!digitalProduct.stripe_client_secret) {
    console.error('stripe_client_secret not supplied')
    return null
  }

  const [isComplete, setComplete] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [fullName, setFullName] = useState('')
  const onChangeFullName = e => setFullName(e.target.value)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) return

    setLoading(true)
    const result = await stripe.confirmCardPayment(digitalProduct.stripe_client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: fullName,
        },
      },
    })
    setLoading(false)

    if (result.error) {
      setError(result.error.message)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setComplete(true)
      }
    }
  }

  if (isComplete) {
    return (
      <div className={styles.Stripe}>
        <div className={styles.Content}>
          <div className={styles.Success}>
            <img src={tick} className={styles.Tick} />

            <p>Thanks for your purchase.</p>

            <Button type='button' onClick={() => window.location.reload()} text='Back to page' className={styles.CTA} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.Stripe}>
      <div className={styles.CloseArea} onClick={closeCheckout} />
      <div className={styles.Content}>
        {isLoading &&
          <div className={styles.LoadingOverlay}>
            <Spinner className={styles.Spinner} loading={true} />
          </div>
        }

        <Padlock className={styles.Padlock} />

        <p>
          You are purchasing <b>{digitalProduct.title}</b>.
        </p>

        {error && <p className={styles.Error}>{error}</p>}

        <Form onSubmit={handleSubmit} className={styles.FormContainer}>
          <Input
            id='name'
            type='text'
            placeholder='Name on card'
            value={fullName}
            onChange={onChangeFullName}
            className={styles.NameOnCard}
          />

          <label>
            <CardElement className={styles.CardElement} options={stripeStyles} />
          </label>

          <Button
            type='submit'
            text={`Purchase for £${digitalProduct.price / 100}`}
            disabled={!stripe}
            className={styles.CTA}
          />

          {digitalProduct.help_text &&
            <div className={styles.HelpText} dangerouslySetInnerHTML={{__html: marked(digitalProduct.help_text)}} />
          }
        </Form>
      </div>
    </div>
  )
}

export default ({digitalProduct, closeCheckout}: {digitalProduct: ?DigitalProductType, closeCheckout: Function}) =>
  hasWindow && digitalProduct && !digitalProduct.has_purchased ?
    <Elements stripe={loadStripe(process.env.STRIPE_KEY || 'pk_test_vGdbaW45PyDzjQ2szekT6OAi009ripfoTz')}>
      <Payment digitalProduct={digitalProduct} closeCheckout={closeCheckout} />
    </Elements> :
    null
