import React from 'react'

import paths from '../../common/paths'
import {recordingHasAudio} from '../../common/audioVideo'

import Link from '../Link'
import HeadphoneIcon from '../Svgs/HeadphoneIcon'

import styles from './AudioAvailableLink.less'

export default ({item, isMini}) => {
  if (!item.recording) return null

  const audioIsAvailable = recordingHasAudio(item.recording)
  if (!audioIsAvailable) return null

  return (
    <div className={`${styles.AudioAvailableLinkWrapper} ${isMini && styles.MiniAudioAvailableLinkWrapper}`}>
      <Link className={styles.Text} internalLink={paths.recording(item, true)}>
        <HeadphoneIcon />
        Audio Available
      </Link>
    </div>
  )
}
