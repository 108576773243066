// @flow
import React from 'react'
import type {Node} from 'react'

import Link from '../Link'

import styles from './IconWithText.less'

type IconWithTextProps = {
  icon: Node,
  text: string,
  link?: string,
  color?: string,
}

export default ({icon, text, link, color}: IconWithTextProps) => {
  if (link) {
    return <Link internalLink={link} className={styles.UnderlinedContainer} style={{color}}>
      {icon && icon}
      <div className={styles.Text} style={{borderColor: color}}>{text}</div>
    </Link>
  }

  return <div className={styles.Container} style={{color}}>
    {icon && icon}
    <div style={{borderColor: color}}>{text}</div>
  </div>
}
