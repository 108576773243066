// @flow
import React from 'react'

import type {HomepageThemeType} from '../../common/types'
import Button from '../Button'

import imageUrl from '../../common/imageUrl'
import withDefault from '../../common/withDefault'

import Grid, {GridItem} from '../Grid'
import {gutterSize} from '../../common/constants'

import styles from './HomepageHeader.less'

const chevrons = {
  white: require('./whiteChevronRight.svg'),
  black: require('./blackChevronRight.svg'),
}

export default class PartnerHeader extends React.Component {
  props: {
    theme: HomepageThemeType,
    logo: string,
  }

  render() {
    const {theme, logo} = this.props
    const {header_background_image, header_background_color, background_color, header_link_color} = theme

    const backLinkColor = withDefault(header_link_color, 'white')

    const hasCTA = this.props.theme && this.props.theme.cta_text && this.props.theme.cta_link

    return (
      <Grid
        className={styles.Header}
        style={getBackgroundStyles(header_background_image, header_background_color, background_color)}
      >
        <GridItem width={6}>{logo && <img src={logo} className={styles.Logo} alt='Logo' />}</GridItem>

        <GridItem
          width={6}
          className={styles.HomeLink}
          style={{
            color: backLinkColor,
          }}
        >
          {hasCTA ?
            <Button
              text={this.props.theme.cta_text}
              href={this.props.theme.cta_link}
              target={'_blank'}
              style={{
                position: 'fixed',
                top: `${gutterSize}px`,
                right: `${gutterSize}px`,
                color: this.props.theme.cta_color,
                backgroundColor: this.props.theme.cta_background_color,
              }}
            /> :
            <a href='https://boilerroom.tv'>
              BOILERROOM.TV
              <img src={chevrons[backLinkColor]} alt='' />
            </a>
          }
        </GridItem>
      </Grid>
    )
  }
}

const getBackgroundStyles = (image, solidColor, gradientColor) => {
  if (image) {
    return {
      backgroundColor: solidColor,
      backgroundImage: `url(${imageUrl(image, 1200, 80)})`,
    }
  }

  if (solidColor) {
    return {
      backgroundColor: solidColor,
    }
  }

  return {
    backgroundImage: `linear-gradient(to bottom, ${gradientColor || '#000000'}, rgba(0,0,0,0))`,
  }
}
