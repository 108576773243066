// @flow
import React from 'react'
import {useSelector} from 'react-redux'

import {brandIds} from '../../common/brands'
import type {BrandType} from '../../common/types'
import type {State} from '../../stateType'

import Login from '../Login'
import Padlock from '../Svgs/Padlock'

import styles from './Auth.less'

type Props = {
  setError: string => void,
  uiLocation: string,
  itemType: ?string,
  itemId: ?number,
}

type AuthError = {
  code: string,
  description: string,
  name: string,
  statusCode: number,
}

export default class Auth extends React.Component {
  state: {
    error: boolean,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  onError = (errors: AuthError) => {
    if (errors.statusCode === 401) {
      this.props.setError(errors.description)
    } else {
      this.props.setError('The code you entered was incorrect. Please try again.')
      this.setState({error: true})
    }
  }

  clearError = () => {
    this.setState({error: false})
  }

  render() {
    return (
      <div className={styles.Auth}>
        <AuthProposition />
        <Login
          onError={this.onError}
          error={this.state.error}
          clearError={this.clearError}
          uiLocation={this.props.uiLocation}
          itemId={this.props.itemId}
          itemType={this.props.itemType}
        />
      </div>
    )
  }
}

export const AuthProposition = () => {
  const brand: BrandType = useSelector((state: State) => state.brand)
  const isFourThree = brand.id === brandIds.fourthree
  if (isFourThree) return <FourThreeAuthProposition />

  return (
    <React.Fragment>
      <h4>
        <Padlock color={'#fff'} />
        BECOME A MEMBER
      </h4>
      <div>Members get</div>
      <ul className={styles.AuthPropositionList}>
        <li>Invitations to parties in your city</li>
        <li>Presale & early bird ticket access</li>
        <li>News about events near you</li>
        <li>Apparel discounts, previews & exclusives</li>
        <li>A place to save your favourite sets</li>
      </ul>
    </React.Fragment>
  )
}

const FourThreeAuthProposition = () => (
  <div className={styles.FourThreeProposition}>
    <h3>4:3 is an alternative cultural institution</h3>
    <p>The award-winning concept by Boiler Room rethinks film, art and music through online and offline experiences.</p>
    <p>Sign up for events and exclusive videos via our WEEKLY SERVICE.</p>
  </div>
)
