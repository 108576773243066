// @flow

import type {GenericItemTypes, GenericItemType} from './types'

export const itemize = (subItem: any, type: GenericItemTypes): GenericItemType => ({
  id: subItem.id,
  title: subItem.title,
  description: subItem.description,
  summary: subItem.summary,
  type: type,
  thumbnail_image:
    subItem.thumbnail_image || (subItem.images && subItem.images.edges[0] && subItem.images.edges[0].node.url),
  background_image: subItem.background_image,
  slug: subItem.slug || subItem.handle,
  brand: subItem.brand,
  digital_products: subItem.digital_products,

  [type]: subItem,
})

export const itemizeCollection = (subItems: Array<*>, type: GenericItemTypes): Array<GenericItemType> =>
  subItems.map(subItem => itemize(subItem, type))

export const itemizeProducts = (subItems: Array<*>, type: GenericItemTypes): Array<GenericItemType> =>
  subItems.map(subItem => itemize(subItem.node, type))
