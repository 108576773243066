// @flow
import React from 'react'

import type {SponsorType} from '../../common/types'

import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import arrowIcon from './whiteArrow.svg'

import styles from './Sponsor.less'

type Props = {
  className?: string,
  sponsor: ?SponsorType,
}

export default ({className, sponsor}: Props) => {
  if (!sponsor) return null

  return (
    <BrandedStyleWrapper styles={styles}>
      <BrandedComponent
        brandContents={isFourThree =>
          sponsor && sponsor.logo_image ?
            <div className={[styles.Sponsor, className].join(' ')}>
              {'In partnership with'}

              {sponsor.url ?
                <a href={sponsor.url} target='_blank'>
                  <Image sponsor={sponsor} />
                </a> :
                <Image sponsor={sponsor} />
              }
              {sponsor.url && sponsor.url_label &&
                <a
                  className={isFourThree ? styles.PartnerSiteLinkFourThree : styles.PartnerSiteLink}
                  href={sponsor.url}
                  target='_blank'
                >
                  <span>{sponsor.url_label}</span>
                  <img src={arrowIcon} className={styles.ArrowIcon} alt='' />
                </a>
              }
            </div> :
            null
        }
      />
    </BrandedStyleWrapper>
  )
}

const Image = ({sponsor}) => <img className={styles.SponsorLogo} src={sponsor.logo_image} alt={sponsor.url_label} />
