// @flow
import React from 'react'

import type {PageSize, BrandType} from '../../common/types'
import {brandIds} from '../../common/brands'
import {makeQueueFromItems} from '../../common/makeQueue'
import {uiLocations} from '../../common/constants'

import BrandedComponent from '../BrandedComponent/'
import BrandedStyleWrapper from '../BrandedStyleWrapper/'
import Page from '../Page/'
import Grid, {GridItem} from '../Grid/'
import Logo from '../Logo/'
import Loading from '../Loading/'
import NotFound from '../NotFound/'
import Card from '../Card'
import LoadingCards from '../LoadingCards'
import Background from '../Background'
import FourThreeInfo from '../FourThreeInfo'
import Redirect from '../Redirect'
import Pagination from '../Pagination'
import Share from '../Share'
import PlayerInitialiser from '../PlayerInitialiser'

import styles from './GenericList.less'

export default class GenericList extends React.Component {
  props: {
    match: {
      params: {
        slug: string,
        type: string,
        page: string,
      },
    },
    data: any, // TODO type me
    loading: boolean,
    params: {
      splat: string,
    },
    location: {
      pathname: String,
    },
    onSeeMore: Function,
    pageSize: PageSize,
    brand: BrandType,
  }

  render() {
    const {data, loading, brand} = this.props

    const collection_by_slug = data && data.collection_by_slug

    if (!loading && !collection_by_slug) return <NotFound />
    if (loading && !collection_by_slug) return <Loading />
    const isLoadingMore = loading && collection_by_slug

    const {
      title,
      description,
      background_image,
      requires_login,
      sponsor,
      logo_image,
      items_count,
      items,
      theme,
    } = collection_by_slug

    const forceTwoPerRow = items_count === 2 || brand.id !== brandIds.boilerroom

    const isOnWrongUrl = this.props.match.params.type !== collection_by_slug.type
    const correctUrl = isOnWrongUrl && `/${collection_by_slug.type}/${collection_by_slug.slug}`

    const redirectUrl = collection_by_slug.redirect_url || correctUrl
    if (redirectUrl) return <Redirect to={redirectUrl} />

    return (
      <Page
        title={title}
        description={description}
        image={background_image}
        brandId={collection_by_slug.brand.id}
        requiresLogin={requires_login}
        contentColumn={brand.id === brandIds.fourthree}
        theme={theme}
      >
        <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
          <div className={styles.Background}>
            <Background image={background_image} dim={0.5} />

            <div
              className={styles.Gradient}
              style={{
                backgroundImage: `linear-gradient(to top, ${theme && theme.background_color || '#000000'}, rgba(0,0,0,0))`,
              }}
            />
          </div>

          <Grid className={styles.Header}>
            <Header
              title={title}
              description={description}
              sponsor={sponsor}
              logo={logo_image}
              items={items}
            />

            {collection_by_slug.items.map(item => (
              <GridItem
                responsiveWidths={{
                  small: 12,
                  medium: 6,
                  large: forceTwoPerRow ? 6 : 4,
                  extraLarge: forceTwoPerRow ? 6 : 3,
                }}
                className='GenericListItem'
                key={item.id}
              >
                <Card
                  item={item}
                  queue={makeQueueFromItems(item, collection_by_slug.items)}
                  uiLocation={uiLocations.genericListPageCard}
                />
              </GridItem>
            ))}

            {isLoadingMore && <LoadingCards />}
          </Grid>

          <Pagination
            itemCount={items_count}
            type={this.props.match.params.type}
            slug={this.props.match.params.slug}
            page={this.props.match.params.page}
          />
        </BrandedStyleWrapper>
      </Page>
    )
  }
}

const Header = ({title, description, logo, sponsor, items}) => (
  <GridItem width={12}>
    <BrandedComponent
      brandContents={isFourThree =>
        isFourThree ?
          <FourThreeInfo title={title} description={description} className={styles.Info} sponsor={sponsor} /> :
          <div className={styles.HeaderContent}>
            <div>
              {sponsor && <SponsorLogo sponsor={sponsor} />}
              <Title logo={logo} title={title} />
            </div>

            <div className={styles.PlayAndShareButtonWrapper}>
              <PlayerInitialiser
                queue={makeQueueFromItems(items[0], items)}
                className={styles.PlayerInitialiserButton}
              />
              <Share className={styles.ShareLink} />
            </div>
          </div>
      }
    />
  </GridItem>
)

const Title = ({logo, title}) =>
  logo ? <Logo src={logo} className={styles.Logo} large={true} /> : <h2 className={styles.HeaderTitle}>{title}</h2>

const SponsorLogo = ({sponsor}) =>
  sponsor.url ?
    <a href={sponsor.url}>
      <Logo src={sponsor.logo_image} className={styles.SponsorLogo} />
    </a> :
    <Logo src={sponsor.logo_image} className={styles.SponsorLogo} />
