// @flow
import React from 'react'
import {spring, Motion} from 'react-motion'

import type {BrandType} from '../../common/types'
import {brandIds} from '../../common/brands'

import lowHeatLogo from '../Navigation/lowHeat.svg'

import styles from './Spinner.less'

export default class Spinner extends React.PureComponent {
  props: {
    brand: BrandType,
    loading: boolean,
    className?: string,
  }

  render() {
    const {brand, loading, className} = this.props
    const isLowHeat = brand.id === brandIds.lowheat
    const image = isLowHeat ? lowHeatLogo : require('./spinner.svg')

    return (
      <Motion className={className} style={{opacity: spring(loading ? 1 : 0)}}>
        {animated => (
          <div
            className={[styles.Spinner, className].join(' ')}
            style={Object.assign({}, animated, {
              backgroundImage: `url(${image})`,
            })}
          />
        )}
      </Motion>
    )
  }
}
