// @flow
import React from 'react'

type Props = {
  color?: string,
}

export default ({color}: Props) => (
  <svg
    width='30px'
    height='30px'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
    style={{background: 'none'}}
  >
    <circle
      cx='50'
      cy='50'
      fill='none'
      stroke={color || '#000'}
      strokeWidth='10'
      r='30'
      strokeDasharray='141.37166941154067 49.12388980384689'
      transform='rotate(65.8102 50 50)'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        calcMode='linear'
        values='0 50 50;360 50 50'
        keyTimes='0;1'
        dur='1s'
        begin='0s'
        repeatCount='indefinite'
      />
    </circle>
  </svg>
)
