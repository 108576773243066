import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import type {LocationType, RecordingType} from '../../common/types'
import type {State} from '../../stateType'

import type {StateType as PlayerType} from '../PersistentPlayer/state'
import {mediaFormats} from '../../common/constants'

import Button from '../Button'

import {actions as playerActions} from '../PersistentPlayer/state'

import styles from './PlayerInitialiser.less'

type Props = {
  className?: string,
  queue?: Array<RecordingType>,
  autoplay?: Boolean,
  formatOverride?: ?String,
  disableQueueActivation?: Boolean,
  isAudioPage?: Boolean,
}

export default ({
  className = '',
  queue,
  autoplay,
  formatOverride,
  disableQueueActivation,
  isAudioPage,
}: Props) => {
  const dispatch = useDispatch()
  const {playerEmbedUrl, itemSlug, format, itemPath, isAudioPlaying}: PlayerType = useSelector((state: State) => state.player)
  const {pathname}: LocationType = useSelector((state: State) => state.loadLocation)

  const isActiveAudioPlayer = itemPath === pathname
  const shouldDisplayAudioPlayingButton = isAudioPlaying && isActiveAudioPlayer

  if (!queue || queue.length === 0) return null

  useEffect(() => {
    if (format === mediaFormats.video && !playerEmbedUrl && autoplay) play()
  }, [])

  const isActive = !disableQueueActivation && queue.find(recording => recording.slug === itemSlug) &&
    (!formatOverride || formatOverride && formatOverride === format)

  const play = () => {
    // if the playlist or recording is already playing, don't do anything on click
    // on a /audio page where the audio button is enabled, allow the user to play the audio
    if (isActive && !isAudioPage) return

    if (isActiveAudioPlayer) {
      return dispatch(playerActions.toggleAudioPlaying())
    }

    if (queue) {
      return dispatch(playerActions.loadQueue(queue, formatOverride))
    }
  }

  const recordingPlayButtonClassName = isActive ? styles.PlayingButton : styles.PlayButton
  const audioPlayButtonClassName = shouldDisplayAudioPlayingButton ? styles.AudioPlayingButton : styles.AudioPlayButton

  // isAudioPage prop for audio/:slug page so not to have the audio play button on audio sliders
  if (isAudioPage) {
    return (
      <Button
        className={[className, audioPlayButtonClassName].join(' ')}
        onClick={play}
      />
    )
  }

  return (
    <Button
      text={isActive ? 'NOW PLAYING' : 'PLAY'}
      className={[className, recordingPlayButtonClassName].join(' ')}
      onClick={play}
    />
  )
}
