// @flow
import {trendingPlaylists} from './constants'
import {recordingIsInPlaylist} from './chartHelpers'
import type {GenericItemType} from './types'

export default (item: GenericItemType, disableChartPosition?: Boolean) => {
  if (!item || !item.recording) return null

  const isInNewPlaylist = recordingIsInPlaylist(item.recording, trendingPlaylists.new)
  const isInTrendingPlaylist = recordingIsInPlaylist(item.recording, trendingPlaylists.trending)
  const shouldDisplayChartPosition = !disableChartPosition && (isInNewPlaylist || isInTrendingPlaylist)

  return shouldDisplayChartPosition
}
