import {gql} from '@apollo/client'
import {RecordingCardFragment, ShowCardFragment} from '../../common/fragments'

export default gql`
  query ArtistQuery($slug: String!) {
    artist_by_slug(slug: $slug) {
      name
      title
      image
      hide_artist
      country: country_name
      socials {
        website
        facebook
        twitter
        instagram
        soundcloud
        youtube
      }
      recordings {
        ...RecordingCard
      }
      shows {
        ...ShowCard
      }
      city {
        name
        slug
      }
    }
  }

  ${RecordingCardFragment}
  ${ShowCardFragment}
`
