import {trendingPlaylists} from './constants'
import {RecordingType} from './types'

export const recordingIsInPlaylist = (recording: RecordingType, playlistSlug: String) => {
  const playlists = recording && recording.playlists

  return playlists && playlists.some(playlist => playlist.slug === trendingPlaylists[playlistSlug])
}

export const playlistPosition = (recording: RecordingType, playlistSlug) => {
  const playlists = recording && recording.playlists

  // Recordings list all playlists they belong to - Attempt to find the correct playlist
  // For the provided playlistSlug
  const foundPlaylist = playlists.find(playlist => playlist.slug === playlistSlug)

  if (foundPlaylist && foundPlaylist.items) {
    return foundPlaylist.items.findIndex(item => item.title === recording.title)
  }
  // -1 means the provided Recording was not found in the specified playlist.
  return -1
}

export const getChartPositionIndicatorText = (recording: RecordingType) => {
  if (!recording) return null
  const isInNewPlaylist = recordingIsInPlaylist(recording, trendingPlaylists.new)
  const isInTrendingPlaylist = recordingIsInPlaylist(recording, trendingPlaylists.trending)
  const shouldDisplayChartPosition = isInNewPlaylist || isInTrendingPlaylist

  if (!shouldDisplayChartPosition) return null

  return isInNewPlaylist ?
    `#${playlistPosition(recording, trendingPlaylists.new) + 1} New` :
    `#${playlistPosition(recording, trendingPlaylists.trending) + 1} Trending`
}
