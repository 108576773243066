import React from 'react'

import {Redirect} from 'react-router-dom'
import {hasWindow} from '../../common/constants'

type Props = {
  to: string,
}

export default ({to}: Props) => {
  if (hasWindow) {
    window.location.assign(to)
    return null
  } else {
    return <Redirect to={to} />
  }
}
