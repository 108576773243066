import {gql} from '@apollo/client'

export default gql`
  query RsvpsQuery {
    rsvps {
      show {
        id
        additional_details
        background_image
        broadcast_status
        city {
          name
          timezone
          slug
        }
        end
        event_end
        event_start
        date_display_format
        flyer_image
        slug
        start
        title
        venue_details
        user_rsvp_status {
          id
          status
          name
          additional_guests
          default_additional_guests
        }
      }
    }
  }
`
