// @flow
import React from 'react'

import Link from '../Link'
import Arrow from '../Svgs/Arrow'

import styles from './FavouriteCities.less'

import type {CityType} from '../../common/types'

export default ({cities}: {cities: Array<CityType>}) => (
  <div className={styles.FavouriteCitiesContainer}>
    <h4>YOUR CITIES</h4>

    <div className={styles.Cities}>
      {cities.length ?
        cities
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(city => (
            <Link className={styles.CityName} internalLink={`/city/${city.slug}`} key={city.id}>
              {`${city.name}`}
            </Link>
          )) :
        <span className={styles.NoCitiesMessage}>You do not have any cities</span>
      }
    </div>

    <Link className={styles.ViewAllCities} internalLink={'/cities'}>
      {cities.length ? 'View all cities' : 'Find your city'}
      <Arrow direction='right' color='white' />
    </Link>
  </div>
)
