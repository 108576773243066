// @flow
import React from 'react'

import type {ShowType} from '../../common/types'
import {itemizeCollection} from '../../common/itemize'
import {genericItemTypes} from '../../common/constants'

import Slider from '../Slider'
import Query from '../Query'

import GET_CURRENT_SHOWS from '../Upcoming/Query.js'

type Props = {
  currentShows: {
    shows: Array<ShowType>,
  },
  loading: boolean,
}

export default () => <Query Component={UpcomingSlider} query={GET_CURRENT_SHOWS} />

const UpcomingSlider = (props: Props) => {
  const {currentShows, loading} = props

  if (!loading && !currentShows) return null

  // Ensures correct number of LoadingCard renders in slider
  const itemsLoading = [false, false, false, false]
  const items = loading ? itemsLoading : itemizeCollection(currentShows.shows, genericItemTypes.show)

  return <Slider title='Upcoming' internalLink={'/upcoming'} items={items} />
}
