// @flow
import {connect} from 'react-redux'

import type {State} from '../../stateType'
import Component from './Component'

const mapStateToProps = ({notifications}: State) => ({
  notifications: notifications.notifications,
})

export default connect(mapStateToProps)(Component)
