import {gql} from '@apollo/client'

export default gql`
  query ShopSettings {
    web_settings {
      shop_nav_banner_text
      shop_offline
    }
  }
`
