// @flow
import React from 'react'
import {gql} from '@apollo/client'
import {Mutation, Query} from '@apollo/client/react/components'
import {connect} from 'react-redux'

import type {State} from '../../stateType'
import {CheckoutFragment} from '../../common/fragments'

import Component from './Component'
import {actions} from '../Shop/state'
import {actions as notificationActions} from '../Notifications/state'

const mapStateToProps = ({shop, page}: State) => ({
  checkoutId: shop.checkoutId,
  shopLocale: shop.shopLocale,
  pageSize: page.pageSize,
})

const mapDispatchToProps = {
  setCheckout: actions.setCheckout,
  setError: notificationActions.setError,
}

export const CREATE_CHECKOUT = gql`
  mutation (
    $lineItems: [CheckoutLineItemInput!],
    $country: CountryCode,
    $customAttributes: [AttributeInput!]
  ) @inContext(country: $country) {
    checkoutCreate(input: {
      lineItems: $lineItems,
      customAttributes: $customAttributes
    }) {
      checkout {
        ...Checkout
      }
    }
  }
  ${CheckoutFragment}
`

export const ADD_LINE_ITEM = gql`
  mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!, $country: CountryCode) @inContext(country: $country) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...Checkout
      }
    }
  }
  ${CheckoutFragment}
`

const GET_PRODUCT = gql`
  query ProductQuery($handle: String!, $country: CountryCode) @inContext(country: $country) {
    product(handle: $handle) {
      availableForSale
      descriptionHtml
      description
      handle
      id
      title
      tags
      productType

      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }

      images(first: 20) {
        edges {
          node {
            id
            altText
            url
          }
        }
      }

      variants(first: 6) {
        edges {
          node {
            availableForSale
            compareAtPrice {
              amount
            }
            id
            title
          }
        }
      }
    }
  }
`

const Product = props => {
  const mutation = props.checkoutId ? ADD_LINE_ITEM : CREATE_CHECKOUT

  return (
    <Query
      query={GET_PRODUCT}
      variables={{handle: props.match.params.slug, country: props.shopLocale}}
      context={{clientName: 'shopify'}}
    >
      {({loading, data}) => (
        <Mutation mutation={mutation} context={{clientName: 'shopify'}} variables={{handle: props.match.params.slug, country: props.shopLocale}}>
          {mutation => <Component {...props} mutation={mutation} data={data} loading={loading} />}
        </Mutation>
      )}
    </Query>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
