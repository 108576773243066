// @flow
import React from 'react'
import Slick from 'react-slick'
import striptags from 'striptags'

import type {GenericCollectionType, PageSize} from '../../common/types'
import {leftArrowKey, pageSizes, rightArrowKey, hasWindow, uiLocations} from '../../common/constants'

import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Background from '../Background'
import Logo from '../Logo'
import Button from '../Button'
import generateCTACopy from './generateCTACopy'
import Link from '../Link'
import Control from '../Control'

import styles from './Carousel.less'
import {analytics} from '../../common/analytics'

type Props = {
  collection: GenericCollectionType,
  displaySponsorLogos: boolean,
  backgroundColor?: ?string,
  pageSize: PageSize,
}

export default class Carousel extends React.Component {
  props: Props

  slider: ?Object

  state: {
    index: number,
    muted: boolean,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      index: 0,
      muted: true,
    }

    if (hasWindow) {
      document.addEventListener('keydown', ({keyCode}: any) => {
        if (keyCode === leftArrowKey) {
          this.previous()
        } else if (keyCode === rightArrowKey) {
          this.next()
        }
      })
    }
  }

  next = () => {
    if (this.slider && this.slider.slickNext) this.slider.slickNext()
  }

  previous = () => {
    if (this.slider && this.slider.slickPrev) this.slider.slickPrev()
  }

  goTo = (index: number) => {
    if (this.slider && this.slider.slickGoTo) this.slider.slickGoTo(index)
  }

  setIndex = (index: number) => {
    analytics.track('carousel_slide', {}, ['ga'])
    this.setState({index})
  }

  toggleMuted = () => {
    analytics.track(this.state.muted ? 'carousel_unmute' : 'carousel_mute', {}, ['ga'])
    this.setState({muted: !this.state.muted})
  }

  setSlider = (c: Object) => {
    this.slider = c
  }

  render() {
    const {displaySponsorLogos, pageSize} = this.props
    const {items} = this.props.collection
    const {index, muted} = this.state

    const activeItem = items[index]

    if (!activeItem) {
      console.warn('Carousel disabled due to the carousel playlist having 0 items.')
      return null
    }

    const {thumbnail_image, background_image, trailer_video} = activeItem

    const backgroundColor = this.props.backgroundColor || '#000000'

    const mobileView = pageSize === pageSizes.small
    const showControls = hasWindow && !mobileView
    const moreThanOneItem = items.length > 1

    return (
      <BrandedStyleWrapper styles={styles} classNameKey='Carousel'>
        <BrandedComponent
          brandContents={isFourThree => (
            <div className={styles.Wrapper}>
              <Background
                image={background_image || thumbnail_image}
                video={trailer_video}
                playing={true}
                muted={this.state.muted}
                className={styles.Background}
              />

              {hasWindow &&
                <Slick
                  ref={this.setSlider}
                  className={styles.Slick}
                  afterChange={this.setIndex}
                  dots={false}
                  arrows={false}
                  infinite={true}
                  speed={isFourThree && !mobileView ? 0 : 800}
                  slidesToShow={1}
                  slidesToScroll={1}
                  autoplay
                  autoplaySpeed={6000}
                >
                  {items.map(item => (
                    <Slide
                      key={item.id}
                      item={item}
                      displaySponsorLogos={displaySponsorLogos}
                      isFourThree={isFourThree}
                      backgroundColor={backgroundColor}
                      mobileView={mobileView}
                    />
                  ))}
                </Slick>
              }

              {showControls &&
                <Controls
                  next={this.next}
                  previous={this.previous}
                  toggleMuted={this.toggleMuted}
                  muted={muted}
                  className={styles.Controls}
                  isFourThree={isFourThree}
                  moreThanOneItem={moreThanOneItem}
                />
              }
            </div>
          )}
        />
      </BrandedStyleWrapper>
    )
  }
}

const Slide = props => {
  const {
    item,

    backgroundColor,
    displaySponsorLogos,

    isFourThree,
  } = props

  // Slick passes style to props which contains the width value used by the Slide component below

  return (
    <Link
      item={item}
      className={styles.SlickSlide}
      uiLocation={uiLocations.carousel}
      // $FlowFixMe
      style={props.style}
    >
      <div className={styles.SlideWrapper}>
        <div className={styles.Slide} style={!isFourThree ? {backgroundColor} : null}>
          <SponsorLogo item={item} displaySponsorLogos={displaySponsorLogos} />

          <Title title={item.title} image={item.logo_image} />

          <Description item={item} />

          <Button text={generateCTACopy(item)} className={styles.SlideButton} />
        </div>
      </div>
    </Link>
  )
}

const SponsorLogo = ({item, displaySponsorLogos}) =>
  displaySponsorLogos && item.sponsor ?
    <p>
      <Logo src={item.sponsor.logo_image} large={false} />
    </p> :
    null

const Title = ({title, image}) =>
  image ?
    <p>
      <Logo src={image} large={true} className={styles.LogoTitle} />
    </p> :
    <h3>{title}</h3>

const Description = ({item}) => (
  <p className={styles.Description} dangerouslySetInnerHTML={{__html: striptags(item.summary || item.description)}} />
)

const Controls = ({next, previous, toggleMuted, muted, showVolume, isFourThree, moreThanOneItem}) => {
  if (isFourThree) {
    return (
      <div className={styles.FourThreeControls}>
        <Control
          color='white'
          background='transparent'
          borderColor='white'
          className={moreThanOneItem ? styles.FourThreeControl : styles.HiddenControl}
          type='left'
          onClick={previous}
        />

        <Control
          color='white'
          background='transparent'
          borderColor='white'
          className={moreThanOneItem ? styles.FourThreeControl : styles.HiddenControl}
          type='right'
          onClick={next}
        />

        <Control
          color='white'
          background='transparent'
          borderColor='white'
          className={styles.FourThreeControl}
          type={muted ? 'unmute' : 'mute'}
          onClick={toggleMuted}
        />
      </div>
    )
  }

  return [
    <div className={moreThanOneItem ? styles.LeftControl : styles.HiddenControl} key='left-control' onClick={previous}>
      <Control type='left' color='#000' className={styles.LeftChevron} />
    </div>,
    <div onClick={toggleMuted} key='volume-control'>
      <Control
        type={muted ? 'unmute' : 'mute'}
        onClick={toggleMuted}
        color='#000'
        background='#FFF'
        className={styles.AudioControl}
      />
    </div>,
    <div className={moreThanOneItem ? styles.RightControl : styles.HiddenControl} key='right-control' onClick={next}>
      <Control type='right' className={styles.RightChevron} />
    </div>,
  ]
}
