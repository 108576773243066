// @flow
import moment from 'moment-timezone'

import type {ShowType} from './types'
import {showDateFormats} from './constants'

export default (show: ShowType): ?string => {
  if (!show) return
  // the date is shown in the show.city.timezone
  const timezone = (show.city && show.city.timezone) || 'UTC'
  const localStart = moment.tz(show.start, timezone)
  const localEnd = moment.tz(show.end, timezone)

  const isOverMultipleYears = localStart.year() !== localEnd.year()
  const isOverMultipleMonths = localStart.month() !== localEnd.month()
  const isOverMultipleDays = localEnd.isAfter(localStart.clone().add(1, 'day'))

  const shouldDisplayMonthYear = show.date_display_format && show.date_display_format === showDateFormats.monthYear

  if (isOverMultipleYears) {
    if (shouldDisplayMonthYear) return `${localStart.format('MMM YYYY')} - ${localEnd.format('MMM YYYY')}`
    return `${localStart.format('DD MMM YYYY')} - ${localEnd.format('DD MMM YYYY')}`
  }

  if (isOverMultipleMonths) {
    if (shouldDisplayMonthYear) return `${localStart.format('MMM')} - ${localEnd.format('MMM')} ${localStart.year()}`
    return `${localStart.format('DD MMM')} - ${localEnd.format('DD MMM')} ${localStart.year()}`
  }

  if (isOverMultipleDays) {
    if (shouldDisplayMonthYear) return localStart.format('MMM YYYY')
    return `${localStart.format('DD')} - ${localEnd.format('DD')} ${localStart.format('MMM YYYY')}`
  }

  return shouldDisplayMonthYear ? localStart.format('MMM YYYY') : localStart.format('DD MMM YYYY')
}
