// @flow
import React from 'react'

import type {NotificationType} from '../../common/types'

import Link from '../Link'

import styles from './Notifications.less'

type Props = {
  notifications: Array<NotificationType>,
}

export default ({notifications}: Props) => {
  if (notifications.length === 0) return null

  return (
    <div className={styles.Container}>
      {notifications.map((notification, index) => (
        <Notification key={'notification' + index} notification={notification} />
      ))}
    </div>
  )
}

const Notification = ({notification}) => {
  const {message, type, internalLink, linkMessage} = notification

  return (
    <div className={styles[`Notification_${type}`]}>
      <span className={styles.Message}>{message}</span>
      {internalLink && linkMessage &&
        <Link className={styles.Link} internalLink={internalLink}>
          {linkMessage}
        </Link>}
    </div>
  )
}
