import moment from 'moment'

export default (slug: String) => {
  const userEnteredFutureDate = slug && moment(slug, 'YYYY-MM-DD').isAfter(moment())
  if (userEnteredFutureDate) return '/schedule/upcoming'

  const userEnteredDateThatIsNotMonday = slug && moment(slug, 'YYYY-MM-DD').isAfter(moment(slug).startOf('isoWeek'))
  if (userEnteredDateThatIsNotMonday) return `/schedule/${moment(slug).startOf('isoWeek').format('YYYY-MM-DD')}`

  return null
}
