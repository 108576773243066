// @flow
import React from 'react'
import moment from 'moment'
import marked from 'marked'

import {brandIds} from '../../common/brands'
import type {ArticleType} from '../../common/types'

import Background from '../Background'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Loading from '../Loading'
import Logo from '../Logo'
import NotFound from '../NotFound'
import Page from '../Page'
import Share from '../Share'
import ShopSlider from '../ShopSlider'
import UpcomingSlider from '../UpcomingSlider'
import Sponsor from '../Sponsor'
import Slider from '../Slider'

import whiteArrow from './whiteArrow.svg'

import styles from './Article.less'

const renderer = {
  image: (href, title, text) => {
    const articleWidth = 650 // px
    const imageSrc = generateImageUrl(href, articleWidth * 2)
    let className = ''
    if (title === 'Full Width') {
      className = styles.FullWidthImage
    } else if (title === 'Pop Out') className = styles.PopOutImage
    return `<img class='${className}' src='${imageSrc}' alt='${text}' />`
  },
}

const generateImageUrl = (href, width) => `https://boilerroom.tv/cdn-cgi/image/width=${width}/` + href

export default class Article extends React.Component {
  props: {
    data: {
      article: ArticleType,
    },
    loading: boolean,
  }

  render() {
    const {data, loading} = this.props

    const article = data && data.article

    if (!loading && !article) return <NotFound />
    if (loading || !article) return <Loading />

    const {
      author,
      background_image,
      content,
      logo_image,
      released,
      requires_login,
      summary,
      thumbnail_image,
      title,
      sponsor,
      trailer_video,
    } = article

    const date = moment(released).format('DD MMM YYYY')
    const isNotFourThree = article.brand && article.brand.id !== brandIds.fourthree

    marked.use({renderer})

    const isBigHeaderArticle = article.big_header_enabled
    const bigHeaderTextColour = article.big_header_text_color || '#FFFFFF'

    return (
      <Page
        ssr={true}
        disableHeaderOffset={isNotFourThree}
        image={thumbnail_image || background_image}
        brandId={article.brand.id}
        requiresLogin={requires_login}
        title={title}
      >
        <BrandedStyleWrapper styles={styles} classNameKey={'Wrapper'}>
          <div className={styles.ImageHeader}>
            <Background
              className={styles.HeadlineImage}
              image={background_image}
              video={trailer_video}
              playing={true}
              muted={true}
            />

            {logo_image &&
              <Logo className={styles.LogoImage} src={logo_image} large={true} />
            }

            {!logo_image && isBigHeaderArticle &&
              <h1 className={styles.Title} style={{color: bigHeaderTextColour}}>{title}</h1>
            }

            {isBigHeaderArticle &&
              <div className={styles.Info} style={{color: bigHeaderTextColour}}>
                <div>{author}</div>

                {author && date && <div className={styles.Spacer}>|</div>}

                <div>{date}</div>
              </div>
            }
          </div>

          <img className={styles.Arrow} src={whiteArrow} />

          <div className={isBigHeaderArticle ? styles.ContainerBigHeaderArticle : styles.Container}>
            <Headline
              author={author}
              date={date}
              logo_image={logo_image}
              summary={summary}
              title={title}
              isBigHeaderArticle={isBigHeaderArticle}
            />

            <div className={styles.TextContainer}>
              <Sponsor className={styles.Sponsor} sponsor={sponsor} />

              <div className={styles.Text} dangerouslySetInnerHTML={{__html: marked(content)}} />

              <Share dark />
            </div>
          </div>
        </BrandedStyleWrapper>
        <div>
          {article.related_playlists &&
            article.related_playlists.map(playlist => (
              <Slider
                title={playlist.title}
                description={playlist.summary}
                items={playlist.items}
                key={`related-playlist-${playlist.id}`}
              />
            ))}

          <UpcomingSlider />

          <ShopSlider />
        </div>
      </Page>
    )
  }
}

const Headline = ({author, date, logo_image, summary, title, isBigHeaderArticle}) =>
  <div className={styles.Headline}>
    {!isBigHeaderArticle &&
      <React.Fragment>
        {logo_image && <Logo className={styles.LogoImage} src={logo_image} large={true} />}
        <h1 className={styles.Title}>{title}</h1>
      </React.Fragment>
    }

    {!isBigHeaderArticle &&
      <div className={styles.Info}>
        <div>{author}</div>

        {author && date && <div className={styles.Spacer}>|</div>}

        <div>{date}</div>
      </div>
    }

    <div className={styles.Summary} dangerouslySetInnerHTML={{__html: marked(summary)}} />
  </div>
