// @flow
import moment from 'moment-timezone'

import type {ShowType} from './types'
import {showDateFormats} from './constants'

const getZoneAbbr = start => {
  const zoneAbbr = start.zoneAbbr()
  // If the timezone abbreviation is not present moment will return the UTC
  // offset. If this happens we prefix the offset with UTC so it's clear to users.
  if (zoneAbbr[0] === '-' || zoneAbbr[0] === '+') return `UTC${zoneAbbr}`
  // Otherwise return the timezone abbreviation as normal
  return zoneAbbr
}

export default (show: ShowType, timezoneOverride?: ?string): ?string => {
  if (!show) return null

  // display show time with city timezone (or UTC as a fallback)
  // if a timezoneOverride is supplied use that timezone
  const cityTimezone = show.city && show.city.timezone
  const timezone = timezoneOverride || cityTimezone || 'UTC'
  const start = moment.tz(show.start, timezone)
  const end = moment.tz(show.end, timezone)
  const isOneDayOrLess = end.isBefore(start.clone().add(1, 'day'))
  const parsedZoneAbbr = getZoneAbbr(start)

  if (isOneDayOrLess) {
    if (show.date_display_format === showDateFormats.dayMonthYearStart) return `${start.format('HH:mm')} ${parsedZoneAbbr}`
    return `${start.format('HH:mm')} - ${end.format('HH:mm')} ${parsedZoneAbbr}`
  }

  return null
}
