// @flow
import React from 'react'

import type {BrandType} from '../../common/types'

import styles from './Button.less'
import LoadingSpinner from '../Svgs/LoadingSpinner'

export default class Button extends React.PureComponent {
  props: {
    brand: BrandType,
    className?: string,
    disabled?: boolean,
    href?: string,
    loadingColour?: string,
    loading?: boolean,
    onClick?: Function,
    style?: Object,
    text: string,
    type?: string,
    target?: string,
    disableDefaultStyles?: boolean,
    diceEventId?: String,
  }

  render() {
    const {
      brand,
      className,
      disabled,
      href,
      loadingColour,
      loading,
      onClick,
      style,
      text,
      type,
      disableDefaultStyles,
      diceEventId,
    } = this.props

    const defaultClassName = styles[`Button${brand.style_suffix}`] || styles.Button
    const classNames = disableDefaultStyles ? className : [defaultClassName, className].join(' ')

    const loadingSpinner = <LoadingSpinner color={loadingColour} />
    const buttonContent = loading ? loadingSpinner : text
    const target = this.props.target || '_self'

    const onKeyPress = event => event.key === 'Enter' && onClick && onClick()

    // Link button
    if (href) {
      return (
        <a href={href} target={target} className={classNames} style={style}>
          {buttonContent}
        </a>
      )
    }
    // Submit button
    if (type) {
      return (
        <button type={type} onClick={onClick} className={classNames} style={style} disabled={disabled}>
          {buttonContent}
        </button>
      )
    }
    // Click button
    if (onClick) {
      return (
        <div onClick={onClick} className={classNames} style={style} tabIndex='0' onKeyPress={onKeyPress}>
          {buttonContent}
        </div>
      )
    }
    // Dice purchase overlay button
    if (diceEventId) {
      return (
        <button className={classNames} style={style} data-dice-id={diceEventId}>
          {buttonContent}
        </button>
      )
    }
    // Faux button
    return (
      <div className={classNames} style={style}>
        {buttonContent}
      </div>
    )
  }
}
