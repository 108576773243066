// @flow
import React from 'react'

export default ({color, className, width, height}: {color?: string, className?: string, width?: string, height?: string}) => {
  const iconWidth = width || '7'
  const iconHeight = height || '10'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={iconWidth}
      height={iconHeight}
      fill='none'
      viewBox='0 0 7 10'
      className={className}
    >
      <path
        fill='currentColor'
        d='M0 6.02L5.681 0 3.45 4.388 7 4.184.812 10l2.637-4.184L0 6.02z'
      />
    </svg>
  )
}
