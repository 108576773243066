// @flow
import React from 'react'

import Page from '../Page'
import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import HasWindow from '../HasWindow'

import styles from './Loading.less'

import fourThreeLogo from './fourThree.svg'

export default ({hideHeader}: {hideHeader?: boolean}) => {
  return (
    <HasWindow>
      <Page header={hideHeader && <div />} hideFooter={true}>
        <BrandedStyleWrapper classNameKey='Wrapper' styles={styles}>
          <BrandedComponent
            classNameKey='Wrapper'
            styles={styles}
            brandContents={(isFourThree, isLowHeat, isGasworks) => (isFourThree ? <FourThree /> : null)}
          />
        </BrandedStyleWrapper>
      </Page>
    </HasWindow>
  )
}

const FourThree = () => <img src={fourThreeLogo} className={styles.FourThreeLogo} alt='Loading Spinner' />
