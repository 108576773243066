import React from 'react'

const VideoIcon = () =>
  <svg
    width='16'
    height='12'
    viewBox='0 0 18 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.5 1.73311C0.5 0.897152 1.09112 0.0759277 1.98361 0.0759277H10.2213C11.1138 0.0759277 11.7049 0.897152 11.7049 1.73311V8.38692C11.7049 9.22288 11.1138 10.0441 10.2213 10.0441H1.98361C1.09112 10.0441 0.5 9.22288 0.5 8.38692V1.73311ZM1.98361 1.07593C1.78963 1.07593 1.5 1.29088 1.5 1.73311V8.38692C1.5 8.82915 1.78963 9.04411 1.98361 9.04411H10.2213C10.4153 9.04411 10.7049 8.82915 10.7049 8.38692V1.73311C10.7049 1.29088 10.4153 1.07593 10.2213 1.07593H1.98361ZM17.6803 0.387955V9.70704L12.5 7.61222V2.66955L17.6803 0.387955ZM13.5 3.32181V6.93793L16.6803 8.22399V1.92109L13.5 3.32181Z'
      fill='white'
      strokeWidth='1px'
      vectorEffect='non-scaling-stroke'
    />
  </svg>

export default VideoIcon
