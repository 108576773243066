// @flow
import React from 'react'
import marked from 'marked'

import type {SponsorType} from '../../common/types'

import Sponsor from '../Sponsor'

import styles from './FourThreeInfo.less'

export default class FourThreeInfo extends React.Component {
  props: {
    title: string,
    description?: string,
    className?: string,
    metaDataClassName?: string,
    metaData?: ?Array<*>,
    truncate?: boolean,
    director?: string,
    sponsor?: ?SponsorType,
  }

  render() {
    const {sponsor, title, metaData, className, truncate, director, metaDataClassName} = this.props

    return (
      <div className={[styles.Wrapper, className].join(' ')}>
        <h3 className={styles.Title}>{title}</h3>

        <MetaData metaData={metaData} director={director} className={metaDataClassName} />

        <Description description={this.props.description} truncate={truncate} />

        <Sponsor sponsor={sponsor} />
      </div>
    )
  }
}

const Description = ({description, truncate}) =>
  description ?
    <div className={truncate ? styles.TruncatedDescription : styles.Description}>
      {truncate ? description : <div dangerouslySetInnerHTML={{__html: marked(description)}} />}
    </div>
    : null

const MetaData = ({metaData, director, className}) =>
  metaData ?
    <div className={[styles.MetaData, className].join(' ')}>
      {metaData.map(text => (text ? <div key={text}>{text}</div> : null))}

      {director && <div>Directed by {director}</div>}
    </div>
    : null
