import {gql} from '@apollo/client'

export const BrandFragment = gql`
  fragment FullBrand on Brand {
    id
    name
    description
    url_slug
    apple_touch_icon
    background_color
    favicon
    image
    page_title
    style_suffix
    title
    url_prefix
  }
`

export const SignupFragment = gql`
  fragment FullSignup on Signup {
    id
    title
    page_title
    slug
    page_publication_status
    text
    text_for_opted_in_members
    button_text
    complete_text
    background_image
    background_video
    tags
    opt_in
    requires_login
    enable_city_selector
    phone_number_enabled
    phone_number_required
    consents {
      label
      required
      tag
    }
  }
`

export const CheckoutFragment = gql`
  fragment Checkout on Checkout {
    completedAt
    id
    subtotalPrice {
      amount
      currencyCode
    }
    webUrl
    customAttributes {
      key
      value
    }
    lineItems(first: 10) {
      edges {
        node {
          id
          quantity
          title
          customAttributes {
            key
            value
          }
          variant {
            id
            image {
              altText
              url
            }
            price {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
  }
`

export const RecordingCardFragment = gql`
  fragment RecordingCard on Recording {
    id
    title
    slug
    background_image
    thumbnail_image
    director
    duration
    description
    released
    youtube_video_id
    youtube_publish_date
    vimeo_video_id
    embed_override_url
    soundcloud_track_id

    formats {
      id
      title
    }

    city {
      name
      timezone
    }

    show {
      id
      title
      broadcast_status
      start
      date_display_format
      city {
        id
        name
        timezone
      }
    }

    genres {
      id
      title
    }

    series {
      id
      title
      type
      slug
      logo_image
    }
  }
`

export const RecordingFragment = gql`
  fragment FullRecording on Recording {
    # metadata
    id
    title
    description
    released
    duration
    director
    film_release_date
    downloads_allowed
    featured_in_partner_advertisement
    web_url
    slug
    redirect_url
    requires_login
    show_background_image

    geoblocked
    has_geotargets

    digital_products {
      id
      title
      slug
      price
      help_text
      has_purchased
      stripe_client_secret
    }

    # media
    audio_file
    dailymotion_video_id
    youtube_video_id
    youtube_publish_date
    vimeo_video_id
    embed_override_url
    soundcloud_track_id
    soundcloud_track_permalink_url

    # visuals
    background_image
    logo_image
    thumbnail_image
    trailer_video

    city {
      id
      name
      timezone
      slug
    }

    # links
    show {
      id
      title
      start
      end
      date_display_format
      slug

      background_image
      banner_image
      flyer_image
      trailer_video

      city {
        id
        name
        timezone
        slug
      }
    }
    genres {
      title
      id
      slug
    }
    series {
      id
      title
      logo_image
      type
      slug
    }
    formats {
      title
      id
      slug
    }
    artists {
      id
      slug
      name
      image
      hide_artist
    }
    brand {
      ...FullBrand
    }
    sponsor {
      id
      url
      url_label
      logo_image
    }
    playlists {
      slug
      items(limit: 8) {
        title
      }
    }
  }
  ${BrandFragment}
`

export const ShowCardFragment = gql`
  fragment ShowCard on Show {
    id
    title

    city {
      id
      name
      timezone
    }

    flyer_image
    broadcast_status
    start
    end
    slug
    hide_artists
    dice_event_id
    signup_enabled
    rsvp_enabled
    cta_enabled
    cta_text
    date_display_format
  }
`

export const ShowFragment = gql`
  fragment FullShow on Show {
    # metadata
    id
    title
    description
    start
    end
    broadcast_status
    minimum_age
    web_url
    slug
    redirect_url
    requires_login
    summary
    hide_artists
    date_display_format

    accessibility_information_not_required
    accessibility_information

    email_subscription_enabled
    email_subscription_text
    email_subscription_complete_text
    email_subscription_tags
    email_subscription_text_for_opted_in_users

    geoblocked
    has_geotargets

    digital_products {
      id
      title
      slug
      price
      help_text
      has_purchased
      stripe_client_secret
    }

    # rsvp form
    phone_number_required
    rsvp_enabled
    cta_enabled
    cta_url
    cta_text
    dice_event_id
    user_rsvp_status {
      id
      status
      default_additional_guests
      additional_guests
      name
    }

    consent_boxes {
      id
      label
      required_for_rsvp
    }

    # event
    additional_details
    event_end
    event_start
    venue_details
    video_call_url

    # media
    youtube_video_id
    streams {
      id
      embed_override_url
      title
      youtube_video_id
      room
      broadcast_status
      scheduled_start_time
    }
    embed_override_url

    # visuals
    background_image
    thumbnail_image
    banner_image
    flyer_image
    trailer_video
    logo_image

    genres {
      title
      slug
      id
    }

    sponsor {
      url
      url_label
      logo_image
    }

    recordings {
      ...FullRecording

      suggestions {
        ...FullRecording
        genres {
          title
        }

        sponsor {
          name
          url
          logo_image
        }
      }
    }

    city {
      id
      name
      timezone
      slug
    }

    suggestions {
      ...FullRecording
      genres {
        title
      }

      sponsor {
        name
        url
        logo_image
      }
    }

    artists {
      id
      name
      title
      description
      image
      slug
      hide_artist
      socials {
        website
        facebook
        twitter
        soundcloud
        instagram
        youtube
      }
    }

    brand {
      ...FullBrand
    }

    signup_enabled
    signup {
      ...FullSignup
    }
  }
  ${SignupFragment}
  ${RecordingFragment}
  ${BrandFragment}
`

export const ProfileFragment = gql`
  fragment FullProfile on Profile {
    email
    uuid

    favourite_recordings {
      ...FullRecording
    }

    favourite_cities {
      id
      name
      slug
    }

    consents {
      title
      given
      list_id
      date
    }
  }
  ${RecordingFragment}
`

export const GenericItemFragment = gql`
  fragment FullGenericItem on GenericItem {
    id
    title
    summary

    thumbnail_image
    background_image
    trailer_video
    logo_image

    url
    slug
    youtube_video_id
    requires_login

    type

    brand {
      ...FullBrand
    }

    recording {
      id
      released
      duration
      soundcloud_track_id
      youtube_video_id
      youtube_publish_date
      vimeo_video_id
      slug
      title
      genres {
        id
        title
      }
      city {
        id
        name
        timezone
        slug
      }
      show {
        id
        start
        flyer_image
        date_display_format
        city {
          id
          name
          timezone
          slug
        }
      }
      playlists {
        slug
        items(limit: 8) {
        title
      }
      }
    }

    show {
      id
      start
      end
      broadcast_status
      flyer_image
      date_display_format
      city {
        id
        name
        timezone
        slug
      }
      rsvp_enabled
      cta_text
      cta_url
      cta_enabled
      dice_event_id
      signup_enabled
      signup {
        ...FullSignup
      }
      slug
    }

    sponsor {
      id
      url
      thumbnail_image
      background_image
    }

    artist {
      id
      slug
      name
      image
    }
  }
  ${BrandFragment}
  ${SignupFragment}
`

export const ThemeFragment = gql`
  fragment FullTheme on Theme {
    header_background_color
    header_background_image
    header_link_color
    background_color

    heading_font_file
    heading_font_color

    body_text_color

    enable_hero_panel_header
    hero_panel_header_background_image
    hero_panel_header_background_video
    hero_panel_header_content

    cta_color
    cta_text
    cta_link
    cta_background_color

    footer_title
    footer_description
    footer_cta_text
    footer_cta_link
    footer_background_color
    footer_background_image
  }
`
