import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client'
import Cookies from 'js-cookie'
import {setContext} from '@apollo/client/link/context'
import fetch from 'cross-fetch'
import stashlessUrl from '../../lib/stashless'
import getRequestHeaders from './getRequestHeaders'

export const defaultClient = 'stashless'

// access headers set in useMutation and via previousContext
const setStashlessContext = setContext((request, previousContext) => ({
  headers: {
    authorization: Cookies.get('accessToken') ? `Bearer ${Cookies.get('accessToken')}` : null,
    ...getRequestHeaders(),
    ...previousContext.headers,
  },
}))

const stashlessLink = new HttpLink({
  uri: `${stashlessUrl}/graphql`,
  useGETForQueries: true,
  credentials: 'include',
  fetch,
})

const clients = {
  'stashless': setStashlessContext.concat(stashlessLink),
  'shopify': new HttpLink({
    uri: 'https://boiler-room-tv.myshopify.com/api/2023-10/graphql.json',
    fetch,
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_ACCESS_TOKEN,
    },
  }),
}

const isRequestedClient = clientName => op => {
  return op.getContext().clientName === clientName
}

const ClientResolverLink = Object.entries(clients)
  .reduce(([_, PreviousLink], [clientName, NextLink]) => {
    const ChainedLink = ApolloLink.split(
      isRequestedClient(clientName),
      NextLink,
      PreviousLink
    )

    return [clientName, ChainedLink]
  }, ['_default', clients[defaultClient]])[1]

const webCache = new InMemoryCache()

export const webClient = () => new ApolloClient({
  cache: webCache.restore(window.__APOLLO_STATE__.apollo),
  link: ClientResolverLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export const ssrClient = () => new ApolloClient({
  ssrMode: true,
  link: ClientResolverLink,
  cache: new InMemoryCache(),
})
