// @flow
const defaultRatio = typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1

export default (
  uri: string,
  width: number,
  height?: ?number,
  overridePixelRatio?: ?number,
  overrideFitType?: ?string,
) => {
  const ratio = overridePixelRatio || defaultRatio
  const fitType = overrideFitType || 'cover'
  const imageWidth = Math.floor(width * ratio)
  const imageHeight = height ? `height=${Math.floor(height * ratio)},` : ''

  return uri
    ? `https://boilerroom.tv/cdn-cgi/image/width=${imageWidth},${imageHeight}fit=${fitType},format=auto/` + uri
    : ''
}
