// @flow
import type {ShowType} from './types'
import {showDateFormats} from './constants'

import getShowTime from './getShowTime'
import getShowDate from './getShowDate'

export default (show: ShowType, disableShowTimes?: boolean) => {
  if (!show || show.date_display_format === showDateFormats.hideDateAndTime) return null
  if (show.date_display_format === showDateFormats.tba) return 'TBA'

  const showDate = getShowDate(show)
  if (showDate && (show.date_display_format === showDateFormats.monthYear || show.date_display_format === showDateFormats.dayMonthYear)) return showDate

  const showTime = getShowTime(show)

  if (show.date_display_format === showDateFormats.dayMonthYearStart || show.date_display_format === showDateFormats.dayMonthYearStartEnd) {
    return showTime && !disableShowTimes ? [showDate, showTime].join(', ') : showDate
  }

  return null
}
