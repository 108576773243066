// @flow

import type {RecordingType, LocationType} from './types'

export const recordingHasAudio = (recording: RecordingType) => !!recording.soundcloud_track_id

export const recordingHasVideo = (recording: RecordingType) =>
  recording && !!(recording.youtube_video_id || recording.vimeo_video_id || recording.embed_override_url)

export const recordingOnlyHasAudio = (recording: RecordingType) =>
  recording && !!(recording.soundcloud_track_id && !recordingHasVideo(recording))

export const recordingHasAudioAndVideo = (recording: RecordingType) =>
  recording && !!(recording.soundcloud_track_id && recordingHasVideo(recording))

export const recordingHasNoMedia = (recording: RecordingType) =>
  recording && !!(!recording.soundcloud_track_id && !recordingHasVideo(recording))

export const recordingHasMedia = (recording: RecordingType) =>
  recording && (recordingHasVideo(recording) || recordingHasAudio(recording))

// checks if the path is /audio and the recording has a soundcloud id.
// checks if the path is /recording OR /film and the recording has no video content.
export const isAudioMode = (recording: RecordingType, location: LocationType) =>
  (location.pathname.indexOf('/audio/') === 0 && recordingHasAudio(recording)) ||
  ((location.pathname.indexOf('/recording/') === 0 || location.pathname.indexOf('/film/') === 0) &&
    recordingOnlyHasAudio(recording))
