import {combineReducers, type Reducer} from 'redux'
import {reducer as formReducer} from 'redux-form'

import auth from './components/Auth/state'
import gate from './components/Gate/state'
import page from './components/Page/state'
import show from './components/Show/state'
import shop from './components/Shop/state'
import blocker from './components/Blocker/state'
import notifications from './components/Notifications/state'
import scrolling from './components/Scrolling/state'
import player from './components/PersistentPlayer/state'

export default ({brand, loadLocation, brands, geoLocation}: {[*]: Reducer<*, *>}): Reducer<*, *> =>
  combineReducers({
    auth,
    page,
    show,
    form: formReducer,
    brand,
    brands,
    loadLocation,
    geoLocation,
    gate,
    shop,
    blocker,
    notifications,
    scrolling,
    player,
  })
