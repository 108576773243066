export default [
  {
    header: 'Artists',
    body: `We collaborate with artists and filmmakers to platform work. We are interested in stories
      from the underground, exploring themes of performance, identity, youth culture and anti-establishment.`,
  },
  {
    header: 'Brands',
    body: 'We work with brands to offer 100% share of voice through seasonal content commission and curation tied to campaign themes.',
  },
  {
    header: 'Festivals',
    body: 'We present bespoke programmes of screenings, talks, performance, parties and audio-visual installations at film, music and art festivals.',
  },
]
