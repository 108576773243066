// @flow
import React from 'react'
import CountUp from 'react-countup'
import {useQuery} from '@apollo/client'

import {brandIds} from '../../common/brands'
import {genericItemTypes, itemsPerPage, signupSources, uiLocations} from '../../common/constants'
import {itemize, itemizeCollection} from '../../common/itemize'
import type {MatchType} from '../../common/types'
import {sortShowsForCityPage} from '../../common/sortShowsForCityPage'

import Link from '../Link'
import Background from '../Background'
import Loading from '../Loading'
import NotFound from '../NotFound'
import Page from '../Page'
import {Results} from '../SearchResults/Component'
import Slider from '../Slider'
import Signup from '../Signup'
import Arrow from '../Svgs/Arrow'

import GET_CITY from './CityQuery'
import GET_CONTINENT from './ContinentQuery'
import mapTheme from './mapTheme.js'
import styles from './Location.less'

export default ({match}: {match: MatchType}) => {
  const isCity = match.path.includes(genericItemTypes.city)

  const {data, loading, error, fetchMore} = useQuery(isCity ? GET_CITY : GET_CONTINENT, {
    variables: {
      slug: match.params.slug,
      limit: itemsPerPage,
      offset: 0,
    },
  })

  const onLoadMore = (previousResult, {fetchMoreResult}) => {
    const location = previousResult.city ? 'city' : 'continent'
    const recordings = previousResult[location].recordings.concat(fetchMoreResult[location].recordings)
    const updated = {
      ...fetchMoreResult,
      [location]: {
        ...fetchMoreResult[location],
        recordings,
      },
    }
    return updated
  }

  if (data && !data.city && !data.continent) return <NotFound />
  if (!data && loading) return <Loading />
  if (error) return null

  const {city, continent} = data
  const location = city || continent
  let locationName = location.name.replace(' ', '+')

  if (locationName === 'Barcelona') locationName = 'Barcelona,Spain'

  const apiKey = 'AIzaSyC0F2sOQJUqodpWzfXhzSFlxtBenaeUYPk'
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${locationName}${
    isCity ? '&zoom=12' : ''
  }&scale=4&size=2880x1580&key=${apiKey}&style=${mapTheme}`

  // Signups are usually created in stash - this Signup is hardcoded as city Signups won't need changing by wider parts of the company and it has per-city custom text
  const citySignup = {
    background_image: '',
    background_video: '',
    slug: '',
    title: location.name,
    opt_in: true,
    page_publication_status: 'd',
    page_title: '',
    button_text: `Subscribe to ${location.name}`,
    text: `**Sign up to our mailing list for ${location.name} event&nbsp;updates**`,
    text_for_opted_in_members: `**Sign up to our mailing list for ${location.name} event updates**`,
    complete_text: '',
    tags: [],
    consents: [],
    requires_login: true,
    phone_number_enabled: false,
    phone_number_required: false,
  }

  const sortedEvents = sortShowsForCityPage(location.shows)

  return (
    <Page
      brandId={brandIds.boilerroom}
      title={location.name}
      disableHeaderOffset={true}
      enableSubscribeOverlay={true}
    >
      <div className={styles.Banner}>
        <Background className={styles.MapBackground} image={mapUrl} />
        <div className={styles.BannerText}>
          <Link internalLink={'/cities'} className={styles.Back} uiLocation={uiLocations.locationPageCitiesBackLink}>
            <Arrow direction='left' color='white' />
            <div>Cities</div>
          </Link>
          <div className={styles.Header}>{location.name}</div>
          <div className={styles.Stats}>
            <Stat title={'Parties'} total={location.shows_count} duration={2} />
            <Stat title={'Sets'} total={location.recordings_count} duration={3} />
            <Stat title={'Artists'} total={location.artists_count} duration={3} />
          </div>
          <Signup
            signup={citySignup}
            signupSource={signupSources.favouriteCity}
            favouriteItemId={location.id}
            favouriteItemType={genericItemTypes.city}
            uiLocation={uiLocations.cityPage}
            enableUnfavourite={true}
            item={itemize(location, genericItemTypes.city)}
            className={styles.CitySignup}
          />
        </div>
      </div>
      <Slider title='Events' items={itemizeCollection(sortedEvents, genericItemTypes.show)} />
      <div className={styles.Recordings}>
        <h3>Sets</h3>
        <Results
          items={itemizeCollection(location.recordings, genericItemTypes.recording)}
          itemsCount={location.recordings_count}
          loading={loading}
          loadMore={() =>
            fetchMore({
              variables: {offset: location.recordings.length},
              updateQuery: onLoadMore,
            })
          }
        />
      </div>
    </Page>
  )
}

const Stat = ({duration, title, total}) => (
  <div className={styles.Stat}>
    <CountUp className={styles.StatNumber} delay={1} duration={duration} end={total} useEasing={false} />
    <div className={styles.StatType}>{title}</div>
  </div>
)
