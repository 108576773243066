// @flow
import React from 'react'
import {useQuery} from '@apollo/client'
import queryString from 'query-string'

import striptags from 'striptags'

import {signupSources, uiLocations} from '../../common/constants'
import type {MatchType, SignupType, LocationType} from '../../common/types'

import Page from '../Page'
import Background from '../Background'
import Signup from '../Signup'
import NotFound from '../NotFound'

import styles from './SignupPage.less'

import SIGNUP_BY_SLUG from './Query'

type Props = {
  match: MatchType,
  location: LocationType,
}

type Data = {
  signup_by_slug: SignupType,
}

export default (props: Props) => {
  const {data, loading}: {data: Data, loading: boolean} = useQuery(SIGNUP_BY_SLUG, {
    variables: {
      slug: props.match.params.slug,
    },
  })

  if (loading) return null

  const signup = data && data.signup_by_slug
  const isEmbed = queryString.parse(props.location.search).embedded === 'true'

  if (!signup || signup.page_publication_status !== 'p') return <NotFound />

  return (
    <Page
      title={signup.page_title || striptags(signup.text)}
      disableFooterSignup={true}
      header={isEmbed && <div />}
      hideFooter={isEmbed}
      ssr={true}
      image={signup.background_image}
    >
      <div className={isEmbed ? styles.EmbedWrapper : styles.Wrapper}>
        <Background image={signup.background_image} video={signup.background_video} playing={true} muted={true} />
        <div className={styles.Content}>
          <Signup
            signup={signup}
            signupSource={signupSources.signupPageSubscribe}
            uiLocation={uiLocations.signupPage}
          />
        </div>
      </div>
    </Page>
  )
}
