// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'
import type {NotificationType} from '../../common/types'
import {notificationTypes} from '../../common/constants'

export const types = {
  addNotification: 'Notifications/addNotification',
  removeNotification: 'Notifications/removeNotification',
}

const plainActions = mapValues(types, type => createAction(type))

const setError = (message: string) => (dispatch, getState) =>
  setNotification(dispatch, getState, message, notificationTypes.error)

const setSuccess = (message: string) => (dispatch, getState) =>
  setNotification(dispatch, getState, message, notificationTypes.success)

const setInfo = (message: string) => (dispatch, getState) =>
  setNotification(dispatch, getState, message, notificationTypes.info)

const setNotification = (dispatch, getState, message, type) => {
  const notifications = getState().notifications.notifications
  if (!alreadyExists(notifications, message)) {
    dispatch(plainActions.addNotification({message, type}))
    // Keep timeout duration in sync with .less animation duration
    setTimeout(() => dispatch(plainActions.removeNotification(message)), 4000)
  }
}

export const alreadyExists = (notifications: Array<NotificationType>, message: string) =>
  notifications.some(notification => notification.message === message)

export const actions = {
  ...plainActions,
  // thunks
  setError,
  setInfo,
  setSuccess,
}

export type StateType = {
  notifications: Array<NotificationType>,
}

const initialState: StateType = {
  notifications: [],
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.addNotification:
      return {
        notifications: [...state.notifications, action.payload],
      }
    case types.removeNotification:
      return {
        notifications: state.notifications.filter(notification => notification.message !== action.payload),
      }
    default:
      return state
  }
}
