// @flow
import React from 'react'

import withDefault from '../../common/withDefault'
import {gutterSize} from '../../common/constants'

import styles from './Grid.less'

export default class Grid extends React.Component {
  props: {
    className?: string,
    style?: any,
    children: any,
    gutter?: boolean,
    justifyContent?: 'center' | 'flex-start' | 'flex-end',
  }

  render() {
    const gridClass = withDefault(this.props.gutter, true) ? styles.Grid : styles.GutterlessGrid
    const justifyContent = withDefault(this.props.justifyContent, 'flex-start')

    return (
      <div className={[gridClass, this.props.className].join(' ')} style={this.props.style}>
        <div className={styles.OverflowWrapper} style={{justifyContent}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const columnClass = (width, size) => (size ? styles[`Column-${width}-${size}`] : styles[`Column-${width}`])

export class GridItem extends React.Component {
  props: {
    width?: number,
    responsiveWidths?: {
      small: number,
      medium: number,
      large: number,
      extraLarge: number,
    },
    children?: any,
    className?: string,
    gutter?: boolean,
    style?: any,
  }

  render() {
    const {width, responsiveWidths, className, children} = this.props
    const gutter = withDefault(this.props.gutter, true)

    let classNames = [className]
    if (responsiveWidths) {
      Object.keys(responsiveWidths).map(size => classNames.push(columnClass(responsiveWidths[size], size)))
    } else if (width) {
      classNames.push(columnClass(width))
    } else {
      console.error('Please supply either width or responsiveWidth to GridItem')
    }

    const style = this.props.style || {}

    return (
      <div
        className={classNames.join(' ')}
        style={{
          ...style,
          paddingRight: gutter ? gutterSize : 0,
          flexGrow: 0,
        }}
      >
        {children}
      </div>
    )
  }
}
