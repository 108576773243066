// @flow
import React from 'react'

import Chevron from '../Svgs/Chevron'

import styles from './Dropdown.less'

type DropdownProps = {
  id?: string,
  onChange?: Function,
  options: Array<string>,
  className?: string,
  value?: string,
  label?: string,
  required?: boolean,
  highlightRequired?: boolean,
}

export default class Dropdown extends React.PureComponent {
  props: DropdownProps
  render() {
    const {id, value, onChange, options, className, label, required, highlightRequired} = this.props

    const showRequired = required && highlightRequired && !value
    const selectClassname = showRequired ? styles.InvalidFormField : ''

    return (
      <label className={[styles.Dropdown, className].join(' ')} htmlFor={id}>
        {label && <p className={styles.Label}>{label}</p>}
        <select id={id} onChange={onChange} value={value} className={selectClassname}>
          <option value='' disabled selected hidden />
          {options.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        <Chevron />
      </label>
    )
  }
}
