import {connect} from 'react-redux'

import type {State} from '../../stateType'

import Component from './Component'
import {actions} from '../Shop/state'

const mapStateToProps = ({shop}: State) => ({
  checkoutId: shop.checkoutId,
  shopLocale: shop.shopLocale,
})

const mapDispatchToProps = {
  removeCheckout: actions.removeCheckout,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
