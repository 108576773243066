// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import {actions} from './state'
import Component from './Component'

const mapStateToProps = ({blocker}: State) => blocker

const mapDispatchToProps = {
  setPassword: actions.setPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
