import {gql} from '@apollo/client'
import {SignupFragment} from '../../common/fragments'

export default gql`
  query SignupBySlugQuery($slug: String!) {
    signup_by_slug(slug: $slug) {
      ...FullSignup
    }
  }

  ${SignupFragment}
`
