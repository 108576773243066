// @flow
// $FlowFixMe
import React, {useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {useSelector, useDispatch} from 'react-redux'
import {captureException} from '@sentry/browser'
import FocusTrap from 'focus-trap-react'

import marked from 'marked'

import mailingListLegalText from '../../common/mailingListLegalText'
import {uiLocations} from '../../common/constants'
import {getUpdateMailingConsentArgs} from '../../common/consents'
import {brandIds} from '../../common/brands'
import type {BrandType} from '../../common/types'
import type {State as StateType} from '../../stateType'
import {actions as notificationActions} from '../Notifications/state'
import captureMutationErrors from '../../common/captureMutationErrors'

import Button from '../Button'

import styles from './OptInGate.less'

import UPDATE_MAILING_CONSENT from '../Signup/Mutation'
import SIGNUP_BY_SLUG from '../SignupPage/Query'
import {analytics} from '../../common/analytics'

export default ({tags}: {tags: Array<string>}) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const dispatch = useDispatch()

  const brand: BrandType = useSelector((state: StateType) => state.brand)
  const isFourThree = brand.id === brandIds.fourthree

  const {data, loading: queryLoading} = useQuery(SIGNUP_BY_SLUG, {
    variables: {
      slug: isFourThree ? 'fourthree-newsletter' : 'newsletter',
    },
    onError: () => console.error('There was an error fetching the footer signup.'),
  })
  const signup = data && data.signup_by_slug

  const [updateMailingConsent] = useMutation(UPDATE_MAILING_CONSENT)

  // Complete or fail silently
  const saveChoice = (given: boolean) => {
    setLoading(true)
    updateMailingConsent(getUpdateMailingConsentArgs(given, isFourThree, tags, uiLocations.optInGate))
      .then(({errors}) => {
        if (errors && errors.length > 0) {
          captureMutationErrors(errors)
          dispatch(notificationActions.setError('Opt-in error, please try again.'))
          return null
        }
        setLoading(false)
      })
      .catch(error => {
        captureException(error)
        console.log(error)
      })
  }

  const optIn = () => {
    analytics.track('subscribe', {ui_location: uiLocations.optInGate})
    setComplete(true)
    saveChoice(true)
  }

  const optOut = () => {
    analytics.track('opt-out', {ui_location: uiLocations.optInGate})
    setComplete(true)
    saveChoice(false)
  }

  if (queryLoading || complete) return null

  return (
    <FocusTrap>
      <div className={styles.Wrapper}>
        <div className={styles.Content}>
          <div dangerouslySetInnerHTML={{__html: marked(signup.text)}} />
          <Button className={styles.Button} text='SIGN UP TO THE MAILING LIST' onClick={optIn} loading={loading} />
          <p className={styles.SmallPrint} dangerouslySetInnerHTML={{__html: mailingListLegalText}} />
          <Button className={styles.NoThanks} disableDefaultStyles={true} text='No Thanks' onClick={optOut} loading={loading} />
        </div>
      </div>
    </FocusTrap>
  )
}
