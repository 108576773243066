export default [
  {
    title: 'About',
    content:
      'Boiler Room started with a webcam taped to a wall, opening a keyhole into London’s underground. Since 2010, we’ve built a unique archive spanning over 8000 performances by more than 5000 artists across 200 cities. Today, we remain true to that history. We support emerging artists. We tell stories from the fringes. We connect local dance floors to the wider&nbsp;world.',
  },
  {
    title: 'Artist Fees',
    content: `Boiler Room pays all artists for all broadcasted performances, full stop.
    ---
Artists have always been paid industry standard fees for all of our commercial (ticketed, branded) shows & projects. In June 2020, we integrated a universal artist fee for all editorial shows too. Since then, no artists have played on Boiler Room for free & all artists will continue to be paid going forward.

Prior to this, Boiler Room editorial shows – which are self-funded & free to attend – sometimes operated on a promotional exchange, where artists' fees were not covered. Although this was standard in the broadcast space at the time, we quickly recognised that exposure ≠ payment. Over a period of time, we reduced the number of projects we took on each year, introduced ticketed events & tours, & focused on new types of brand partnerships––all with a view towards financial sustainability and providing an appropriate level of support for every artist we work with.

Today, we continue to support the artists we work with through fees, funding and access to our global platform.
`,
  },
  {
    title: 'Open Dance Floor Policy',
    content: 'Read our [Open Dance Floor Policy](/page/open-dance-floor-policy).',
  },
  {
    title: 'Partnerships and Funding',
    content: `We founded Boiler Room without funding or investment. Over 8 years, we are proud to have built a business that has created 100s of jobs for young people and is a diverse, forward-thinking team rooted in club culture.

Boiler Room’s in-house events and content are free to access. For this model to work, we’ve taken investment from people who believe in our vision, alongside brand partnerships that generate revenue. This has allowed us to remain an independent publisher, whilst celebrating and contributing to the culture we love; funding non-sponsored events, films and community work.

Our vision is to create a sustainable business that can continue to do so.

Get in touch via <a href="mailto:partnerships@boilerroom.tv">partnerships@boilerroom.tv</a>`,
  },
  {
    title: 'Working at Boiler Room',
    content: `Boiler Room is always on the lookout for hard-working people with a passion for music and culture to join the team. We’re committed to maintaining a diverse pool of talent, hiring in a wide range of departments from film and music to operations and accounts.

Please see all our current <a href="https://boilerroom.tv/jobs/" target="_blank">opportunities here</a>`,
  },
]
