// @flow
export default function (number: number, maximum: number, minimum?: number = 0): number {
  if (number > maximum) {
    return minimum
  } else if (number < minimum) {
    return maximum
  } else {
    return number
  }
}
