import React from 'react'

import Button from '../Button'
import Link from '../Link'

import {itemsPerPage} from '../../common/constants'
import cycle from '../../common/cycle'

import styles from './Pagination.less'

export default ({itemCount, type, slug, page}) => {
  const numberOfPages = itemCount <= itemsPerPage ? 1 : Math.floor(itemCount / itemsPerPage) + 1
  const activePage = parseInt(page || 1, 10)
  const pathname = slug ? `/${type}/${slug}/` : `/${type}/`

  if (numberOfPages === 1) return null

  // Ensure /1 always stripped
  const previousLink = `${pathname}${activePage !== 2 ? cycle(activePage - 1, numberOfPages, 1) : ''}`
  const nextLink = `${pathname}${activePage !== numberOfPages ? cycle(activePage + 1, numberOfPages) : ''}`

  return (
    <div className={styles.Pagination}>
      <Link internalLink={previousLink}>
        <Button text='Previous' className={styles.Button} />
      </Link>

      <div className={styles.Pages}>
        {Array.from({length: numberOfPages}, (value, index) => {
          const page = index + 1

          return (
            <Link
              key={`page-${index}`}
              internalLink={`${pathname}${page === 1 ? '' : page}`}
              className={activePage === page ? styles.ActivePage : styles.Page}
            >
              {index + 1}
            </Link>
          )
        })}
      </div>

      <Link internalLink={nextLink}>
        <Button text='Next' className={styles.Button} />
      </Link>
    </div>
  )
}
