// @flow
import React from 'react'

import arrowIcon from './whiteArrow.svg'

import styles from './Form.less'

type FormProps = {
  action?: string,
  children?: any,
  className?: string,
  controls?: Function,
  controlsText?: string,
  header?: string,
  method?: string,
  noValidate?: boolean,
  onSubmit?: Function,
  overrideRef?: Function,
  target?: string,
}

export default class Form extends React.PureComponent {
  ref: any
  props: FormProps

  setupRef = (ref: any) => {
    this.ref = ref
  }

  render() {
    const {
      action,
      children,
      className,
      controls,
      controlsText,
      header,
      method,
      noValidate,
      onSubmit,
      overrideRef,
      target,
    } = this.props

    const classes = [styles['Container'], className].join(' ')

    return (
      <div className={classes}>
        {header && <FormHeader controls={controls} controlsText={controlsText} header={header} />}
        <form
          action={action}
          method={method}
          noValidate={noValidate || null}
          onSubmit={onSubmit}
          target={target}
          ref={overrideRef || this.setupRef}
        >
          {children}
        </form>
      </div>
    )
  }
}

class FormHeader extends React.PureComponent {
  render() {
    const {controls, controlsText, header} = this.props

    return (
      <div className={styles.Header}>
        <h3>{header}</h3>
        {controls && <FormControls controls={controls} controlsText={controlsText} />}
      </div>
    )
  }
}

class FormControls extends React.PureComponent {
  render() {
    const {controls, controlsText} = this.props
    return (
      <div onClick={controls} className={styles.Controls}>
        <img src={arrowIcon} className={styles.ArrowIcon} alt='' />
        <span>{controlsText}</span>
      </div>
    )
  }
}
