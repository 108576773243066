// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import Component from './Component'

const mapStateToProps = ({brand, page}: State) => ({
  brand,
  pageSize: page.pageSize,
})

export default connect(mapStateToProps)(Component)
