import React from 'react'

const TicketIcon = ({color}: {color?: string}) => {
  const fill = color || '#fff'

  return (
    <svg
      width='23'
      height='13'
      viewBox='0 0 23 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.3154 1.53845V5.53845H17.3154C17.0393 5.53845 16.8154 5.31459 16.8154 5.03845V1.03845H18.4154H21.8154C22.0916 1.03845 22.3154 1.26231 22.3154 1.53845ZM15.8154 1.03845V5.03845C15.8154 5.86688 16.487 6.53845 17.3154 6.53845H22.3154V11.5385C22.3154 11.8146 22.0916 12.0385 21.8154 12.0385H18.4154H5.21543H1.81543C1.53929 12.0385 1.31543 11.8146 1.31543 11.5385V1.53845C1.31543 1.26231 1.53929 1.03845 1.81543 1.03845H5.21543H15.8154ZM3.81543 3.03845C3.53929 3.03845 3.31543 3.26231 3.31543 3.53845C3.31543 3.81459 3.53929 4.03845 3.81543 4.03845H8.81543C9.09157 4.03845 9.31543 3.81459 9.31543 3.53845C9.31543 3.26231 9.09157 3.03845 8.81543 3.03845H3.81543ZM3.81543 9.03845C3.53929 9.03845 3.31543 9.26231 3.31543 9.53845C3.31543 9.81459 3.53929 10.0385 3.81543 10.0385H17.3154C17.5916 10.0385 17.8154 9.81459 17.8154 9.53845C17.8154 9.26231 17.5916 9.03845 17.3154 9.03845H3.81543ZM3.81543 6.03845C3.53929 6.03845 3.31543 6.26231 3.31543 6.53845C3.31543 6.81459 3.53929 7.03845 3.81543 7.03845H12.8154C13.0916 7.03845 13.3154 6.81459 13.3154 6.53845C13.3154 6.26231 13.0916 6.03845 12.8154 6.03845H3.81543Z'
        stroke={fill}
        strokeLinecap='round'
      />
    </svg>
  )
}

export default TicketIcon
