// @flow
import React from 'react'

import Page from '../Page'

import SizeItems from './SizeItems'
import styles from './SizeGuide.less'

export default class SizeGuide extends React.Component {
  render() {
    return (
      <Page title='Size Guide' disableHeaderOffset={true} invertColours={true} iconColour='#000'>
        <div className={styles.SizeGuide}>
          <h3>SIZE GUIDE</h3>
          <SizeGuideTable />
        </div>
      </Page>
    )
  }
}

const SizeGuideTable = () => (
  <table className={styles.SizeGuideTable}>
    <tbody>
      <tr>
        <th>SIZE</th>
        {SizeItems.map((item, index) => (
          <th key={index}>{item.size}</th>
        ))}
      </tr>
      <tr>
        <th>WIDTH (in.)</th>
        {SizeItems.map((item, index) => (
          <td key={index}>{item.width}</td>
        ))}
      </tr>
      <tr>
        <th>LENGTH (in.)</th>
        {SizeItems.map((item, index) => (
          <td key={index}>{item.length}</td>
        ))}
      </tr>
    </tbody>
  </table>
)
