import {gql} from '@apollo/client'

export default gql`
  query CitiesGridQuery {
    cities {
      name
      slug
    }
  }
`
