// @flow
import React from 'react'
import {Redirect} from 'react-router-dom'

import type {BrandType, ProfileType} from '../../common/types'

import AccountNavigation from '../AccountNavigation'
import Button from '../Button'
import Loading from '../Loading'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import Preferences from '../Preferences'
import Page from '../Page'
import FavouriteCities from '../FavouriteCities'

import styles from './Account.less'
import {hasWindow} from '../../common/constants'

type ProfileProps = {
  shouldRedirect: boolean,
  brand: BrandType,
  data: {
    profile: ProfileType,
  },
  loading: boolean,
  logout: () => void,
}

export default ({shouldRedirect, loading, logout, data}: ProfileProps) => {
  if (shouldRedirect) {
    return <Redirect to={'/login'} />
  }

  const hasProfile = hasWindow && data && data.profile

  return (
    <Page allBrandPage={true} title='Account'>
      <AccountNavigation />
      {hasProfile ?
        <BrandedStyleWrapper styles={styles} classNameKey={'Wrapper'}>
          <div className={styles.ContainerColumn}>
            <h4>EMAIL</h4>
            <p>{data.profile.email}</p>

            <FavouriteCities cities={data && data.profile && data.profile.favourite_cities} />

            <Preferences />

            <Button className={styles.Button} text='Log Out' onClick={logout} />
          </div>
        </BrandedStyleWrapper> :
        <Loading />
      }
    </Page>
  )
}
