import {gql} from '@apollo/client'

export default gql`
  query GenericPageQuery($slug: String!) {
    page_by_slug(slug: $slug) {
      id
      title
      slug
      description
      background_image
      thumbnail_image
      content_markdown
      background_color
      text_color
      brand {
        id
      }
      released
      is_shop_page
    }
  }
`
