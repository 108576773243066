// @flow
import React from 'react'
import {useSelector} from 'react-redux'
import marked from 'marked'

import type {State as StateType} from '../../stateType'

import type {PageSize} from '../../common/types'
import type {ShopCurrency} from '../../common/constants'
import {currencyCodes, pageSizes, shopCurrencies, currencySymbols, dropdowns, hasWindow, uiLocations} from '../../common/constants'
import {analytics} from '../../common/analytics'
import {getShopCurrencyCodeFromCountryCode} from '../Shop/state'

import BasketMenu from '../BasketMenu'
import Link from '../Link'
import NavigationLink from '../NavigationLink'
import Button from '../Button'

import styles from './ShopNav.less'

type ShopProps = {
  activeSlug?: ?string,
  shopLocale: string,
  changeShopLocale: string => void,
  web_settings: {
    shop_nav_banner_text: string,
  },
  navigation: {
    navigation_elements: Array<{
      name: string,
      link: string,
    }>,
  },
}

const getSlug = (link: string) => {
  return link.split('/')[link.split('/').length - 1]
}

const ShopNavComponent = (props: ShopProps) => {
  const pageSize: PageSize = useSelector((state: StateType) => state.page.pageSize)
  const mobileView = pageSize === pageSizes.small || pageSize === pageSizes.medium
  const shopNoticeMessage = props.web_settings && props.web_settings.shop_nav_banner_text
  const shopNavCollection = props.navigation && props.navigation.navigation_elements
  const currency = getShopCurrencyCodeFromCountryCode(props.shopLocale)
  // $FlowFixMe
  const [openDropdown, setOpenDropdown] = React.useState('')

  const setShopLocale = (currency: ShopCurrency) => {
    props.changeShopLocale(currency.countryCodes[0])
  }

  const handleDropdownOpen = ({dropdown, open}:{dropdown: string, open: boolean}) => {
    if (!open) {
      return setOpenDropdown('')
    }

    setOpenDropdown(dropdown)
  }

  const isActiveDropdown = (dropdown: string) => {
    return openDropdown === dropdown
  }

  return (
    <React.Fragment>
      <div className={styles.Buffer} />

      <div className={styles.Container}>
        {mobileView ?
          <MobileNav activeSlug={props.activeSlug} shopNavCollection={shopNavCollection} handleDropdownOpen={handleDropdownOpen} open={isActiveDropdown(dropdowns.collections)} /> :
          <Collections shopNavCollection={shopNavCollection} />
        }

        <div className={styles.Links}>

          {hasWindow &&
            <CurrencyDropdown
              isMobile={mobileView}
              currency={currency}
              setShopLocale={setShopLocale}
              handleDropdownOpen={handleDropdownOpen}
              open={isActiveDropdown(dropdowns.currency)}
            />
          }

          <Link internalLink='/page/support' className={styles.SupportLink}>
            Support
          </Link>

          <BasketMenu menuButtonClass={styles.BasketMenu} iconColour='#000' />
        </div>
      </div>

      <Notice message={shopNoticeMessage} />
    </React.Fragment>
  )
}

const Collections = ({shopNavCollection}) => (
  <div className={styles.ShopNavCollections}>
    {shopNavCollection &&
      shopNavCollection.map((item, index) => (
        <NavigationLink
          key={index}
          item={item}
          className={styles.Link}
          activeClassName={styles.ActiveLink}
          uiLocation={uiLocations.shopNavLink}
        />
      ))}
  </div>
)

class MobileNav extends React.Component {
  constructor(props) {
    super(props)
    document.addEventListener('scroll', this.closeNav)
  }

  closeNav = () => {
    this.props.handleDropdownOpen({dropdown: 'collections', open: false})
  }

  handleOnClick = () => {
    this.props.handleDropdownOpen({dropdown: 'collections', open: !this.props.open})
  }

  render() {
    const open = this.props.open

    const shopNavCollection = this.props.shopNavCollection
    const activeCollection =
      shopNavCollection && shopNavCollection.find(({link}) => getSlug(link) === this.props.activeSlug)
    const navHeading = (activeCollection && activeCollection.name) || 'All'
    return (
      <div className={styles.MobileNav}>
        <Button
          className={open ? styles.NavTitle_open : styles.NavTitle}
          onClick={this.handleOnClick}
          disableDefaultStyles={true}
          text={navHeading}
        />

        <div className={open ? styles.Collections_MobileOpen : styles.Collections_Mobile} onClick={this.handleOnClick}>
          <Collections shopNavCollection={shopNavCollection} />
        </div>
      </div>
    )
  }
}

class CurrencyDropdown extends React.Component {
  constructor(props) {
    super(props)
    document.addEventListener('scroll', this.closeDropdown)
  }

  closeDropdown = () => {
    this.props.handleDropdownOpen({dropdown: 'currency', open: false})
  }
  handleOnClick = () => {
    this.props.handleDropdownOpen({dropdown: 'currency', open: !this.props.open})
  }
  handleSelectCurrency = (currency: ShopCurrency) => {
    this.props.setShopLocale(currency)
    this.closeDropdown()

    analytics.track('shop_user_changed_currency', {currency: currency.code}, ['ga'])
  }

  render() {
    const open = this.props.open
    const isMobile = this.props.isMobile
    const selectedCurrency = shopCurrencies.find(entry => entry.code === this.props.currency)
    const titleCurrencyString = selectedCurrency ? `${selectedCurrency.symbol} ${selectedCurrency.code}` : `${currencySymbols.usd} ${currencyCodes.usd}`

    return (
      <div className={styles.CurrencyNav}>
        <Button
          className={styles[`CurrencyNavTitle${isMobile ? '_Mobile' : ''}${open ? '_open' : ''}`]}
          onClick={this.handleOnClick}
          disableDefaultStyles={true}
          text={titleCurrencyString}
        />

        <div className={styles[`CurrenciesDropdown${isMobile ? '_Mobile' : ''}${open ? '_Open' : ''}`]}>
          {shopCurrencies.map((entry, index) => {
            const currencyString = `${entry.symbol} ${entry.code}`
            return (
              <Button
                key={index}
                className={styles[`CurrencyButton${currencyString === titleCurrencyString ? '_Active' : ''}`]}
                onClick={() => this.handleSelectCurrency(entry)}
                text={currencyString}
                disableDefaultStyles
              />
            )
          })}
        </div>
      </div>
    )
  }
}

const Notice = ({message}: {message: ?string}) => {
  if (!message) return null

  return <div className={styles.Notice} dangerouslySetInnerHTML={{__html: marked(message)}} />
}

export default ShopNavComponent
