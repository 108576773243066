// @flow
import {useSelector} from 'react-redux'

import type {BrandType} from '../../common/types'
import type {State as StateType} from '../../stateType'
import {brandIds} from '../../common/brands'

type Props = {
  children?: any,

  brandContents?: ?(isFourThree: boolean, isLowHeat: boolean, isGasworks: boolean, isFestival: boolean) => any,
}

export default (props: Props) => {
  const brand: BrandType = useSelector((state: StateType) => state.brand)
  const {brandContents, children} = props

  const isFourThree = brand && brand.id === brandIds.fourthree
  const isLowHeat = brand && brand.id === brandIds.lowheat
  const isGasworks = brand && brand.id === brandIds.gasworks
  const isFestival = brand && brand.id === brandIds.festival
  const contents = brandContents ? brandContents(isFourThree, isLowHeat, isGasworks, isFestival) : children
  return contents
}
