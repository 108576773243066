import {broadcastStates, genericItemTypes} from '../../common/constants'

export default function (item) {
  if (item.type === genericItemTypes.recording) return 'PLAY NOW'
  if (item.type === genericItemTypes.playlist) return 'VIEW ALL'
  if (item.type === genericItemTypes.article) return 'READ MORE'
  if (item.type === genericItemTypes.show && item.show) {
    switch (item.show.broadcast_status) {
      case broadcastStates.upcoming:
      case broadcastStates.future:
        return item.show.rsvp_enabled ? item.show.cta_text || 'REQUEST INVITE' : 'MORE INFO'
      case broadcastStates.offair:
        return 'MORE INFO'
      case broadcastStates.live:
      case broadcastStates.relooping:
      case broadcastStates.archived:
        return 'WATCH NOW'
    }
  }
  return 'MORE INFO'
}
