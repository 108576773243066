import React from 'react'

import {uiLocations} from '../../common/constants'

import Link from '../Link'
import Arrow from '../Svgs/Arrow'

import styles from './FindYourCityLink.less'

export default () =>
  <Link className={styles.FindYourCity} internalLink={'/cities'} uiLocation={uiLocations.findYourCityLink}>
    FIND YOUR CITY
    <span className={styles.Arrow}>
      <Arrow direction='right' />
    </span>
  </Link>
