// @flow
import React from 'react'
import styles from './Input.less'

import withDefault from '../../common/withDefault'

type InputProps = {
  autoFocus?: boolean,
  className?: string,
  style?: any,
  correct?: mixed,
  disabled?: boolean,
  id?: string,
  input?: any,
  meta?: Object,
  name?: string,
  onChange?: Function,
  placeholder?: string,
  required?: boolean,
  type?: 'email' | 'date' | 'number' | 'password' | 'text' | 'tel',
  value: string,
  label?: ?string,
  highlightRequired?: boolean,
  maxLength?: number,
  pattern?: string,
  autoComplete?: string,
  autoCorrect?: string,
  displayEmailError?: boolean,
}

export default class Input extends React.PureComponent {
  props: InputProps

  render() {
    const {
      autoFocus,
      className,
      disabled,
      id,
      input,
      name,
      onChange,
      placeholder,
      required,
      type,
      value,
      label,
      highlightRequired,
      maxLength,
      pattern,
      autoComplete,
      autoCorrect,
      displayEmailError,
      style,
    } = this.props

    const classes = [styles['Input'], className].join(' ')
    const showRequired = required && !this.props.correct && highlightRequired
    const correct = value.length === 0 || withDefault(this.props.correct, true)
    const isRequiredClass = showRequired ? styles.RequiredFormField : styles.ValidFormField
    const inputClassName = !correct ? styles.InvalidFormField : isRequiredClass

    return (
      <label htmlFor={id} className={classes}>
        {label && <p className={styles.Label}>{label}</p>}
        <input
          {...input}
          className={inputClassName}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={type || 'text'}
          required={required || null}
          value={value}
          autoFocus={autoFocus}
          maxLength={maxLength}
          pattern={pattern}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          style={style}
        />
        {displayEmailError && <p className={styles.ErrorMessage}>Enter a valid email</p>}
      </label>
    )
  }
}
