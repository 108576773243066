import {gql} from '@apollo/client'

export default gql`
  query getRecordingsByDate($yt_published_from: String, $yt_published_to: String) {
    recordings(yt_published_from: $yt_published_from, yt_published_to: $yt_published_to, orderBy: "youtube_publish_date") {
      recordings {
        id
        title
        youtube_publish_date
        soundcloud_track_id
        slug
        thumbnail_image
        youtube_video_id
        duration
        description
        summary
        show {
          title
          slug
          start
        }
      }
    }
  }
`
