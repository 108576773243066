// @flow
import React from 'react'

import styles from './DatePicker.less'

type Props = {
  onChange: Function,
  value: string,
  required?: boolean,
  className?: string,
  label?: string,
  highlightRequired?: boolean,
  correct?: boolean,
}

type State = {
  year: string,
  month: string,
  day: string,
}

export default class DatePicker extends React.Component {
  state: State
  props: Props

  constructor(props: Props) {
    super(props)

    const splitDate = props.value && props.value.split('-')

    this.state = {
      year: splitDate ? splitDate[0] : '',
      month: splitDate ? splitDate[1] : '',
      day: splitDate ? splitDate[2] : '',
    }
  }

  yearValid = () => /^(19|20)\d{2}$/.test(this.state.year)
  monthValid = () => /^(0?[1-9]|1[012])$/.test(this.state.month)
  dayValid = () => /^(0?[1-9]|[12]\d|3[01])$/.test(this.state.day)
  isValid = () => this.yearValid() && this.monthValid() && this.dayValid() && this.props.correct

  onChange = (datePart: 'year' | 'month' | 'day') => (e: any) => this.entryValid(datePart, e)

  entryValid = (datePart: 'year' | 'month' | 'day', e: any) => {
    const value = e.target.value
    switch (datePart) {
      case 'year':
        if (value.length < 5) this.updateField(datePart, value)
        break
      case 'month':
      case 'day':
        if (value.length < 3) this.updateField(datePart, value)
        break
    }
  }

  updateField = (datePart: 'year' | 'month' | 'day', value: string) => this.setState({[datePart]: value})

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {year, month, day} = this.state

    if (prevProps.value !== this.props.value) {
      const splitDate = this.props.value.split('-')
      this.setState({
        year: splitDate[0] || '',
        month: splitDate[1] || '',
        day: splitDate[2] || '',
      })
    }

    const hasChanged = prevState.year !== year || prevState.month !== month || prevState.day !== day
    if (hasChanged) this.props.onChange(`${year}-${month}-${day}`)
  }

  render() {
    const {required, className, label, highlightRequired} = this.props
    const {year, month, day} = this.state

    const isPopulated = year || month || day
    const showRequired = required && highlightRequired && !this.isValid()
    const validationStyle = this.isValid() || !isPopulated ? '' : styles.InvalidFormField
    const inputClassName = showRequired ? styles.RequiredFormField : validationStyle
    const currentYear = new Date().getFullYear()

    const blockInvalidCharacters = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

    return (
      <div className={[styles.DatePicker, className].join(' ')}>
        {label && <label className={styles.Label}>{label}</label>}
        <div className={styles.Inputs}>
          <input
            type='number'
            onChange={this.onChange('year')}
            value={year}
            required={required || null}
            placeholder='YYYY'
            className={inputClassName}
            min={1920}
            max={currentYear}
            onKeyDown={blockInvalidCharacters}
          />
          <input
            type='number'
            onChange={this.onChange('month')}
            value={month}
            required={required || null}
            placeholder='MM'
            className={inputClassName}
            min={1}
            max={12}
            onKeyDown={blockInvalidCharacters}
          />
          <input
            type='number'
            onChange={this.onChange('day')}
            value={day}
            placeholder='DD'
            required={required || null}
            className={inputClassName}
            min={1}
            max={31}
            onKeyDown={blockInvalidCharacters}
          />
        </div>
      </div>
    )
  }
}
