// @flow
import React from 'react'

import type {MatchType, CityType} from '../../common/types'

import Link from '../Link'
import Page from '../Page'
import Query from '../Query'
import Loading from '../Loading/'

import GET_CITIES from './Query'

import {brandIds} from '../../common/brands'

import styles from './Cities.less'
import {useSelector} from 'react-redux'
import type {State} from '../../stateType'
import Search from '../Search'
import Background from '../Background'

type Props = {match: MatchType}

export default (props: Props) => {
  return <Query Component={Cities} props={props} query={GET_CITIES} />
}

type CitiesProps = {
  loading: boolean,
  cities: ?Array<CityType>
}

const sortCities = (cities: Array<CityType>) => {
  const citiesByLetter = {}

  const uniqueFirstLetters = [...new Set(cities.map(city => city.name[0].toLocaleLowerCase()))].sort()

  uniqueFirstLetters.map(letter => {
    const citiesForLetter = cities.filter(city => city.name[0].toLocaleLowerCase() === letter)
    citiesByLetter[letter] = citiesForLetter.sort((a, b) => {
      const aName = a.name.toLocaleLowerCase()
      const bName = b.name.toLocaleLowerCase()

      if (aName < bName) return -1
      if (aName > bName) return 1
      return 0
    })
  })

  return citiesByLetter
}

export const Cities = ({loading, cities}: CitiesProps) => {
  if (loading || !cities) return <Loading />
  // $FlowFixMe
  const [search, setSearch] = React.useState('')
  // $FlowFixMe
  const [filteredCities, setFilteredCities] = React.useState([])
  // $FlowFixMe
  const sortedCities = React.useMemo(() => sortCities(cities))
  // $FlowFixMe
  const [showResults, setShowResults] = React.useState(false)
  const pageSize = useSelector((state: State) => state.page.pageSize)
  const totalItemsHeight = (cities.length + Object.keys(sortedCities).length) * 44

  const columnWidths = {
    small: 1,
    medium: 2,
    large: 3,
    extraLarge: 4,
  }
  const handleSearch = e => setSearch(e.target.value)

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => setShowResults(false), 100)
    }
  }

  const handleFocus = () => setShowResults(true)

  // $FlowFixMe
  React.useEffect(() => {
    if (!cities) return

    setFilteredCities(cities.filter(city => city.name.toLowerCase().includes(search.toLowerCase())))
  }, [search])

  return (
    <Page title='Cities' brandId={brandIds.boilerroom}>
      <Background image={'https://stash-archive-master-videos.s3.eu-west-2.amazonaws.com/assets/Globe.png'} />

      <div className={styles.Cities}>
        <h2>CITIES</h2>

        <div className={styles.FilterWrapper} onFocus={handleFocus} onBlur={handleBlur}>
          <Search
            value={search}
            onChange={handleSearch}
            placeholder={'Find your city'}
          />

          {showResults && search &&
            <div className={styles.FilterResults}>
              {filteredCities.length ?
                filteredCities.slice(0, 4).map(city => (
                  <Link internalLink={`/city/${city.slug}`} key={city.slug}>
                    <p key={city.name}>{city.name}</p>
                  </Link>
                )) :
                <p>No results.</p>
              }
            </div>}
        </div>

        <div className={styles.TopCitiesWrapper}>
          {cities.slice(0, 20).map(city => (
            <Link internalLink={`/city/${city.slug}`} key={city.slug}>
              <h3>{city.name.toLocaleUpperCase()}</h3>
            </Link>
          ))}
        </div>

        <div className={styles.LetterCitiesWrapper} style={{height: totalItemsHeight / columnWidths[pageSize]}}>
          {Object.keys(sortedCities).map(letter => (
            <React.Fragment key={letter}>
              <div className={styles.LetterTitle}>
                <h3>{letter}</h3>
              </div>

              {sortedCities[letter].map(city => (
                <Link internalLink={`/city/${city.slug}`} key={city.slug} className={styles.LetterCity}>
                  <h4 className={styles.CityTitle}>{city.name.toLocaleUpperCase()}</h4>
                </Link>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Page>
  )
}
