import {gql} from '@apollo/client'
import {GenericItemFragment, BrandFragment, ThemeFragment, SignupFragment} from '../../common/fragments'

export default gql`
  query HomeQuery($id: Int!, $limit: Int) {
    homepage(id: $id) {
      id
      description
      thumbnail_image
      background_image
      trailer_video
      display_title
      requires_login

      hide_slider_headers
      center_sliders
      remove_empty_space
      hide_shop_slider

      collections {
        id
        title
        description
        logo_image
        display_logo_and_title
        slug
        type
        requires_login
        summary
        homepage_display_mode

        theme {
          ...FullTheme
        }

        items(limit: $limit) {
          ...FullGenericItem
        }
      }

      sponsor {
        id
        name
        logo_image
        url
        socials {
          facebook
          twitter
          instagram
          soundcloud
          youtube
        }
      }

      display_title
      description
      logo_image

      theme {
        ...FullTheme
      }

      brand {
        ...FullBrand
      }

      signup_enabled
      signup {
        ...FullSignup
      }
    }
  }

  ${BrandFragment}
  ${GenericItemFragment}
  ${ThemeFragment}
  ${SignupFragment}
`
