// @flow
import React from 'react'

import type {ShowType, RecordingType} from '../../common/types'
import formatShowDateAndTime from '../../common/formatShowDateAndTime'

import Link from '../Link'
import ReleaseTimeInfo from '../ReleaseTimeInfo'

import styles from './BroadcastData.less'
import BrandedStyleWrapper from '../BrandedStyleWrapper'

type Props = {
  className?: string,
  show?: ?ShowType,
  recording?: ?RecordingType,
  disableCityLink?: boolean,
  disableShowTimes?: boolean,
}

export default ({className = '', show, recording, disableCityLink, disableShowTimes}: Props) => {
  if (show) {
    return (
      <ShowData
        show={show}
        className={className}
        disableCityLink={disableCityLink}
        disableShowTimes={disableShowTimes}
      />
    )
  }

  if (recording) {
    return (
      <RecordingData
        recording={recording}
        className={className}
        disableCityLink={disableCityLink}
      />
    )
  }

  return null
}

const RecordingData = ({recording, className, disableCityLink}) => {
  const recordingHasCityOrShowCity = recording.city || (recording.show && recording.show.city)

  const city = recording.city || recording.show && recording.show.city

  return (
    <div className={[className, styles.RecordingCardDataWrapper].join(' ')}>
      {(city &&
        <span className={styles.City}>
          {disableCityLink ?
            city.name :
            <Link className={styles.CityLink} internalLink={`/city/${city.slug}`}>
              {city.name}
            </Link>
          }
        </span>
      )}
      <ReleaseTimeInfo className={recordingHasCityOrShowCity && styles.ReleaseTime} recording={recording} />
    </div>
  )
}

const ShowData = ({show, className, disableCityLink, disableShowTimes}) => {
  const formattedShowDate = formatShowDateAndTime(show, disableShowTimes)

  return (
    <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
      <div className={[className, styles.ShowDataWrapper].join(' ')}>
        <div>
          {formattedShowDate &&
            <div className={styles.ShowDate}>
              {formattedShowDate}
            </div>
          }

          {show.city &&
            <div>
              <span>
                {disableCityLink ?
                  show.city.name :
                  <Link className={styles.CityLink} internalLink={`/city/${show.city.slug}`}>
                    {show.city.name}
                  </Link>
                }
              </span>
            </div>
          }
        </div>
      </div>
    </BrandedStyleWrapper>
  )
}
