// @flow
import React from 'react'

import GET_NAVIGATION from './query'

import Query from '../Query'
import NavigationLink from '../NavigationLink'
import AllVideosIcon from '../Svgs/AllVideosIcon'
import Bolt from '../Svgs/Bolt'
import ScheduleIcon from '../Svgs/Schedule'

import styles from './VideoScheduleNav.less'

type Props = {
  activeSlug?: ?string,
}

type NavProps = {
  activeSlug?: ?string,
  navigation: {
    navigation_elements: Array<{
      name: string,
      link: string,
    }>,
  },
}

export default (props: Props) => {
  return (
    <Query
      Component={VideoScheduleNav}
      query={GET_NAVIGATION}
      variables={{slug: 'video-page-sub-navigation'}}
      props={props}
      fetchPolicy='cache-first'
    />
  )
}

const getIconForLink = link => {
  if (link === '/video') {
    return <AllVideosIcon className={styles.LinkIcons} />
  }

  if (link === '/charts') {
    return <Bolt height='12' width='10' className={styles.LinkIcons} />
  }

  if (link === '/schedule') {
    return <ScheduleIcon className={styles.LinkIcons} />
  }
}

const VideoScheduleNav = (props: NavProps) => {
  const videoScheduleNavCollection = props.navigation && props.navigation.navigation_elements

  return (
    <div>
      <div className={styles.Buffer} />

      <div className={styles.Container}>
        <div className={styles.Links}>
          {videoScheduleNavCollection &&
            videoScheduleNavCollection.map((item, index) => (
              <NavigationLink
                key={index}
                item={item}
                className={styles.Link}
                activeClassName={styles.ActiveLink}
                isParentPath={item.name === 'Schedule'}
                icon={getIconForLink(item.link)}
                uiLocation={styles.videoNavLink}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}
