// @flow
import {connect} from 'react-redux'

import type {State} from '../../stateType'

import {actions} from '../Notifications/state'
import Component from './Component'

const mapStateToProps = ({brand, brands, auth}: State) => ({brands, brand, user: auth.user})

const mapDispatchToProps = {
  setError: actions.setError,
  setSuccess: actions.setSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
