// @flow
import React from 'react'

export default ({color}: {color?: string}) => {
  const fill = color || '#fff'

  return (
    <svg width='15px' height='15px' viewBox='0 0 296 233' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill={fill} fillRule='evenodd'>
        <g
          id='Group'
          transform='translate(148.000000, 85.000000) rotate(-315.000000) translate(-148.000000, -85.000000) translate(73.000000, -49.000000)'
          fill={fill}
        >
          <rect id='Rectangle' x='0' y='207' width='150' height='61' />
          <rect id='Rectangle' x='89' y='0' width='61' height='268' />
        </g>
      </g>
    </svg>
  )
}
