// @flow
import {stringify} from 'query-string'

type Data = {
  hlsUrl: ?string,
  youtubeId: ?string,
  vimeoId: ?string,
  autoplay?: number,
}

export default (data: Data) => {
  const {hlsUrl, youtubeId, vimeoId, autoplay} = data

  // YouTube
  if (youtubeId) {
    const params = stringify({
      autohide: 1,
      autoplay,
      enablejsapi: 1,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
      theme: 'dark',
      html5: 1,
    })

    return `//www.youtube.com/embed/${youtubeId}?${params}`
  }

  // Vimeo
  if (vimeoId) {
    const params = stringify({
      autoplay,
      title: 0,
    })

    return `https://player.vimeo.com/video/${vimeoId}?${params}`
  }

  // JW Player
  if (hlsUrl) {
    const hlsId = hlsUrl.split('/').pop().replace('.m3u8', '')
    return `//content.jwplatform.com/players/${hlsId}-0GfGFmQj.html`
  }

  console.warn('There is no stream for this video')
  return null
}
