// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import {actions as authActions} from '../Auth/state'

import Component from './Component'

const mapStateToProps = ({page, auth, brand}: State) => ({
  isLoggedIn: auth.isLoggedIn,
})

const mapDispatchToProps = {
  startRedirect: authActions.startRedirect,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
