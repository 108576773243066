// @flow
import React from 'react'

import type {MatchType, GenericPageType} from '../../common/types'
import Query from '../Query'
import Page from '../Page'
import Loading from '../Loading/'
import NotFound from '../NotFound'
import ShopNav from '../ShopNav'
import marked from 'marked'

import GET_GENERIC_PAGE from './Query'

import styles from './GenericPage.less'

type Props = {match: MatchType}

export default (props: Props) => {
  return (
    <Query
      Component={GenericPage}
      props={props}
      query={GET_GENERIC_PAGE}
      variables={{
        slug: props.match.params.slug,
      }}
    />
  )
}

class GenericPage extends React.Component {
  props: {
    loading: boolean,
    page_by_slug: ?GenericPageType,
  }

  render() {
    if (!this.props.loading && !this.props.page_by_slug) return <NotFound />
    if (this.props.loading || !this.props.page_by_slug) return <Loading />
    const {background_color, content_markdown, description, text_color, thumbnail_image, title, brand, is_shop_page} =
      this.props.page_by_slug
    const backgroundColor = background_color || '#FFFFFF'
    const textColor = text_color || '#000000'

    // Use the ShopNav on Shop pages
    const header = is_shop_page && <ShopNav />

    return (
      <Page
        title={title}
        description={description}
        subNavigation={header}
        iconColour={textColor}
        image={thumbnail_image}
        brandId={brand.id}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
          }}
          className={styles.Content}
          dangerouslySetInnerHTML={{__html: marked(content_markdown)}}
        />
      </Page>
    )
  }
}
