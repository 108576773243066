import React from 'react'

import BrandedComponent from '../BrandedComponent'
import Icon from '../Icon'
import * as socialIconsWhite from './white'
import * as socialIconsBlack from './black'

import styles from './SocialIcons.less'

const SocialIcons = ({color, links, className}) => (
  <BrandedComponent
    brandContents={isFourThree => (
      <div className={[styles.SocialIcons, className].join(' ')}>
        {links.map((social, i) => {
          const {title, iconName, url} = social
          const socialColour = color || (isFourThree ? socialIconsBlack : socialIconsWhite)
          return url ?
            <a key={title} href={url} target='_blank'>
              <Icon
                onClick={null}
                src={socialColour[iconName]}
                name={title}
                className={styles.SocialIcon}
              />
            </a> :
            null
        })}
      </div>
    )}
  />
)

export default SocialIcons
