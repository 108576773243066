import React from 'react'

const SearchIcon = ({color}: {color?: string}) => {
  const fill = color || '#fff'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g stroke={fill} strokeWidth='2' transform='translate(1 1)'>
          <path
            d='M9.746 1.76C7.713-.27 4.385-.238 2.312 1.836.238 3.908.206 7.237 2.238 9.269c2.033 2.032 5.361 2 7.434-.074 2.073-2.073 2.106-5.401.074-7.434z'
          />

          <path strokeLinecap='square' d='M9.856 9.379l4.784 4.784' />
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
