// @flow
import React from 'react'

import GET_RSVPS from './Query'

import AccountNavigation from '../AccountNavigation'
import BroadcastData from '../BroadcastData'
import Link from '../Link'
import Loading from '../Loading'
import Page from '../Page'
import Query from '../Query'
import RsvpStatus from '../RsvpStatus'

import type {RsvpType} from '../../common/types'

import styles from './InviteRequests.less'
import MessageWithCTA from '../MessageWithCTA'

type Props = {
  isLoggedIn: boolean,
  loading: boolean,
  rsvps: Array<RsvpType>,
}

export default (props: Props) => (
  <Query props={props} Component={InviteRequests} query={GET_RSVPS} fetchPolicy='no-cache' />
)

export const InviteRequests = (props: Props) => {
  const {isLoggedIn, loading, rsvps} = props

  return (
    <Page title='Invite Requests' requiresLogin={true}>
      <AccountNavigation />

      {loading ?
        <Loading /> :
        <div className={styles.Wrapper}>
          {isLoggedIn && rsvps && rsvps.length > 0 ?
            rsvps.map(rsvp => <Event key={rsvp.show.title} rsvp={rsvp} />) :
            <MessageWithCTA
              message={'You have not made any invite requests'}
              buttonText={'BROWSE EVENTS'}
              internalLink={'/upcoming'}
            />
          }
        </div>
      }
    </Page>
  )
}

type EventProps = {
  rsvp: RsvpType,
}

export const Event = ({rsvp}: EventProps) => (
  <div key={rsvp.show.title} className={styles.Event}>
    <div className={styles.EventInfo}>
      <Link internalLink={`session/${rsvp.show.slug}`}>
        <h3>{rsvp.show.title}</h3>
      </Link>

      <BroadcastData show={rsvp.show} className={styles.BroadcastData} />
    </div>

    <RsvpStatus className={styles.RsvpStatus} show={rsvp.show} />
  </div>
)
