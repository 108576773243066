// @flow
import React from 'react'
import isMobile from 'is-mobile'
import marked from 'marked'

import CloseIcon from '../Svgs/CloseIcon'
import Grid, {GridItem} from '../Grid/'
import Page from '../Page'
import BrandedComponent from '../BrandedComponent'

import FAQItems from './FAQItems'
import styles from './About.less'

export default class About extends React.Component {
  render() {
    return (
      <Page disableHeaderOffset={true} allBrandPage={true}>
        <BrandedComponent brandContents={isFourThree => (isFourThree ? <FourThreeAbout /> : <BoilerRoomAbout />)} />
      </Page>
    )
  }
}

class BoilerRoomAbout extends React.Component {
  state: {
    activeFAQIndex: ?number,
  }

  constructor() {
    super()
    this.state = {
      activeFAQIndex: 0,
    }
  }

  changeFAQSelected = (index: number) => {
    this.setState({activeFAQIndex: index})
  }

  closeFAQ = () => {
    if (isMobile()) {
      this.setState({activeFAQIndex: null})
    } else {
      this.setState({activeFAQIndex: 0})
    }
  }

  render() {
    const {activeFAQIndex} = this.state
    const activeItem = typeof activeFAQIndex === 'number' && FAQItems[activeFAQIndex]

    return (
      <Grid>
        <GridItem width={12}>
          <div className={styles.Content}>
            <div className={styles.FAQs}>
              {FAQItems.map((item, index) => (
                <FAQItem
                  key={item.title}
                  title={item.title}
                  content={item.content}
                  changeFAQSelected={this.changeFAQSelected}
                  closeFAQ={this.closeFAQ}
                  index={index}
                  isActive={index === this.state.activeFAQIndex}
                />
              ))}
            </div>

            <div className={styles.TextArea}>
              {activeItem &&
                <FAQContent
                  key={activeItem.title}
                  className={styles.FAQContent}
                  content={activeItem.content}
                  title={activeItem.title}
                />
              }
            </div>
          </div>
        </GridItem>
      </Grid>
    )
  }
}

const FAQContent = ({className, content, title}) => (
  <div className={className} dangerouslySetInnerHTML={{__html: marked(content)}} />
)

class FAQItem extends React.Component {
  showHideContent = () => {
    const {isActive, closeFAQ, changeFAQSelected} = this.props
    isActive ? closeFAQ() : changeFAQSelected(this.props.index)
  }

  render() {
    const {content, title, index, isActive} = this.props
    const aboutAndNotMobile = !isMobile() && index === 0
    const showCloseIcon = isActive && !aboutAndNotMobile

    return (
      <div className={isActive ? styles.FAQItem_Active : styles.FAQItem} onClick={this.showHideContent}>
        <div className={styles.FAQTitle}>
          {title}

          {showCloseIcon && <CloseIcon className={styles.CloseIconMobile} />}
        </div>

        {isActive && <FAQContent className={styles.FAQContentSmall} content={content} title={title} />}
      </div>
    )
  }
}

const FourThreeAbout = () => (
  <Grid className={styles.AboutFourThree}>
    <GridItem width={12} className={styles.Text}>
      <p>
        4:3 by Boiler Room is a multifaceted genre-spanning platform for curated and commissioned underground film
        exploring themes of performance, identity, youth culture and anti-establishment.
      </p>

      <p>
        Platform agnostic, 4:3 is for curious minds and those connected to culture, offering an exploration of unseen
        films and as well as in real life experiences, an opportunity to discover the unknown.
      </p>

      <p>
        Like Boiler Room, 4:3 is rooted in physical experience; our events are multi-faceted, connecting the dots
        between club culture and cinema to stretch the boundaries of what a film experience can be.
      </p>
    </GridItem>
  </Grid>
)
