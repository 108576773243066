// @flow
import React from 'react'

import Background from '../Background/'
import Player from '../Player/'
import type {ShowType, PageSize} from '../../common/types'
import {broadcastStates} from '../../common/constants'
import Logo from '../Svgs/Logo'
import MultiStream from '../MultiStream'

import styles from './EmbeddedShow.less'

type EmbedShowProps = {
  show: ShowType,
  pageSize: PageSize,
  minimal: boolean,
}

export default class EmbeddedShow extends React.Component {
  props: EmbedShowProps

  showState = () => {
    const {
      show: {broadcast_status, embed_override_url, flyer_image, geoblocked, streams, title, youtube_video_id},
    } = this.props

    const multiStream = streams.length > 0

    switch (broadcast_status) {
      case broadcastStates.upcoming:
      case broadcastStates.future:
      case broadcastStates.offair:
      case broadcastStates.archived:
        return <img className={styles.Flyer} src={flyer_image} alt={`${title} Flyer Image`} />
      case broadcastStates.live:
      case broadcastStates.relooping:
        if (multiStream) {
          return <MultiStream geoblocked={geoblocked} streams={streams} title={title} />
        } else {
          return (
            <Player
              youtubeId={youtube_video_id}
              overrideLoggedIn={true}
              geoblocked={geoblocked}
              title={title}
              overrideUrl={embed_override_url}
            />
          )
        }
    }
  }

  render() {
    const {
      show: {background_image, title},
      minimal,
    } = this.props

    return (
      <div className={styles.Wrapper}>
        {!minimal &&
          <div className={styles.Header}>
            <div className={styles.LogoContainer}>
              <Logo className={styles.Logo} />
            </div>

            <div className={styles.Info}>
              <h4 className={styles.Title}>{title}</h4>
            </div>
          </div>
        }
        <div className={styles.ShowContainer}>
          {background_image && <Background image={background_image} className={styles.Background} />}

          <div className={styles.Show}>{this.showState()}</div>
        </div>
      </div>
    )
  }
}
