// @flow
import React from 'react'

import Button from '../Button'
import Checkbox from '../Checkbox'
import DatePicker from '../DatePicker'
import Dropdown from '../Dropdown'
import Form from '../Form'
import Input from '../Input'

import moment from 'moment'

import mailingListLegalText from '../../common/mailingListLegalText'
import {genderOptions} from '../../common/constants'
import type {ConsentBoxType} from '../../common/types'

import styles from './Rsvp.less'

type RsvpFormProps = {
  consentBoxes: Array<ConsentBoxType>,
  loading: boolean,
  minimumAge?: number,
  mobileRequired: boolean,
  onSubmit: Function,
  profile: {
    email: string,
  },
  title: string,
}

export default class RsvpForm extends React.Component {
  state: {
    consentBoxes: Array<ConsentBoxType>,
    dob: string,
    email: string,
    firstNames: string,
    lastNames: string,
    gender: string,
    highlightRequired: boolean,
    phonenumber: string,
    emailMarketingOptIn: boolean,
  }

  constructor(props: RsvpFormProps) {
    super(props)
    const {email} = props.profile

    this.state = {
      consentBoxes: props.consentBoxes,
      dob: '',
      email,
      firstNames: '',
      lastNames: '',
      gender: '',
      highlightRequired: false,
      phonenumber: '',
      emailMarketingOptIn: false,
    }
  }

  updateEmailMarketingOptIn = (e: any) => this.setState({emailMarketingOptIn: e.target.checked})

  updateConsent = (e: any) => {
    const index = e.target.name
    let consentBoxesCopy = this.state.consentBoxes
    consentBoxesCopy[index].given = e.target.checked

    this.setState({consentBoxes: consentBoxesCopy})
  }

  updateDOB = (dob: string) => this.setState({dob})
  updateFirstNames = (e: any) => this.setState({firstNames: e.target.value})
  updateLastNames = (e: any) => this.setState({lastNames: e.target.value})
  updateGender = (e: any) => this.setState({gender: e.target.value})
  updatePhonenumber = (e: any) => this.setState({phonenumber: e.target.value})

  ageInYears = () => moment().diff(new Date(this.state.dob), 'years')

  isCorrectAge = () => this.ageInYears() >= this.props.minimumAge

  validateAge = () => {
    if (!this.props.minimumAge) return true

    const ageValid = /^((19|20)\d{2})-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/.test(this.state.dob)

    if (!ageValid) {
      return false
    } else if (this.props.minimumAge) {
      return this.isCorrectAge()
    } else {
      return true
    }
  }

  canBeSubmitted = () => {
    const {firstNames, lastNames, dob, gender, phonenumber} = this.state
    const consentGiven = this.checkConsent()
    const validAge = this.validateAge()
    const mobile = this.props.mobileRequired ? phonenumber.length > 9 : true
    return firstNames && lastNames && dob && gender && consentGiven && validAge && mobile
  }

  checkConsent = () => {
    const requiredConsentBoxes = this.state.consentBoxes.filter(box => box.required_for_rsvp)
    return requiredConsentBoxes.every(box => box.given)
  }

  getConsentBoxData = () =>
    this.state.consentBoxes.map(box => {
      return {consent_box_id: box.id, value: box.given}
    })

  submit = (e: any) => {
    e.preventDefault()
    const consentBoxData = this.getConsentBoxData()
    this.props.onSubmit(
      this.state.firstNames,
      this.state.lastNames,
      this.state.dob,
      this.state.gender,
      this.state.email,
      this.state.phonenumber,
      consentBoxData,
      this.state.emailMarketingOptIn,
    )
  }

  updateHighlightRequired = (e: any) => {
    e.preventDefault()
    if (!this.state.highlightRequired) this.setState({highlightRequired: true})
  }

  render() {
    const {loading, minimumAge, mobileRequired, title} = this.props

    const {firstNames, lastNames, dob, consentBoxes, highlightRequired, phonenumber} = this.state

    return (
      <Form
        className={styles.InviteForm}
        onSubmit={this.canBeSubmitted() ? this.submit : this.updateHighlightRequired}
        header={title}
        noValidate={true}
      >
        <Input
          type='text'
          id='firstName'
          onChange={this.updateFirstNames}
          correct={firstNames.length > 0}
          value={firstNames}
          required={true}
          label='First names'
          highlightRequired={highlightRequired}
        />

        <Input
          type='text'
          id='lastName'
          onChange={this.updateLastNames}
          correct={lastNames.length > 0}
          value={lastNames}
          required={true}
          label='Last names'
          highlightRequired={highlightRequired}
        />

        <DatePicker
          onChange={this.updateDOB}
          value={dob}
          required={true}
          label='Date of Birth'
          highlightRequired={highlightRequired}
          correct={this.isCorrectAge()}
        />

        {(this.state.dob && !this.validateAge()) && <p className={styles.AgeWarning}>You must be at least {minimumAge} years of age.</p>}

        {mobileRequired &&
          <Input
            type='tel'
            id='mobile'
            onChange={this.updatePhonenumber}
            value={phonenumber}
            correct={phonenumber.length > 9}
            label='Mobile Number'
            required={true}
            highlightRequired={highlightRequired}
          />
        }

        <Dropdown
          id='gender'
          value={this.state.gender}
          onChange={this.updateGender}
          options={genderOptions}
          label='Gender'
          required={true}
          highlightRequired={highlightRequired}
        />

        {consentBoxes[0] &&
          consentBoxes.map((box, index) => (
            <Checkbox
              key={box.id}
              id={'consent-' + box.id}
              checked={consentBoxes[index].given}
              name={index}
              onChange={this.updateConsent}
              highlightRequired={highlightRequired}
              required={box.required_for_rsvp}
              text={box.label}
              textStyle={styles.ConsentText}
              className={styles.Checkbox}
            />
          ))}

        <Checkbox
          id={'email-marketing-consent'}
          checked={this.state.emailMarketingOptIn}
          onChange={this.updateEmailMarketingOptIn}
          text={'Add me to the BOILER ROOM mailing list'}
          textStyle={styles.ConsentText}
          className={styles.Checkbox}
        />
        <p className={styles.SmallPrint} dangerouslySetInnerHTML={{__html: mailingListLegalText}} />

        <Button
          className={styles.SubmitButton}
          style={{backgroundColor: this.canBeSubmitted() ? '#FFFFFF' : '#B2B2B2'}}
          loading={loading}
          type='submit'
          text='Request Invite'
        />
      </Form>
    )
  }
}
