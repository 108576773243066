// @flow
import React from 'react'
import {Query} from '@apollo/client/react/components'

import Mutation from '../Mutation'

type Props = {
  Component: any,
  mutationName?: string,
  mutationSchema?: string,
  props?: Object,
  query: string,
  skip?: boolean,
  variables?: Object,
  fetchPolicy?: ?string,
  context?: ?Object
}

export default ({Component, mutationName, mutationSchema, props, query, skip, variables, fetchPolicy, context}: Props) => (
  <Query query={query} variables={variables} skip={skip} fetchPolicy={fetchPolicy} context={context}>
    {({data, loading, refetch, fetchMore}) => {
      return mutationName && mutationSchema ?
        <Mutation
          props={{...props, ...data, loading, refetch, fetchMore}}
          Component={Component}
          mutationName={mutationName}
          mutationSchema={mutationSchema}
          context={context}
        /> :
        <Component {...data} {...props} loading={loading} fetchMore={fetchMore} refetch={refetch} />
    }}
  </Query>
)
