// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'
import Cookies from 'js-cookie'
import http from 'axios'
import {captureException} from '@sentry/browser'
import getRequestHeaders from '../../common/getRequestHeaders'

import stashlessURL from './../../../lib/stashless'

import {actions as notificationActions} from '../Notifications/state'
import {analytics} from '../../common/analytics'
import {actions as gateActions} from '../Gate/state'
import {uiLocations} from '../../common/constants'

export const types = {
  userLoggedIn: 'Auth/login',
  userNotLoggedIn: 'Auth/userNotLoggedIn',
  startRedirect: 'Auth/startRedirect',
  endRedirect: 'Auth/endRedirect',
  newMember: 'Auth/logNewMember',
  logout: 'Auth/logout',
  setUuid: 'Auth/setUuid',
}

const plainActions = mapValues(types, type => createAction(type))

const emailLogin = (email, onError, uiLocation, itemId, itemType) => dispatch => {
  http
    .post(`${stashlessURL}/login`, {
      email,
    }, {
      withCredentials: true,
      headers: getRequestHeaders(uiLocation, itemId, itemType),
    })
    .then(res => {
      dispatch(notificationActions.setSuccess(`Code sent to ${email}. Check your inbox.`))
      if (res.data.isSignup) dispatch(actions.newMember())
    })
    .catch(e => {
      console.log(e)
      onError(e)
      captureException(e)
    })
}

const verifyCode = ({email, code}, onError, uiLocation, itemId, itemType) => (dispatch, getState) => {
  http
    .post(`${stashlessURL}/verify`, {
      code,
      email,
    }, {
      withCredentials: true,
      headers: getRequestHeaders(uiLocation, itemId, itemType),
    })
    .then(res => {
      dispatch(notificationActions.setSuccess('Code accepted, you are now logged in.'))
      dispatch(actions.userLoggedIn({
        accessToken: res.data.token,
        user: {
          email,
          uuid: res.data.uuid,
        },
      }))

      Cookies.set('accessToken', res.data.token, {domain: 'boilerroom.tv', expires: 365})
      analytics.track('log_in')

      const {auth, gate} = getState()
      if (auth.isNewMember) {
        analytics.track('sign_up', {
          signup_source: gate.signupSource,
        })
      }

      // close gate after login, stops auth gate from blocking scrolling
      dispatch(gateActions.closeGate())
    })
    .catch(e => {
      captureException(e)
      onError(e)
      console.log(e)
    })
}

const onLaunch = cb => dispatch => {
  // Check for saved auth token
  const accessToken = Cookies.get('accessToken')
  if (accessToken) {
    http
      .post(`${stashlessURL}/authenticate`, {
        token: accessToken,
      }, {
        withCredentials: true,
        headers: getRequestHeaders(uiLocations.returnToSession),
      })
      .then(res => {
        analytics.track('login')
        dispatch(actions.userLoggedIn({
          accessToken,
          user: {
            email: res.data.email_address,
            uuid: res.data.uuid,
          },
        }))
      })
      .catch(e => {
        clearTokens()
        dispatch(plainActions.userNotLoggedIn())
        captureException(e)
        console.log(e)
      })
  } else {
    const urlParams = new URLSearchParams(window.location.search)
    const uuid = urlParams.get('br_uuid')
    if (uuid) dispatch(plainActions.setUuid({uuid}))

    dispatch(plainActions.userNotLoggedIn())
  }
}

const clearTokens = () => {
  Cookies.remove('accessToken')
  Cookies.remove('accessToken', {domain: 'boilerroom.tv'})
}

const logout = () => dispatch => {
  const accessToken = Cookies.get('accessToken')
  if (!accessToken) {
    dispatch(plainActions.logout())
    return
  }

  http
    .post(`${stashlessURL}/logout`, {token: accessToken})
    .catch(() => null) // if the stashless logout fails, continue logging the user out anyway
    .then(() => {
      clearTokens()
      dispatch(notificationActions.setSuccess('You are logged out.'))
      dispatch(plainActions.logout())
    })
}

export const actions = {
  ...plainActions,

  // thunks
  verifyCode,
  emailLogin,
  onLaunch,
  logout,
}

export type StateType = {
  accessToken: ?string,
  user: ?{
    email: string,
    uuid: string,
  },
  isNewMember: boolean,
  isRedirecting: boolean,
  isLoggedIn: boolean,
  loading: boolean,
}

const initialState: StateType = {
  accessToken: null,
  user: {
    email: '',
    uuid: '',
  },
  isNewMember: false,
  isRedirecting: false,
  isLoggedIn: false,
  loading: true,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.setUuid:
      return {
        ...state,
        user: {
          ...state.user,
          uuid: action.payload.uuid,
        },
      }
    case types.userLoggedIn:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isLoggedIn: true,
        user: {
          ...state.user,
          email: action.payload.user.email,
          uuid: action.payload.user.uuid,
        },
        loading: false,
      }
    case types.logout:
      return {
        ...initialState,
        loading: false,
      }
    case types.userNotLoggedIn :
      return {
        ...state,
        loading: false,
      }
    case types.startRedirect:
      return {
        ...state,
        isRedirecting: true,
      }
    case types.endRedirect:
      return {
        ...state,
        isRedirecting: false,
      }
    case types.newMember:
      return {
        ...state,
        isNewMember: true,
      }
    default:
      return state
  }
}
