class Rudder {
  trackEvent(eventName, properties) {
    if (typeof window !== 'undefined' && window.rudderanalytics) {
      window.rudderanalytics.track(eventName, properties)
    }
  }

  identifyUser(userId) {
    if (typeof window !== 'undefined' && window.rudderanalytics) {
      window.rudderanalytics.identify(userId)
    }
  }
}

export default new Rudder()
