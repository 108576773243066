// @flow
import React from 'react'

import {lowHeatLogo} from '../../common/brands'
import BrandedComponent from '../BrandedComponent'

import styles from './LowHeatIcon.less'

const icons = {
  logo: lowHeatLogo,
  warning: require('./icons/warning.svg'),
  tattoo1: require('./icons/tattoo1.svg'),
  tattoo2: require('./icons/tattoo2.svg'),
  ovalLogo: require('./icons/ovalLogo.svg'),
}

type Props = {
  icon: 'warning' | 'tattoo1' | 'tattoo2' | 'ovalLogo',
  className?: ?string,
}

export const LowHeatPageOverlay = () => (
  <div className={styles.Container}>
    <img src={icons.logo} className={styles.Logo} />
    <img src={icons.ovalLogo} className={styles.OvalLogo} />
  </div>
)

export default (props: Props) => (
  <BrandedComponent
    brandContents={(is43, isLowHeat) =>
      isLowHeat ? <img src={icons[props.icon]} className={props.className} /> : null
    }
  />
)
