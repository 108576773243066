// @flow
import React from 'react'
import {Mutation} from '@apollo/client/react/components'
import {captureException} from '@sentry/browser'

import mailingListLegalText from '../../common/mailingListLegalText'
import {uiLocations} from '../../common/constants'
import {brandIds} from '../../common/brands'
import type {BrandType, ProfileType} from '../../common/types'
import {getUpdateMailingConsentArgs, hasOptedInToMailingList} from '../../common/consents'
import captureMutationErrors from '../../common/captureMutationErrors'

import Query from '../Query'
import Checkbox from '../Checkbox'

import styles from './OptIn.less'

import UPDATE_MAILING_CONSENT from '../Signup/Mutation'
import GET_PREFERENCES from './Query'
import {analytics} from '../../common/analytics'

type Props = {
  checked?: boolean,
  textStyle?: string,
}

type OptInProps = {
  ...Props,
  brand: BrandType,
  brands: Array<BrandType>,
  updateMailingConsent: any,
  profile: ProfileType,
  loading: boolean,
  setError: (message: string) => void,
}

export default (props: Props) => (
  <Mutation mutation={UPDATE_MAILING_CONSENT}>
    {updateMailingConsent => (
      <Query Component={OptIn} query={GET_PREFERENCES} props={{...props, updateMailingConsent}} />
    )}
  </Mutation>
)

export class OptIn extends React.Component {
  state: {
    checked: boolean,
    loading: boolean,
  }

  constructor(props: OptInProps) {
    super(props)
    this.state = {
      checked: Boolean(hasOptedInToMailingList(props.profile, props.brand)),
      loading: false,
    }
  }

  updateOptIn = (e: any) => {
    if (e.target.checked) {
      analytics.track('subscribe', {ui_location: uiLocations.accountOptIn})
    }
    this.setState({checked: e.target.checked, loading: true})
    this.saveChoice(e.target.checked)
  }

  saveChoice = (checked: boolean) => {
    const isFourThree = this.props.brand.id === brandIds.fourthree

    this.props
      .updateMailingConsent(getUpdateMailingConsentArgs(checked, isFourThree, [], uiLocations.accountOptIn))
      .then(({errors}) => {
        if (errors && errors.length > 0) {
          captureMutationErrors(errors)
          this.props.setError('Opt-In error, please try again.')
        }
        this.setState({loading: false})
      })
      .catch(error => {
        captureException(error)
        console.log(error)
        this.setState({loading: false, checked: !checked})
      })
  }

  render() {
    if (this.props.loading) return null
    const {textStyle} = this.props

    return (
      <div className={styles.SignUpWrap}>
        <Checkbox
          id='mailing list'
          name='mailing list'
          checked={this.state.checked}
          loading={this.state.loading}
          loadingColour={'#FFF'}
          onChange={this.updateOptIn}
          text={'Add me to the mailing list'}
          textStyle={textStyle}
          className={styles.Label}
        />
        <p className={styles.SmallPrint} dangerouslySetInnerHTML={{__html: mailingListLegalText}} />
      </div>
    )
  }
}
