// @flow
import React from 'react'

import Page from '../Page/'
import Genres from '../Genres/'
import ShopSlider from '../ShopSlider/'
import Input from '../Input/'

import searchIcon from './search.svg'

import styles from './SearchPage.less'

export default () => (
  <Page title='Search' requiresLogin={false} disableHeaderOffset={true} footer={null} allBrandPage={true} ssr={true}>
    <div className={styles.Wrapper}>
      <SearchField />
      <Genres />
    </div>
    <ShopSlider />
  </Page>
)

class SearchField extends React.Component {
  constructor() {
    super()
    this.state = {
      value: '',
    }
  }

  submitSearch = event => {
    event.preventDefault()
    if (typeof window !== 'undefined') {
      window.location = `/?s=${encodeURIComponent(this.state.value)}`
    }
  }

  onChange = ({target}) => this.setState({value: target.value})

  onClick = e => e.stopPropagation()

  render() {
    return (
      <form onSubmit={this.submitSearch} onClick={this.onClick}>
        <Input
          onChange={this.onChange}
          style={{backgroundImage: `url(${searchIcon})`}}
          className={styles.Input}
          value={this.state.value}
          label='Search the archive'
          autoFocus
        />
      </form>
    )
  }
}
