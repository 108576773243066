// @flow
import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import marked from 'marked'

import {rsvpStates} from '../../common/constants'
import type {ShowType} from '../../common/types'

import styles from './RsvpStatus.less'

type RsvpStatusProps = {
  className?: string,
  show: ShowType,
}

export default class RsvpStatus extends React.PureComponent {
  props: RsvpStatusProps

  render() {
    const {className, show} = this.props

    if (!show.user_rsvp_status) return null

    const rsvpStatus = show.user_rsvp_status.status
    const isPastEvent = moment(show.end).add(1, 'd').isBefore(moment())

    if (isPastEvent) {
      return rsvpStatus === rsvpStates.successful ?
        <ArchiveMessage className={className} /> :
        <UnsuccessfulMessage className={className} />
    }

    switch (rsvpStatus) {
      case rsvpStates.pending:
        return <PendingMessage className={className} />
      case rsvpStates.unsuccessful:
        return <UnsuccessfulMessage className={className} />
      case rsvpStates.successful:
        return <SuccessfulRsvp className={className} show={show} />
      default:
        return null
    }
  }
}

const ArchiveMessage = ({className}) => (
  <div className={[styles.Container, className].join(' ')}>
    <div className={styles.Header}>You were on the guestlist for this event.</div>
  </div>
)

const PendingMessage = ({className}) => (
  <div className={[styles.Container, className].join(' ')}>
    <div className={styles.Header}>Your invite request has been received.</div>
    <div className={styles.Info}>
      We will email if you are successful.<br /><br />
      Although we want to get everyone on the guestlist, we're not always able to.
    </div>
  </div>
)

const UnsuccessfulMessage = ({className}) => (
  <div className={[styles.Container, className].join(' ')}>
    <div className={styles.Header}>Sorry, you are not on the guestlist.</div>
    <div className={styles.Info}>
      We try to get everyone on where we can, but this time it hasn’t come through for you.
    </div>
  </div>
)

const SuccessfulRsvp = ({className, show}) => {
  const {additional_details, city, event_end, event_start, venue_details, user_rsvp_status} = show

  if (!user_rsvp_status) return null

  const {additional_guests, default_additional_guests} = user_rsvp_status

  const guests = additional_guests || default_additional_guests

  const timezone = moment.tz(city.timezone)
  const start = formatShowTime(event_start, timezone.tz())
  const end = formatShowTime(event_end, timezone.tz())
  const timezoneCode = timezone.zoneAbbr()
  const doors = `${start} : ${end} ${timezoneCode}`
  const name = show.user_rsvp_status && show.user_rsvp_status.name

  return (
    <div className={[styles.Container, className].join(' ')}>
      <div className={styles.SuccessHeader}>
        You're on the guestlist as{' '}
        <b>
          {name} + {guests}.
        </b>
      </div>

      <div className={styles.Info}>
        <InfoItem header='Venue' text={venue_details} />

        <InfoItem header='Info' text={additional_details} />

        <InfoItem header='Doors' text={doors} />
      </div>
    </div>
  )
}

const formatShowTime = (time, timezone) => {
  const timeUTC = moment.tz(time, 'UTC')
  const eventTime = moment.tz(timeUTC, timezone)
  return eventTime.format('HH:mm')
}

const InfoItem = ({text, header}) =>
  text ?
    <div className={styles.InfoItem}>
      <div className={styles.InfoHeader}>{header}</div>

      <div dangerouslySetInnerHTML={{__html: marked(text, {breaks: true})}} />
    </div> :
    null
