import React from 'react'
import {withRouter} from 'react-router-dom'

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const prevPathname = prevProps.location.pathname
    const pathname = this.props.location.pathname
    const isRecordingPath = /^\/(recording|audio|film)\/([a-zA-Z0-9-_]+)[/]?/

    const isSwitchingAudioVideoModeOnARecording =
      prevPathname.match(isRecordingPath) &&
      pathname.match(isRecordingPath) &&
      prevPathname.match(isRecordingPath)[2] === pathname.match(isRecordingPath)[2]

    const locationHasChanged = pathname !== prevPathname

    if (!isSwitchingAudioVideoModeOnARecording && locationHasChanged) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
