import React from 'react'
import moment from 'moment'

import type {GenericItemType} from '../../common/types'
import {genericItemTypes} from '../../common/constants'

import {getPrices} from '../../common/shop'

import BroadcastData from '../BroadcastData'
import styles from './ItemInfo.less'

export default ({item, disableShowTimes, isFourThree}: {item: GenericItemType, disableShowTimes: boolean, isFourThree: boolean}) => {
  // Broadcasts i.e. Recordings or Shows
  if (
    (item.type === genericItemTypes.recording && item.recording) ||
    (item.type === genericItemTypes.show && item.show)
  ) {
    return (
      <BroadcastData
        recording={item.recording}
        show={item.show}
        className={styles.Wrapper}
        disableCityLink
        disableShowTimes
      />
    )
  }
  // Articles
  if (item.type === genericItemTypes.article && item.article && item.article.released) {
    return <div>{moment(item.article.released).format('DD MMM YYYY')}</div>
  }
  // Products
  if (item.type === genericItemTypes.product && item.product) {
    const prices = getPrices(item.product)
    return (
      <div>
        {prices && prices.compareAtPrice && <del>{prices.compareAtPrice}</del>}
        {prices.price}
      </div>
    )
  }
  // Links
  if (item.type === genericItemTypes.link && item.publication) {
    return <div>{item.publication}</div>
  }
  return null
}
