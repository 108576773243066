// @flow
import connect from '../../common/safeConnect'
import type {State} from '../../stateType'

import Component from './Component'

const mapStateToProps = ({auth}: State) => ({
  isLoggedIn: auth.isLoggedIn,
})

export default connect(mapStateToProps)(Component)
