import {gql} from '@apollo/client'
import {ShowFragment, GenericItemFragment, ThemeFragment} from '../../common/fragments'

export default gql`
  query ShowQuery($slug: String!, $signature: String) {
    show: show_by_slug(slug: $slug, signature: $signature) {
      ...FullShow

      theme {
        ...FullTheme
      }

      child_shows {
        ...FullShow
      }

      parent_show {
        ...FullShow
        child_shows {
          ...FullShow
        }
      }

      related_playlists {
        id
        title
        description
        summary
        slug
        items {
          ...FullGenericItem
        }
      }
    }
  }

  ${ShowFragment}
  ${GenericItemFragment}
  ${ThemeFragment}
`
