// @flow
import React from 'react'

import styles from './CompleteTick.less'

export default ({
  className,
  backgroundColor,
  tickColor,
}:
{
  className?: ?string,
  backgroundColor?: ?string,
  tickColor?: ?string,
}) =>
  <svg viewBox='0 0 20 20' fill='none' width='60px' className={className}>
    <circle cx='10' cy='10' r='10' fill={backgroundColor || '#70BB66'} />
    <path
      className={styles.Tick}
      d='M5 11 L 8 14 L 15 7'
      stroke={tickColor || '#fff'}
      strokeLinecap='square'
      strokeWidth='1.5'
    />
  </svg>
