// @flow
import React from 'react'

import type {RecordingType, LocationType} from '../../common/types'
import paths from '../../common/paths'

import Link from '../Link'

import VideoIcon from '../Svgs/VideoIcon'
import HeadphoneIcon from '../Svgs/HeadphoneIcon'

import styles from './ToggleAudioVideo.less'
import {recordingHasAudioAndVideo} from '../../common/audioVideo'
import {hasFutureYoutubePublishDate} from '../../common/youtubePublicationDate'

type Props = {
  recording: RecordingType,
  location: LocationType,
}

export default ({recording, location}: Props) => {
  if (recordingHasAudioAndVideo(recording) && !hasFutureYoutubePublishDate(recording)) {
    const isAudio: boolean = location.pathname.indexOf('/audio/') === 0
    const toggleToUrl: String = paths.recording(recording, !isAudio)

    const icon = isAudio ? <VideoIcon /> : <HeadphoneIcon />
    const text = isAudio ? 'Video' : 'Audio'

    return (
      <Link className={styles.Link} internalLink={toggleToUrl}>
        {icon}
        {text}
      </Link>
    )
  }

  return null
}
