const social = (title, iconName, url) => ({title, iconName, url})

export default [
  social('Facebook', 'facebook', 'https://www.facebook.com/boilerroom.tv'),
  social('Twitter', 'twitter', 'https://twitter.com/boilerroomtv'),
  social('SoundCloud', 'soundcloud', 'https://soundcloud.com/platform'),
  social('Instagram', 'instagram', 'https://www.instagram.com/boilerroomtv/'),
  social('YouTube', 'youtube', 'https://www.youtube.com/user/brtvofficial'),
  social('Discord', 'discord', 'https://discord.gg/WgRQ6ACWFY'),
]

export const fourThreeSocialLinks = [
  social('Facebook', 'facebook', 'https://www.facebook.com/fourthree.boilerroom.tv/'),
  social('Twitter', 'twitter', 'https://twitter.com/boilerroomtv'),
  social('Vimeo', 'vimeo', 'https://vimeo.com/fourthree'),
  social('Instagram', 'instagram', 'https://www.instagram.com/fourthree.boilerroom.tv/'),
  social('YouTube', 'youtube', 'https://www.youtube.com/user/brtvofficial'),
  social('Discord', 'discord', 'https://discord.gg/WgRQ6ACWFY'),
]

export const artistSocialLinks = socials => {
  return [
    social('Facebook', 'facebook', socials.facebook),
    social('Twitter', 'twitter', socials.twitter),
    social('SoundCloud', 'soundcloud', socials.soundcloud),
    social('Instagram', 'instagram', socials.instagram),
    social('YouTube', 'youtube', socials.youtube),
    social('Website', 'website', socials.website),
  ]
}
