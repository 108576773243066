import React from 'react'

import type {ShopifyCheckoutType} from '../../common/types'

import Link from '../Link'
import {hasWindow, uiLocations} from '../../common/constants'

import styles from './BasketMenu.less'

const calculateItemsLength = items => {
  const quantitiesArray = items.map(item => item.node.quantity)
  return quantitiesArray.reduce((a, b) => a + b, 0)
}

type Props = {
  data: {
    basket: ShopifyCheckoutType,
  },
  checkoutId: boolean,
  iconColour?: string,
  menuButtonClass: string,
}

export default class BasketMenu extends React.PureComponent {
  state: {
    itemAdded: boolean,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      itemAdded: false,
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {data} = prevProps
    const prevItems = data && data.basket && data.basket.lineItems
    const prevItemsLength = prevItems && calculateItemsLength(prevItems.edges)

    const items = this.props.data && this.props.data.basket && this.props.data.basket.lineItems
    const itemsLength = items && calculateItemsLength(items.edges)

    const itemJustAdded = prevItemsLength && itemsLength && prevItemsLength < itemsLength
    if (itemJustAdded) this.setState({itemAdded: itemJustAdded})
  }

  render() {
    const {data, menuButtonClass} = this.props
    const basketLinkIsActive = hasWindow && window.location.pathname === '/basket'
    const className = basketLinkIsActive ? styles.Active : ''
    const classNames = [menuButtonClass, className].join(' ')
    const basketItems = data && data.basket && data.basket.lineItems.edges

    const itemsLength = basketItems && calculateItemsLength(basketItems)
    const link = itemsLength ? '/basket' : '/shop'

    return (
      <Link
        internalLink={link}
        className={classNames}
        key={itemsLength}
        uiLocation={uiLocations.shopNavBasketLink}
      >
        Basket
        {itemsLength && <span>{itemsLength}</span>}
      </Link>
    )
  }
}
