// @flow
import React from 'react'

import artistHoldingIcon from '../Artist/artistHoldingIcon.png'

import imageUrl from '../../common/imageUrl'
import type {ArtistType} from '../../common/types'

import styles from './Artists.less'
import Link from '../Link'
import Grid, {GridItem} from '../Grid'
import {uiLocations} from '../../common/constants'

export default ({artists}: {artists: Array<ArtistType>}) => {
  return <div className={styles.Wrapper}>
    <div>
      <h3 className={styles.Header}>{'Artists'}</h3>
      {artists.length < 12 ? (
        <Grid className={styles.ArtistGrid}>
          {artists.map((artist, index) => (
            <GridItem key={artist.id} width={4} className={styles.ArtistItem}>
              <ArtistCard artist={artist} index={index} />
            </GridItem>
          ))}
        </Grid>
      ) : (
        <ul className={styles.InlineArtistList}>
          {artists.map((artist, index) => (
            <li key={index}>
              {artist.hide_artist ?
                <div>{artist.name + '  '}</div> :
                <Link internalLink={`/artist/${artist.slug}`} className={styles.InlineArtist}>
                  {artist.name + ' '}
                </Link>
              }
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
}

const ArtistCard = ({artist}) => {
  const {name, image, hide_artist, slug} = artist

  const maybeLink = children => {
    if (hide_artist) {
      return <div className={styles.Info}>
        {children}
      </div>
    }

    return <Link className={styles.LinkedInfo} internalLink={`/artist/${slug}`} uiLocation={uiLocations.lineupArtistCard}>
      {children}
    </Link>
  }

  return maybeLink(
    <React.Fragment>
      <img
        className={styles.ArtistImage}
        src={imageUrl(image, 300, 300) || artistHoldingIcon}
        alt={`${artist.name}-profile-image`}
      />
      <div className={styles.Name}>{name}</div>
    </React.Fragment>
  )
}
