// @flow
import React from 'react'

import {useSelector} from 'react-redux'
import {analytics} from '../../common/analytics'

// Fallback is staging
const brazeAPIKey = process.env.BRAZE_API_KEY || 'dd49cffd-7116-4efa-929f-e3ba138932e2'

export default () => {
  const {uuid, city} = useSelector(state => ({
    uuid: state.auth.user.uuid,
    city: state.geoLocation.city,
  }))

  // $FlowFixMe
  React.useEffect(() => {
    if (uuid) {
      const braze = require('@braze/web-sdk')
      braze.initialize(brazeAPIKey, {baseUrl: 'sdk.fra-01.braze.eu'})
      braze.changeUser(uuid)
      if (city) braze.getUser().setHomeCity(city)
      braze.openSession()
      analytics.identify(uuid)
    }
  }, [uuid])

  return null
}
