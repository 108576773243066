import React from 'react'
import useAxios from 'axios-hooks'

import styles from './Jobs.less'

import Page from '../Page'
import Loading from '../Loading'

export default () => {
  const [{data: headerContent, loading: isHeaderLoading}] = useAxios(
    'https://api.greenhouse.io/v1/boards/boilerroom/?content=true'
  )
  const [{data: jobsData, loading: isJobsLoading}] = useAxios(
    'https://api.greenhouse.io/v1/boards/boilerroom/jobs?content=true'
  )

  if (isHeaderLoading || isJobsLoading) return <Loading />

  return (
    <Page title='Jobs' ssr={true}>
      <div className={styles.Container}>
        <h1>JOIN THE TEAM</h1>
        {headerContent && <div className={styles.Content} dangerouslySetInnerHTML={{__html: headerContent.content}} />}
        <h3>CURRENT OPENINGS</h3>
        <ul className={styles.Jobslist}>{jobsData && jobsData.jobs.map(job => <Job key={job.id} job={job} />)}</ul>
      </div>
    </Page>
  )
}

const Job = ({job}) => (
  <li>
    <a className={styles.JobLink} href={job.absolute_url} target='_blank'>
      {job.title}
    </a>
    <span className={styles.JobLocation}>{job.location.name}</span>
  </li>
)
