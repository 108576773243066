// @flow
import {gql} from '@apollo/client'
import {graphql} from '@apollo/client/react/hoc'
import compose from 'lodash.flowright'

import connect from '../../common/safeConnect'
import {GenericItemFragment} from '../../common/fragments'
import {itemsPerPage} from '../../common/constants'
import type {State} from '../../stateType'

import Component from './Component'

const mapStateToProps = ({brand, auth}: State) => ({
  brandId: brand.id,
  user: auth.user,
})

const seeMoreUpdate = (previousResult, {fetchMoreResult}) => {
  const items = previousResult.search_items.items.concat(fetchMoreResult.search_items.items)
  const updated = {
    ...fetchMoreResult,
    search_items: {
      ...fetchMoreResult.search_items,
      items,
    },
  }
  return updated
}

const searchResultsGraphqlQuery = graphql(
  gql`
    query SearchResultsQuery($q: String!, $limit: Int, $offset: Int, $brand_id: Int) {
      search_items(q: $q, limit: $limit, offset: $offset, brand_id: $brand_id) {
        items_count
        items {
          ...FullGenericItem
        }
      }
    }

    ${GenericItemFragment}
  `,
  {
    options: props => ({
      variables: {
        q: props.query,
        limit: itemsPerPage,
        offset: 0,
        brand_id: props.brandId,
      },
      // Required for setting 'loading' prop to true while fetching new data.
      // See https://github.com/apollographql/@apollo/client/react/components/issues/321
      notifyOnNetworkStatusChange: true,
    }),
    props: ownProps => {
      const {search_items, fetchMore, variables} = ownProps.data
      const nextOffset = search_items ? search_items.items.length : 0
      const onSeeMore = () =>
        fetchMore({
          variables: {
            ...variables,
            limit: itemsPerPage,
            offset: nextOffset,
          },
          updateQuery: seeMoreUpdate,
        })
      return {
        ...ownProps,
        onSeeMore,
      }
    },
  },
)

export default compose(connect(mapStateToProps), searchResultsGraphqlQuery)(Component)
